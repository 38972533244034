import React from 'react';
import { useNavigate } from 'react-router-dom';
import logo from '../assets/logo.png';

const PasswordSuccess = () => {
  const navigate = useNavigate();

  return (
    <div style={styles.background}>
      <div style={styles.container}>
        <div style={styles.header}>
          <div style={styles.logoContainer}>
            <img src={logo} alt="Logo" style={styles.logo} />
            <h1 style={styles.companyName}>YourEDU</h1>
          </div>
        </div>
        <div style={styles.formContainer}>
          <h2>Password successfully reset</h2>
          <p>Click the button below to proceed to the YourEDU login page.</p>
          <button onClick={() => navigate('/login')} style={styles.button}>Go to login page</button>
        </div>
      </div>
    </div>
  );
};

const styles = {
  background: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    backgroundColor: '#00356b', // Yale blue background
  },
  container: {
    backgroundColor: '#fff', // White background for the box
    borderRadius: '10px', // Curved edges
    padding: '40px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Subtle shadow for depth
    width: '100%',
    maxWidth: '400px', // Max width for the box
  },
  header: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '20px',
  },
  logoContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  logo: {
    height: '50px',
    marginRight: '10px',
  },
  companyName: {
    fontSize: '24px',
    fontWeight: 'bold',
  },
  formContainer: {
    width: '100%',
    textAlign: 'left',
  },
  button: {
    marginTop: '20px',
    padding: '10px 20px',
    backgroundColor: '#00356b', // Yale blue
    color: 'white',
    border: 'none',
    cursor: 'pointer',
    borderRadius: '4px', // Rounded corners for button
  },
};

export default PasswordSuccess;
