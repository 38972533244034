import React, { useState, useEffect } from 'react';
import { Link, Route, Routes, useNavigate } from 'react-router-dom';
import { useAuth } from '../utils/AuthContext'; 
import Transcript from './Transcript';
import CourseDescriptions from './CourseDescriptions';
import SchoolPhilosophy from './SchoolPhilosophy';
import GradingRubric from './GradingRubric';
import GuidanceCounselorLetter from './GuidanceCounselorLetter';
import './Colleges.css'; // Import the stylesheet

const AdminMaterials = () => {
  const { user } = useAuth();  
  const [loading, setLoading] = useState(true);
  const [progress, setProgress] = useState(() => {
    const savedProgress = localStorage.getItem('adminMaterialsProgress');
    return savedProgress ? JSON.parse(savedProgress) : {
      schoolPhilosophy: 0,
      transcript: 0,
      courseDescriptions: 0,
      gradingRubric: 0,
      guidanceCounselorLetter: 0
    };
  });

  // Cache API responses
  const [documentsData, setDocumentsData] = useState({});

  // Single function to fetch all documents at once
  const fetchAllDocuments = async () => {
    if (!user?.id) return;

    try {
      const endpoints = {
        schoolPhilosophy: '/api/school-philosophy',
        transcript: '/api/transcript',
        courseDescriptions: '/api/course-descriptions',
        gradingRubric: '/api/grading-rubric',
        guidanceCounselorLetter: '/api/guidance-letter'
      };

      const responses = await Promise.all(
        Object.entries(endpoints).map(async ([key, endpoint]) => {
          const response = await fetch(`${endpoint}?userId=${user.id}`);
          const data = await response.json();
          return [key, data];
        })
      );

      const documentsData = Object.fromEntries(responses);
      setDocumentsData(documentsData);
      return documentsData;
    } catch (error) {
      console.error('Error fetching documents:', error);
      return null;
    }
  };

  // Calculate progress for all documents
  const calculateAllProgress = (data) => {
    if (!data) return;

    const newProgress = {};

    // School Philosophy Progress
    if (data.schoolPhilosophy) {
      const totalFields = 24;
      const filledFields = Object.values(data.schoolPhilosophy)
        .filter(value => value && value !== '' && value !== null)
        .length;
      newProgress.schoolPhilosophy = Math.round((filledFields / totalFields) * 100);
    }

    // Transcript Progress
    if (data.transcript) {
      const transcriptData = data.transcript.transcript || data.transcript;
      const basicFields = [
        'name', 'gender', 'address', 'city', 'state', 'zip', 'dob',
        'parentGuardian', 'studentEmail', 'projectedGradDate', 'parentEmail',
        'schoolName', 'schoolPhone', 'schoolAddress', 'schoolCity',
        'schoolState', 'schoolZip', 'issueDate', 'graduationDate',
        'freshmanYear', 'sophomoreYear', 'juniorYear', 'seniorYear'
      ];
      
      const filledBasicFields = basicFields.filter(field => 
        transcriptData[field] && transcriptData[field].toString().trim() !== ''
      ).length;

      const courseArrays = [
        'freshmanCourses', 'sophomoreCourses', 
        'juniorCourses', 'seniorCourses', 'preHighSchoolCourses'
      ];
      
      const filledCourseArrays = courseArrays.filter(year => 
        Array.isArray(transcriptData[year]) && transcriptData[year].length > 0
      ).length;

      let filledSummaryFields = 0;
      if (transcriptData.cumulativeSummary) {
        const summaryFields = ['totalCredits', 'cumulativeGPA', 'weightedGPA'];
        filledSummaryFields = summaryFields.filter(field => 
          transcriptData.cumulativeSummary[field] !== undefined && 
          transcriptData.cumulativeSummary[field] !== null && 
          transcriptData.cumulativeSummary[field].toString().trim() !== ''
        ).length;
      }

      const totalFields = basicFields.length + courseArrays.length + 3;
      const filledFields = filledBasicFields + filledCourseArrays + filledSummaryFields;
      newProgress.transcript = Math.round((filledFields / totalFields) * 100);
    }

    // Course Descriptions Progress
    if (data.courseDescriptions) {
      const totalFields = 4;
      const filledFields = ['freshman', 'sophomore', 'junior', 'senior']
        .filter(year => data.courseDescriptions[year]?.length > 0)
        .length;
      newProgress.courseDescriptions = Math.round((filledFields / totalFields) * 100);
    }

    // Grading Rubric Progress
    if (data.gradingRubric) {
      let totalFields = 5;
      let filledFields = ['evaluationMethod', 'learningGoals', 'assignments', 'aiGradingScale']
        .filter(field => data.gradingRubric[field] && data.gradingRubric[field] !== '')
        .length;
      
      if (data.gradingRubric.gradingScale) {
        totalFields += 13;
        filledFields += Object.values(data.gradingRubric.gradingScale)
          .filter(value => value && value !== '')
          .length;
      }
      newProgress.gradingRubric = Math.round((filledFields / totalFields) * 100);
    }

    // Guidance Letter Progress
    if (data.guidanceCounselorLetter) {
      newProgress.guidanceCounselorLetter = data.guidanceCounselorLetter.letterContent ? 100 : 0;
    }

    setProgress(newProgress);
    localStorage.setItem('adminMaterialsProgress', JSON.stringify(newProgress));
  };

  useEffect(() => {
    const initializeData = async () => {
      if (!user?.id) return;
      const data = await fetchAllDocuments();
      calculateAllProgress(data);
      setLoading(false);
    };

    initializeData();
  }, [user]);

  const navigate = useNavigate();

  return (
    <div style={{
      display: 'flex',
      padding: '0 32px',
      minHeight: 'calc(100vh - 72px)',
      marginTop: '-12px',
      background: 'linear-gradient(to bottom right, #f0f9ff, #ffffff)',
      width: '100%',
    }}>
      <div style={{
        width: '100%',
        maxWidth: '1400px',
        margin: '20px auto',
      }}>
        <div style={{
          backgroundColor: '#ffffff',
          padding: '32px',
          borderRadius: '16px',
          boxShadow: '0 4px 20px rgba(49, 130, 206, 0.1)',
          marginBottom: '24px',
          border: '1px solid rgba(49, 130, 206, 0.1)',
        }}>
          <div style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
            gap: '24px',
            width: '100%',
            animation: 'fadeIn 0.5s ease-out',
          }}>
            {/* School Profile Report Box - First */}
            <div style={styles.box} className="document-box" onClick={(e) => {
              if (!e.target.closest('select')) {
                navigate('/admin-materials/school-philosophy');
              }
            }}>
              <div style={styles.boxDecoration} />
              <div style={styles.titleSection}>
                <h3>
                  <span role="img" aria-label="school">🏫</span>
                  School Profile Report
                </h3>
                <div style={styles.subtitle}>Create and manage your school profile</div>
              </div>
              <div style={styles.infoSection}>
                <div style={{ width: '100%' }}>
                  <div style={styles.progressBarContainer}>
                    <div style={{...styles.progressBar, width: `${progress.schoolPhilosophy}%`}} />
                  </div>
                  <div style={styles.progressText}>
                    {`${progress.schoolPhilosophy}% Complete`}
                  </div>
                </div>
              </div>
            </div>

            {/* Transcript Box - Now Second */}
            <div style={styles.box} className="document-box" onClick={(e) => {
              if (!e.target.closest('select')) {
                navigate('/admin-materials/transcript');
              }
            }}>
              <div style={styles.boxDecoration} />
              <div style={styles.titleSection}>
                <h3>
                  <span role="img" aria-label="transcript">📝</span>
                  Transcript
                </h3>
                <div style={styles.subtitle}>Create and manage your academic record</div>
              </div>
              <div style={styles.infoSection}>
                <div style={{ width: '100%' }}>
                  <div style={styles.progressBarContainer}>
                    <div style={{...styles.progressBar, width: `${progress.transcript}%`}} />
                  </div>
                  <div style={styles.progressText}>
                    {`${progress.transcript}% Complete`}
                  </div>
                </div>
              </div>
            </div>

            {/* Course Descriptions - Now Third */}
            <div style={styles.box} className="document-box" onClick={(e) => {
              if (!e.target.closest('select')) {
                navigate('/admin-materials/course-descriptions');
              }
            }}>
              <div style={styles.boxDecoration} />
              <div style={styles.titleSection}>
                <h3>
                  <span role="img" aria-label="book">📚</span>
                  Course Descriptions
                </h3>
                <div style={styles.subtitle}>Create and manage your course descriptions</div>
              </div>
              <div style={styles.infoSection}>
                <div style={{ width: '100%' }}>
                  <div style={styles.progressBarContainer}>
                    <div style={{...styles.progressBar, width: `${progress.courseDescriptions}%`}} />
                  </div>
                  <div style={styles.progressText}>
                    {`${progress.courseDescriptions}% Complete`}
                  </div>
                </div>
              </div>
            </div>

            {/* Grading Rubric */}
            <div style={styles.box} className="document-box" onClick={(e) => {
              if (!e.target.closest('select')) {
                navigate('/admin-materials/grading-rubric');
              }
            }}>
              <div style={styles.boxDecoration} />
              <div style={styles.titleSection}>
                <h3>
                  <span role="img" aria-label="rubric">📋</span>
                  Grading Rubric
                </h3>
                <div style={styles.subtitle}>Create and manage your grading rubric</div>
              </div>
              <div style={styles.infoSection}>
                <div style={{ width: '100%' }}>
                  <div style={styles.progressBarContainer}>
                    <div style={{...styles.progressBar, width: `${progress.gradingRubric}%`}} />
                  </div>
                  <div style={styles.progressText}>
                    {`${progress.gradingRubric}% Complete`}
                  </div>
                </div>
              </div>
            </div>

            {/* Guidance Counselor Letter */}
            <div style={styles.box} className="document-box" onClick={(e) => {
              if (!e.target.closest('select')) {
                navigate('/admin-materials/guidance-counselor-letter');
              }
            }}>
              <div style={styles.boxDecoration} />
              <div style={styles.titleSection}>
                <h3>
                  <span role="img" aria-label="letter">📧</span>
                  Guidance Counselor Letter
                </h3>
                <div style={styles.subtitle}>Create and manage your guidance counselor letter</div>
              </div>
              <div style={styles.infoSection}>
                <div style={{ width: '100%' }}>
                  <div style={styles.progressBarContainer}>
                    <div style={{...styles.progressBar, width: `${progress.guidanceCounselorLetter}%`}} />
                  </div>
                  <div style={styles.progressText}>
                    {`${progress.guidanceCounselorLetter}% Complete`}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    padding: '0 32px',
    minHeight: 'calc(100vh - 72px)',
    marginTop: '-12px',
    background: 'linear-gradient(to bottom right, #f0f9ff, #ffffff)',
    width: '100%',
  },
  mainContent: {
    width: '100%',
    maxWidth: '1400px',
    margin: '20px auto',
  },
  gridContainer: {
    backgroundColor: '#ffffff',
    padding: '32px',
    borderRadius: '16px',
    boxShadow: '0 4px 20px rgba(49, 130, 206, 0.1)',
    marginBottom: '24px',
    border: '1px solid rgba(49, 130, 206, 0.1)',
  },
  box: {
    backgroundColor: '#ffffff',
    borderRadius: '12px',
    padding: '28px',
    border: '1px solid #e2e8f0',
    transition: 'all 0.3s ease',
    cursor: 'pointer',
    position: 'relative',
    overflow: 'hidden',
    '&:hover': {
      transform: 'translateY(-4px)',
      boxShadow: '0 12px 24px rgba(49, 130, 206, 0.15)',
      borderColor: '#3182CE',
    },
  },
  titleSection: {
    marginBottom: '20px',
    position: 'relative',
    zIndex: 1,
    '& h3': {
      fontSize: '20px',
      fontWeight: '600',
      color: '#2B6CB0',
      marginBottom: '8px',
      display: 'flex',
      alignItems: 'center',
      gap: '8px',
    },
  },
  infoSection: {
    position: 'relative',
    zIndex: 1,
  },
  arrow: {
    position: 'absolute',
    right: '20px',
    fontSize: '24px',
    color: '#3498db',
  },
  select: {
    padding: '8px 15px',
    width: '200px',
    border: '1px solid #ddd',
    borderRadius: '4px',
    fontSize: '16px',
    cursor: 'pointer',
    textAlign: 'center',
  },
  reviewSection: {
    marginTop: '50px',
    padding: '20px',
    backgroundColor: '#f1f1f1',
    borderRadius: '10px',
    textAlign: 'left',
    maxWidth: '600px',
    margin: 'auto',
  },
  betaTag: {
    color: '#007bff',
  },
  instructionText: {
    marginBottom: '20px',
    lineHeight: '1.6',
    fontSize: '16px',
  },
  reviewSelect: {
    padding: '10px',
    borderRadius: '5px',
    border: '1px solid #ccc',
    backgroundColor: '#6c757d',
    color: '#fff',
    width: '100%',
    fontSize: '14px',
    textAlign: 'center',
    transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',  // Add transition for smooth effect
    ':hover': {
      transform: 'scale(1.05)',  // Scale up on hover
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',  // Add shadow
    },
  },
  sendButton: {
    marginTop: '20px',
    padding: '10px 20px',
    backgroundColor: '#007bff',
    color: '#fff',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',  // Add transition for smooth effect
    ':hover': {
      transform: 'scale(1.05)',  // Scale up on hover
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',  // Add shadow
    },
  },
  textCenter: {
    textAlign: 'center',
  },
  progressBarContainer: {
    width: '100%',
    height: '12px',
    backgroundColor: '#EBF8FF',
    borderRadius: '8px',
    overflow: 'hidden',
    marginTop: '12px',
    boxShadow: 'inset 0 2px 4px rgba(0, 0, 0, 0.05)',
  },
  progressBar: {
    height: '100%',
    background: 'linear-gradient(to right, #3182CE, #4299E1)',
    borderRadius: '8px',
    transition: 'width 0.4s ease',
  },
  progressText: {
    fontSize: '15px',
    color: '#4A5568',
    marginTop: '8px',
    textAlign: 'right',
    fontWeight: '500',
  },
  boxDecoration: {
    position: 'absolute',
    top: 0,
    right: 0,
    width: '120px',
    height: '120px',
    background: 'radial-gradient(circle at top right, rgba(235, 248, 255, 0.8) 0%, transparent 70%)',
    opacity: 0.6,
    zIndex: 0,
  },
  subtitle: {
    color: '#718096',
    fontSize: '14px',
    fontWeight: '500',
    marginTop: '4px',
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
    gap: '24px',
    width: '100%',
    animation: 'fadeIn 0.5s ease-out',
  },
};

export default AdminMaterials;

