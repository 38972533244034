import React, { useState, useEffect } from 'react';
import { useAuth } from '../utils/AuthContext';

const Profile = () => {
  const { user } = useAuth();
  const [profile, setProfile] = useState({
    profilePicture: '',
    parentName: '',
    kids: '',
    bio: '',
    city: '',
    state: '',
    curriculum: '',
    extracurriculars: '',
    links: [],
    friends: [],
    statuses: {
      schoolPhilosophy: 'not started',
      transcript: 'not started',
      courseDescriptions: 'not started',
      gradingRubric: 'not started',
      guidanceCounselorLetter: 'not started',
    }
  });
  const [searchResults, setSearchResults] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [friends, setFriends] = useState([]);
  const [selectedProfile, setSelectedProfile] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loadingMessage, setLoadingMessage] = useState('Loading profile data...');
  const [topProfiles, setTopProfiles] = useState([]);

  // Automatically save profile on any data change
  useEffect(() => {
    if (!loading && user?.id && Object.keys(profile).length > 0) {
      const timeoutId = setTimeout(() => {
        saveProfile();
      }, 1000); // Debounce save for 1 second
      
      return () => clearTimeout(timeoutId);
    }
  }, [profile, loading, user]);

  // Fetch the user profile and top profiles
  useEffect(() => {
    const fetchProfile = async () => {
      try {
        if (!user || !user.id) return;
        const response = await fetch(`/api/profile?userId=${user.id}`);
        if (response.ok) {
          const data = await response.json();
          setProfile(data.profile || {});
          setFriends(data.profile.friends || []);
        } else {
          setLoadingMessage('Failed to load profile');
        }
      } catch (error) {
        setLoadingMessage('Error loading profile');
      } finally {
        setLoading(false);
      }
    };

    const fetchTopProfiles = async () => {
      try {
        const response = await fetch('/api/profile?top=true');
        if (response.ok) {
          const data = await response.json();
          setTopProfiles(data.topProfiles || []);
        }
      } catch (error) {
        console.error('Error fetching top profiles:', error);
      }
    };

    fetchProfile();
    fetchTopProfiles();
  }, [user]);

  // Handle input change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === 'links') {
      setProfile({ ...profile, [name]: value.split(',').map(link => link.trim()) });
    } else {
      setProfile({ ...profile, [name]: value });
    }
  };

  // Search for profiles
  const handleSearch = async (e) => {
    const query = e.target.value;
    setSearchQuery(query);

    if (query.length > 0) {
      try {
        const response = await fetch(`/api/profile?name=${query}`);
        if (response.ok) {
          const data = await response.json();
          // Filter out profiles without a parentName
          setSearchResults(data.profiles.filter(profile => profile.parentName));
        }
      } catch (error) {
        console.error('Error searching profiles:', error);
      }
    } else {
      setSearchResults([]);
    }
  };

  // Add a friend
  const handleAddFriend = async (friendId) => {
    try {
      if (friends.some(f => f.userId === friendId)) {
        alert('You are already friends with this person!');
        return;
      }

      const friendProfileResponse = await fetch(`/api/profile?userId=${friendId}`);
      if (friendProfileResponse.ok) {
        const friendProfileData = await friendProfileResponse.json();

        const updatedFriends = [...friends, {
          userId: friendProfileData.profile.userId,
          parentName: friendProfileData.profile.parentName,
          profilePicture: friendProfileData.profile.profilePicture,
        }];

        const saveResponse = await fetch(`/api/profile`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            ...profile,
            friends: updatedFriends.map(f => f.userId),
            userId: user.id,
          }),
        });

        if (saveResponse.ok) {
          setFriends(updatedFriends);
          updateTopProfiles();  // Update top profiles with the new friends data
        }
      }
    } catch (error) {
      console.error('Error adding friend:', error);
    }
  };

  // Remove a friend
  const handleRemoveFriend = async (friendId) => {
    try {
      const updatedFriends = friends.filter(f => f.userId !== friendId);

      const saveResponse = await fetch(`/api/profile`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          ...profile,
          friends: updatedFriends.map(f => f.userId),
          userId: user.id,
        }),
      });

      if (saveResponse.ok) {
        setFriends(updatedFriends);
      }
    } catch (error) {
      console.error('Error removing friend:', error);
    }
  };

  // Save profile changes
  const saveProfile = async () => {
    try {
      // Don't save if user is not available
      if (!user || !user.id) {
        console.log('User not available, skipping profile save');
        return;
      }

      // Don't save if profile is empty (initial state)
      if (!profile.parentName && !profile.bio && !profile.kids) {
        console.log('Profile is empty, skipping save');
        return;
      }

      console.log('Saving profile:', { ...profile, userId: user.id });
      
      const response = await fetch(`/api/profile`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 
          ...profile, 
          userId: user.id,
          // Ensure these fields are always present even if empty
          friends: profile.friends || [],
          links: profile.links || [],
          statuses: profile.statuses || {}
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        console.error('Failed to save profile:', errorData);
        throw new Error(errorData.message || 'Failed to save profile');
      }
    } catch (error) {
      console.error('Error saving profile:', error);
    }
  };

  // Handle profile picture change
  const handleProfilePictureChange = (e) => {
    const file = e.target.files[0];

    const reader = new FileReader();
    reader.onloadend = () => {
      setProfile({ ...profile, profilePicture: reader.result });
    };
    reader.readAsDataURL(file);
  };

  // Handle profile click to display friend's profile
  const handleProfileClick = async (profile) => {
    try {
      const response = await fetch(`/api/profile?userId=${profile.userId}`);
      if (response.ok) {
        const profileData = await response.json();
        setSelectedProfile(profileData.profile);
      }
    } catch (error) {
      console.error('Error loading selected profile:', error);
    }
  };

  // Update top profiles based on the number of friends
  const updateTopProfiles = async () => {
    try {
      const response = await fetch('/api/profile?top=true');
      if (response.ok) {
        const data = await response.json();
        setTopProfiles(data.topProfiles.filter(profile => profile.parentName && profile.parentName.trim() !== ''));
      }
    } catch (error) {
      console.error('Error updating top profiles:', error);
    }
  };

  return (
    <div style={styles.container}>
      {loading && <p style={styles.loadingMessage}>{loadingMessage}</p>}
      <div style={styles.leftColumn}>
        <h2 style={styles.sectionHeader}>Your Profile</h2>
        <div style={styles.profilePictureContainer}>
          {profile.profilePicture && (
            <img src={profile.profilePicture} alt="Profile" style={styles.profilePicture} />
          )}
          <input
            type="file"
            accept="image/*"
            onChange={handleProfilePictureChange}
            style={styles.fileInput}
          />
        </div>
        <label style={styles.label}>
          Parent Name:
          <input
            name="parentName"
            value={profile.parentName || ''}
            onChange={handleInputChange}
            style={styles.input}
          />
        </label>
        <label style={styles.label}>
          Kids (Name, Age):
          <input
            name="kids"
            value={profile.kids || ''}
            onChange={handleInputChange}
            style={styles.input}
            placeholder="e.g., John, 12"
          />
        </label>
        <label style={styles.label}>
          General Bio:
          <textarea
            name="bio"
            value={profile.bio || ''}
            onChange={handleInputChange}
            style={styles.textarea}
          />
        </label>
        <label style={styles.label}>
          City:
          <input
            name="city"
            value={profile.city || ''}
            onChange={handleInputChange}
            style={styles.input}
          />
        </label>
        <label style={styles.label}>
          State:
          <input
            name="state"
            value={profile.state || ''}
            onChange={handleInputChange}
            style={styles.input}
          />
        </label>
        <label style={styles.label}>
          Curriculum:
          <textarea
            name="curriculum"
            value={profile.curriculum || ''}
            onChange={handleInputChange}
            style={styles.textarea}
          />
        </label>
        <label style={styles.label}>
          Extracurriculars:
          <textarea
            name="extracurriculars"
            value={profile.extracurriculars || ''}
            onChange={handleInputChange}
            style={styles.textarea}
          />
        </label>
        <label style={styles.label}>
          Favorite Resources and Links:
          <textarea
            name="links"
            value={profile.links?.join(', ') || ''}
            onChange={handleInputChange}
            style={styles.textarea}
          />
        </label>
      </div>

      <div style={styles.middleColumn}>
        <h2 style={styles.sectionHeader}>Search Profiles</h2>
        <input
          value={searchQuery}
          onChange={handleSearch}
          placeholder="Search by name..."
          style={styles.input}
        />
        <div>
          {searchResults.map((result) => (
            <div key={result._id} style={styles.profileCard}>
              <h4 onClick={() => handleProfileClick(result)} style={styles.profileName}>
                {result.parentName}
              </h4>
              {/* Removed Add Friend button from search results */}
            </div>
          ))}
        </div>
        {selectedProfile && (
          <div style={styles.selectedProfile}>
            <h3>{selectedProfile.parentName}</h3>
            <img src={selectedProfile.profilePicture} alt="Profile" style={styles.profilePicture} />
            <p><strong>Bio:</strong> {selectedProfile.bio}</p>
            <p><strong>Kids:</strong> {selectedProfile.kids}</p>
            <p><strong>City:</strong> {selectedProfile.city}</p>
            <p><strong>State:</strong> {selectedProfile.state}</p>
            <p><strong>Curriculum:</strong> {selectedProfile.curriculum}</p>
            <p><strong>Extracurriculars:</strong> {selectedProfile.extracurriculars}</p>
            <p><strong>Links:</strong> {selectedProfile.links?.map((link, index) => (
              <a key={index} href={link.startsWith('http') ? link : `http://${link}`} target="_blank" rel="noopener noreferrer" style={styles.link}>{link}</a>
            ))}</p>
          </div>
        )}

        <h2 style={{ ...styles.sectionHeader, marginTop: '40px' }}>Top Profiles</h2>
        <div>
          {topProfiles
            .filter(profile => profile.parentName && profile.parentName.trim() !== '')
            .sort((a, b) => b.friends.length - a.friends.length)
            .map((topProfile) => (
              <div key={topProfile.userId} style={styles.profileCard}>
                <h4 onClick={() => handleProfileClick(topProfile)} style={styles.profileLink}>
                  {topProfile.parentName}
                </h4>
              </div>
            ))}
        </div>
      </div>

      {/*<div style={styles.rightColumn}>
        <h2 style={styles.sectionHeader}>Your Friends ({friends.length})</h2>
        {friends.map((friend) => (
          <div key={friend.userId} style={styles.profileCard}>
            <p onClick={() => handleProfileClick(friend)} style={styles.profileLink}>
              {friend.parentName}
            </p>
            <button onClick={() => handleRemoveFriend(friend.userId)} style={styles.removeFriendButton}>X</button>
          </div>
        ))}
      </div>*/}
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    padding: '20px',
    gap: '20px',
  },
  loadingMessage: {
    color: '#007BFF',
    fontWeight: 'bold',
    fontSize: '16px',
    marginBottom: '20px',
  },
  leftColumn: {
    flex: 1,
    padding: '20px',
    border: '1px solid #ccc',
    borderRadius: '10px',
    backgroundColor: '#ffffff',
  },
  middleColumn: {
    flex: 2,
    padding: '20px',
    border: '1px solid #ccc',
    borderRadius: '10px',
    backgroundColor: '#ffffff',
  },
  rightColumn: {
    flex: 1,
    padding: '20px',
    border: '1px solid #ccc',
    borderRadius: '10px',
    backgroundColor: '#ffffff',
  },
  profilePictureContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '20px',
  },
  profilePicture: {
    width: '150px',
    height: '150px',
    borderRadius: '50%',
    marginBottom: '10px',
    objectFit: 'cover',
  },
  fileInput: {
    display: 'block',
  },
  label: {
    display: 'block',
    marginBottom: '10px',
    fontWeight: 'bold',
  },
  input: {
    width: '100%',
    padding: '10px',
    marginBottom: '10px',
    borderRadius: '4px',
    border: '1px solid #ced4da',
  },
  textarea: {
    width: '100%',
    padding: '10px',
    marginBottom: '10px',
    borderRadius: '4px',
    border: '1px solid #ced4da',
    height: '100px',
  },
  addFriendButton: {
    padding: '8px 16px',
    backgroundColor: '#28a745',
    color: '#FFFFFF',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    fontWeight: 'bold',
    position: 'absolute',
    top: '10px',
    right: '10px',
  },
  removeFriendButton: {
    padding: '5px',
    backgroundColor: '#dc3545',
    color: '#FFFFFF',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    fontWeight: 'bold',
    position: 'absolute',
    top: '10px',
    right: '10px',
  },
  sectionHeader: {
    fontSize: '20px',
    marginBottom: '20px',
    color: '#00356b',
  },
  profileCard: {
    border: '1px solid #ccc',
    padding: '10px',
    borderRadius: '8px',
    marginBottom: '10px',
    position: 'relative',
  },
  profileName: {
    cursor: 'pointer',
    color: '#007BFF',
    textDecoration: 'underline',
  },
  profileLink: {
    cursor: 'pointer',
    color: '#007BFF',
    textDecoration: 'underline',
  },
  selectedProfile: {
    marginTop: '20px',
    padding: '20px',
    border: '1px solid #ced4da',
    borderRadius: '10px',
    backgroundColor: '#f8f9fa',
    position: 'relative',
  },
  link: {
    display: 'block',
    color: '#007BFF',
    textDecoration: 'none',
    marginBottom: '5px',
  },
};

export default Profile;
