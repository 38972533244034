import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../utils/AuthContext';
import PageHeader from '../components/PageHeader';

const MyCourses = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [courses, setCourses] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newCourse, setNewCourse] = useState({
    name: '',
    subject: '',
    term: '',
    teacher: '',
    description: ''
  });

  const subjects = [
    'Mathematics',
    'Science',
    'English',
    'History',
    'Foreign Language',
    'Art',
    'Music',
    'Physical Education',
    'Computer Science',
    'Other'
  ];

  useEffect(() => {
    // Load courses from backend/localStorage
    const loadCourses = async () => {
      const savedCourses = localStorage.getItem('userCourses');
      if (savedCourses) {
        setCourses(JSON.parse(savedCourses));
      }
    };
    loadCourses();
  }, [user?.id]);

  const handleCourseClick = (courseId) => {
    navigate(`/mycourses/${courseId}`);
  };

  const handleCreateCourse = () => {
    setIsModalOpen(true);
  };

  const handleSubmitCourse = () => {
    const courseId = Date.now().toString(); // Simple unique ID generation
    const courseData = {
      id: courseId,
      ...newCourse,
      progress: 0,
      modules: [],
      assignments: []
    };

    const updatedCourses = [...courses, courseData];
    setCourses(updatedCourses);
    localStorage.setItem('userCourses', JSON.stringify(updatedCourses));

    // Reset form and close modal
    setNewCourse({
      name: '',
      subject: '',
      term: '',
      teacher: '',
      description: ''
    });
    setIsModalOpen(false);
  };

  return (
    <div style={styles.container}>
      <PageHeader 
        title="My Courses" 
        buttonText="+ Create New Course"
        onButtonClick={handleCreateCourse}
      />

      <div style={styles.courseGrid}>
        {courses.map((course) => (
          <div 
            key={course.id}
            style={styles.courseCard}
            onClick={() => handleCourseClick(course.id)}
          >
            <div style={styles.courseHeader} className={course.subject.toLowerCase()}>
              <h3 style={styles.courseName}>{course.name}</h3>
            </div>
            <div style={styles.courseInfo}>
              <p style={styles.courseTeacher}>Teacher: {course.teacher}</p>
              <p style={styles.courseTerm}>Term: {course.term}</p>
              <div style={styles.courseProgress}>
                <div style={styles.progressBar}>
                  <div 
                    style={{
                      ...styles.progressFill,
                      width: `${course.progress || 0}%`
                    }}
                  />
                </div>
                <span style={styles.progressText}>{course.progress || 0}% Complete</span>
              </div>
            </div>
          </div>
        ))}
      </div>

      {isModalOpen && (
        <div style={styles.modalOverlay}>
          <div style={styles.modal}>
            <h2 style={styles.modalTitle}>Create New Course</h2>
            <div style={styles.formGroup}>
              <label style={styles.label}>Course Name</label>
              <input
                type="text"
                style={styles.input}
                value={newCourse.name}
                onChange={(e) => setNewCourse({...newCourse, name: e.target.value})}
                placeholder="Enter course name"
              />
            </div>
            <div style={styles.formGroup}>
              <label style={styles.label}>Subject</label>
              <select
                style={styles.input}
                value={newCourse.subject}
                onChange={(e) => setNewCourse({...newCourse, subject: e.target.value})}
              >
                <option value="">Select subject</option>
                {subjects.map(subject => (
                  <option key={subject} value={subject}>{subject}</option>
                ))}
              </select>
            </div>
            <div style={styles.formGroup}>
              <label style={styles.label}>Term</label>
              <input
                type="text"
                style={styles.input}
                value={newCourse.term}
                onChange={(e) => setNewCourse({...newCourse, term: e.target.value})}
                placeholder="e.g., Fall 2024"
              />
            </div>
            <div style={styles.formGroup}>
              <label style={styles.label}>Teacher</label>
              <input
                type="text"
                style={styles.input}
                value={newCourse.teacher}
                onChange={(e) => setNewCourse({...newCourse, teacher: e.target.value})}
                placeholder="Enter teacher name"
              />
            </div>
            <div style={styles.formGroup}>
              <label style={styles.label}>Description</label>
              <textarea
                style={{...styles.input, height: '100px'}}
                value={newCourse.description}
                onChange={(e) => setNewCourse({...newCourse, description: e.target.value})}
                placeholder="Enter course description"
              />
            </div>
            <div style={styles.modalButtons}>
              <button 
                style={{...styles.button, backgroundColor: '#gray'}}
                onClick={() => setIsModalOpen(false)}
              >
                Cancel
              </button>
              <button 
                style={{...styles.button, backgroundColor: '#4CAF50'}}
                onClick={handleSubmitCourse}
              >
                Create Course
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const styles = {
  container: {
    padding: '2rem',
    maxWidth: '1200px',
    margin: '0 auto',
  },
  courseGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
    gap: '2rem',
  },
  courseCard: {
    backgroundColor: 'white',
    borderRadius: '8px',
    overflow: 'hidden',
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
    cursor: 'pointer',
    transition: 'transform 0.2s',
    '&:hover': {
      transform: 'translateY(-4px)',
    },
  },
  courseHeader: {
    padding: '1.5rem',
    color: 'white',
  },
  courseName: {
    margin: 0,
    fontSize: '1.25rem',
  },
  courseInfo: {
    padding: '1rem',
  },
  courseTeacher: {
    margin: '0 0 0.5rem 0',
    color: '#666',
  },
  courseTerm: {
    margin: '0 0 1rem 0',
    color: '#666',
  },
  courseProgress: {
    marginTop: '1rem',
  },
  progressBar: {
    height: '8px',
    backgroundColor: '#eee',
    borderRadius: '4px',
    overflow: 'hidden',
  },
  progressFill: {
    height: '100%',
    backgroundColor: '#4CAF50',
    transition: 'width 0.3s',
  },
  progressText: {
    fontSize: '0.875rem',
    color: '#666',
    marginTop: '0.25rem',
  },
  modalOverlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
  },
  modal: {
    backgroundColor: 'white',
    borderRadius: '8px',
    padding: '2rem',
    width: '90%',
    maxWidth: '500px',
    maxHeight: '90vh',
    overflow: 'auto',
  },
  modalTitle: {
    fontSize: '1.5rem',
    marginBottom: '1.5rem',
    color: '#333',
  },
  formGroup: {
    marginBottom: '1rem',
  },
  label: {
    display: 'block',
    marginBottom: '0.5rem',
    color: '#666',
  },
  input: {
    width: '100%',
    padding: '0.5rem',
    borderRadius: '4px',
    border: '1px solid #ddd',
    fontSize: '1rem',
  },
  modalButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '1rem',
    marginTop: '1.5rem',
  },
  button: {
    padding: '0.75rem 1.5rem',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    fontSize: '1rem',
    color: 'white',
  },
};

export default MyCourses; 