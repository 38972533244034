import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAuth } from '../utils/AuthContext';

const CourseDetail = () => {
  const { courseId } = useParams();
  const { user } = useAuth();
  const [course, setCourse] = useState(null);
  const [activeTab, setActiveTab] = useState('modules');

  useEffect(() => {
    // Load course data
    const loadCourse = () => {
      const savedCourses = JSON.parse(localStorage.getItem('userCourses') || '[]');
      const foundCourse = savedCourses.find(c => c.id === courseId);
      setCourse(foundCourse);
    };
    loadCourse();
  }, [courseId]);

  if (!course) return <div>Loading...</div>;

  return (
    <div style={styles.container}>
      <div style={styles.header}>
        <h1 style={styles.title}>{course.name}</h1>
        <p style={styles.subtitle}>{course.term} | {course.teacher}</p>
      </div>

      <div style={styles.navigation}>
        <button 
          style={{
            ...styles.navButton,
            backgroundColor: activeTab === 'modules' ? '#4CAF50' : 'transparent'
          }}
          onClick={() => setActiveTab('modules')}
        >
          Modules
        </button>
        <button 
          style={{
            ...styles.navButton,
            backgroundColor: activeTab === 'assignments' ? '#4CAF50' : 'transparent'
          }}
          onClick={() => setActiveTab('assignments')}
        >
          Assignments
        </button>
        <button 
          style={{
            ...styles.navButton,
            backgroundColor: activeTab === 'grades' ? '#4CAF50' : 'transparent'
          }}
          onClick={() => setActiveTab('grades')}
        >
          Grades
        </button>
      </div>

      <div style={styles.content}>
        {activeTab === 'modules' && (
          <div style={styles.moduleList}>
            {course.modules?.map((module, index) => (
              <div key={index} style={styles.module}>
                <h3 style={styles.moduleTitle}>Module {index + 1}: {module.title}</h3>
                <div style={styles.moduleContent}>
                  {module.items?.map((item, itemIndex) => (
                    <div key={itemIndex} style={styles.moduleItem}>
                      <span style={styles.itemIcon}>📄</span>
                      <span style={styles.itemTitle}>{item.title}</span>
                      <span style={styles.itemStatus}>{item.status}</span>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        )}

        {activeTab === 'assignments' && (
          <div style={styles.assignmentList}>
            {course.assignments?.map((assignment, index) => (
              <div key={index} style={styles.assignment}>
                <h3 style={styles.assignmentTitle}>{assignment.title}</h3>
                <p style={styles.assignmentDue}>Due: {assignment.dueDate}</p>
                <button style={styles.submitButton}>
                  Submit Assignment
                </button>
              </div>
            ))}
          </div>
        )}

        {activeTab === 'grades' && (
          <div style={styles.gradesList}>
            <div style={styles.gradesSummary}>
              <h3>Course Grade: {course.grade || 'N/A'}</h3>
            </div>
            {course.assignments?.map((assignment, index) => (
              <div key={index} style={styles.gradeItem}>
                <span>{assignment.title}</span>
                <span>{assignment.grade || 'Not graded'}</span>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

const styles = {
  container: {
    padding: '2rem',
    maxWidth: '1200px',
    margin: '0 auto',
  },
  header: {
    marginBottom: '2rem',
    borderBottom: '1px solid #eee',
    paddingBottom: '1rem',
  },
  title: {
    fontSize: '2rem',
    color: '#333',
    margin: '0 0 0.5rem 0',
  },
  subtitle: {
    color: '#666',
    margin: 0,
  },
  navigation: {
    display: 'flex',
    gap: '1rem',
    marginBottom: '2rem',
  },
  navButton: {
    padding: '0.75rem 1.5rem',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    color: '#333',
    fontSize: '1rem',
    '&:hover': {
      backgroundColor: '#e0e0e0',
    },
  },
  // ... more styles for modules, assignments, and grades
};

export default CourseDetail; 