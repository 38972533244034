import React from 'react';
import PageHeader from '../components/PageHeader';

const CareerExploration = () => {
  return (
    <div style={styles.container}>
      <PageHeader title="Career Exploration" />
      
      <div style={styles.content}>
        <div style={styles.introSection}>
          <p style={styles.intro}>
            Exploring potential career paths is an important part of your educational journey. 
            We've curated some valuable resources to help you better understand your personality type 
            and potential career matches.
          </p>
        </div>

        <div style={styles.quizzesGrid}>
          {/* 16 Personalities Quiz */}
          <div style={styles.quizCard}>
            <div style={styles.quizHeader}>
              <h2 style={styles.quizTitle}>16 Personalities Test</h2>
              <span style={styles.duration}>Duration: ~12 minutes</span>
            </div>
            
            <p style={styles.quizDescription}>
              The 16 Personalities test is based on the Myers-Briggs Type Indicator (MBTI), which helps you 
              understand your personality type, strengths, and potential career paths. This test provides 
              insights into how you interact with others, process information, and make decisions.
            </p>
            
            <div style={styles.keyPoints}>
              <h3 style={styles.keyPointsTitle}>What you'll learn:</h3>
              <ul style={styles.keyPointsList}>
                <li>Your personality type out of 16 possible combinations</li>
                <li>Your natural strengths and potential areas for growth</li>
                <li>Career paths that typically suit your personality type</li>
                <li>How you tend to behave in relationships and workplace settings</li>
              </ul>
            </div>

            <a 
              href="https://www.16personalities.com/free-personality-test"
              target="_blank"
              rel="noopener noreferrer"
              style={styles.quizButton}
            >
              Take the 16 Personalities Test
            </a>
          </div>

          {/* Princeton Review Career Quiz */}
          <div style={styles.quizCard}>
            <div style={styles.quizHeader}>
              <h2 style={styles.quizTitle}>Princeton Review Career Quiz</h2>
              <span style={styles.duration}>Duration: ~5 minutes</span>
            </div>
            
            <p style={styles.quizDescription}>
              The Princeton Review Career Quiz helps match your interests and preferences with potential 
              career paths. This quiz focuses on practical scenarios and work preferences to suggest 
              careers that might be a good fit for you.
            </p>
            
            <div style={styles.keyPoints}>
              <h3 style={styles.keyPointsTitle}>What you'll learn:</h3>
              <ul style={styles.keyPointsList}>
                <li>Career fields that match your interests</li>
                <li>Specific job titles to explore</li>
                <li>Work environments that might suit you</li>
                <li>Types of tasks you might enjoy in your career</li>
              </ul>
            </div>

            <a 
              href="https://www.princetonreview.com/quiz/career-quiz"
              target="_blank"
              rel="noopener noreferrer"
              style={styles.quizButton}
            >
              Take the Princeton Review Career Quiz
            </a>
          </div>
        </div>

        <div style={styles.tipsSection}>
          <h2 style={styles.tipsTitle}>Making the Most of These Assessments</h2>
          <ul style={styles.tipsList}>
            <li>Take these quizzes when you're relaxed and can focus</li>
            <li>Answer honestly rather than what you think you "should" answer</li>
            <li>Remember that these are guides, not definitive answers</li>
            <li>Use the results as starting points for further career research</li>
            <li>Consider retaking the assessments after a few months to see if your preferences have changed</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

const styles = {
  container: {
    padding: '2rem',
    maxWidth: '1200px',
    margin: '0 auto',
  },
  title: {
    fontSize: '2.5rem',
    color: '#333',
    marginBottom: '1.5rem',
    textAlign: 'center',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: '2rem',
  },
  introSection: {
    textAlign: 'center',
    maxWidth: '800px',
    margin: '0 auto',
  },
  intro: {
    fontSize: '1.1rem',
    color: '#666',
    lineHeight: '1.6',
  },
  quizzesGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
    gap: '2rem',
    margin: '2rem 0',
  },
  quizCard: {
    backgroundColor: 'white',
    borderRadius: '12px',
    padding: '2rem',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    display: 'flex',
    flexDirection: 'column',
    gap: '1.5rem',
  },
  quizHeader: {
    borderBottom: '2px solid #f0f0f0',
    paddingBottom: '1rem',
  },
  quizTitle: {
    fontSize: '1.5rem',
    color: '#333',
    marginBottom: '0.5rem',
  },
  duration: {
    color: '#666',
    fontSize: '0.9rem',
  },
  quizDescription: {
    color: '#555',
    lineHeight: '1.6',
  },
  keyPoints: {
    backgroundColor: '#f8f9fa',
    padding: '1.5rem',
    borderRadius: '8px',
  },
  keyPointsTitle: {
    fontSize: '1.1rem',
    color: '#333',
    marginBottom: '1rem',
  },
  keyPointsList: {
    paddingLeft: '1.5rem',
    color: '#555',
    '& li': {
      marginBottom: '0.5rem',
    },
  },
  quizButton: {
    display: 'inline-block',
    backgroundColor: '#4CAF50',
    color: 'white',
    padding: '1rem 2rem',
    borderRadius: '6px',
    textDecoration: 'none',
    textAlign: 'center',
    fontWeight: '500',
    transition: 'background-color 0.2s',
    marginTop: 'auto',
    '&:hover': {
      backgroundColor: '#45a049',
    },
  },
  tipsSection: {
    backgroundColor: '#f8f9fa',
    padding: '2rem',
    borderRadius: '12px',
    marginTop: '2rem',
  },
  tipsTitle: {
    fontSize: '1.5rem',
    color: '#333',
    marginBottom: '1rem',
  },
  tipsList: {
    color: '#555',
    paddingLeft: '1.5rem',
    '& li': {
      marginBottom: '0.75rem',
      lineHeight: '1.6',
    },
  },
};

export default CareerExploration;
