import React, { useState, useEffect, useRef, useCallback } from 'react';
import { PDFDownloadLink, Document, Page, Text, View, StyleSheet, PDFViewer, Font } from '@react-pdf/renderer';
import Modal from 'react-modal';
import { useAuth } from '../utils/AuthContext';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import logo from '../assets/logo.png';

// Register Helvetica fonts from src/assets folder
Font.register({
  family: 'Helvetica',
  fonts: [
    { src: '/src/assets/fonts/Helvetica.ttf', fontWeight: 'normal' },  // Regular font
    { src: '/src/assets/fonts/Helvetica-Bold.ttf', fontWeight: 'bold' }, // Bold font
    { src: '/src/assets/fonts/Helvetica-Oblique.ttf', fontStyle: 'italic' }, // Italic font
  ],
});

// Register the DollieScript font
Font.register({
  family: 'DollieScript',
  fonts: [
    {
      src: '/src/assets/fonts/DollieScriptPersonalUse.ttf',
    },
  ],
});

// Define default form data structure
const defaultFormData = {
  name: '',
  gender: '',
  address: '',
  city: '',
  state: '',
  zip: '',
  dob: '',
  parentGuardian: '',
  studentEmail: '',
  projectedGradDate: '',
  parentEmail: '',
  schoolName: '',
  schoolPhone: '',
  schoolAddress: '',
  schoolCity: '',
  schoolState: '',
  schoolZip: '',
  issueDate: '',
  graduationDate: '',
  freshmanYear: '',
  sophomoreYear: '',
  juniorYear: '',
  seniorYear: '',
  preHighSchoolYear: '',
  freshmanCourses: [{ method: '', courseTitle: '', term1Grade: '', term2Grade: '', term3Grade: '', credits: '', apScore: '' }],
  sophomoreCourses: [{ method: '', courseTitle: '', term1Grade: '', term2Grade: '', term3Grade: '', credits: '', apScore: '' }],
  juniorCourses: [{ method: '', courseTitle: '', term1Grade: '', term2Grade: '', term3Grade: '', credits: '', apScore: '' }],
  seniorCourses: [{ method: '', courseTitle: '', term1Grade: '', term2Grade: '', term3Grade: '', credits: '', apScore: '' }],
  preHighSchoolCourses: [{ method: '', courseTitle: '', term1Grade: '', term2Grade: '', term3Grade: '', credits: '' }],
  cumulativeSummary: {
    totalCredits: 0,
    gpaCredits: 0,
    gpaPoints: 0,
    cumulativeGPA: 0,
    weightedGPA: '',
  },
  testScores: '',
  gradingScale: {
    show: false,
  },
  miscellaneous: '',
  signatureDate: '',
  pdfData: '',
};

const Transcript = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const saveTimeoutRef = useRef(null);

  // Initialize with localStorage but always fetch from backend
  const [formData, setFormData] = useState(() => {
    const cachedData = user?.id ? localStorage.getItem(`transcript_${user.id}`) : null;
    return cachedData ? JSON.parse(cachedData) : defaultFormData;
  });

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [dataStatus, setDataStatus] = useState('Data saved');
  const [isInitialized, setIsInitialized] = useState(false);

  // Modify fetchTranscript to be the source of truth
  const fetchTranscript = useCallback(async () => {
    if (!user?.id) return;

    try {
      setLoading(true);
      setDataStatus('Loading data...');
      
      const response = await axios.get('/api/transcript', {
        params: { userId: user.id }
      });

      if (response.status === 200 && response.data.transcript) {
        // Backend data exists, use it as source of truth
        const processedData = {
          ...defaultFormData,
          ...response.data.transcript
        };
        setFormData(processedData);
        localStorage.setItem(`transcript_${user.id}`, JSON.stringify(processedData));
        setDataStatus('Data loaded from server');
      }
    } catch (error) {
      console.error('Error fetching transcript:', error);
      setDataStatus('Error loading from server');
    } finally {
      setLoading(false);
      setIsInitialized(true);
    }
  }, [user]);

  // Always fetch from backend on mount
  useEffect(() => {
    if (user?.id) {
      fetchTranscript();
    }
  }, [user, fetchTranscript]);

  // Update saveTranscript to save to backend first
  const saveTranscript = useCallback(async (updatedFormData) => {
    if (!user?.id) {
      console.error('Cannot save: missing user ID');
      return;
    }
    
    try {
      setSaving(true);
      setDataStatus('Saving to server...');
      
      const response = await axios.post('/api/transcript', {
        userId: user.id,
        formData: updatedFormData,
      });
      
      if (response.status === 200) {
        // Only update localStorage after successful backend save
        localStorage.setItem(`transcript_${user.id}`, JSON.stringify(updatedFormData));
        setFormData(updatedFormData);
        setDataStatus('Saved to server');
      }
    } catch (error) {
      console.error('Error saving transcript:', error);
      setDataStatus('Error saving to server');
      // Revert to backend data on save error
      fetchTranscript();
    } finally {
      setSaving(false);
    }
  }, [user, fetchTranscript]);

  // Add debouncedSave function like SchoolPhilosophy
  const debouncedSave = useCallback((updatedFormData) => {
    if (saveTimeoutRef.current) {
      clearTimeout(saveTimeoutRef.current);
    }
    saveTimeoutRef.current = setTimeout(() => {
      saveTranscript(updatedFormData);
    }, 1000);
  }, [saveTranscript]);

  // Handle changes with direct debouncing
  const handleChange = useCallback((e, grade, index) => {
    const { name, value } = e.target;
    const updatedCourses = [...formData[`${grade}Courses`]];
    updatedCourses[index] = { ...updatedCourses[index], [name]: value };
    const updatedFormData = { ...formData, [`${grade}Courses`]: updatedCourses };
    
    // Save to localStorage immediately
    localStorage.setItem(`transcript_${user.id}`, JSON.stringify(updatedFormData));
    
    // Update state
    setFormData(updatedFormData);
    
    // Update status and trigger save
    setDataStatus('Saving data...');
    setSaving(true);
    
    if (saveTimeoutRef.current) {
      clearTimeout(saveTimeoutRef.current);
    }
    
    saveTimeoutRef.current = setTimeout(() => {
      saveTranscript(updatedFormData);
    }, 1000);
  }, [formData, saveTranscript, user.id]);

  // Other handlers with direct debouncing
  const handleYearChange = useCallback((e) => {
    const { name, value } = e.target;
    const updatedFormData = { ...formData, [name]: value };
    setFormData(updatedFormData);
    
    if (saveTimeoutRef.current) {
      clearTimeout(saveTimeoutRef.current);
    }
    
    saveTimeoutRef.current = setTimeout(() => {
      saveTranscript(updatedFormData);
    }, 1000);
  }, [formData, saveTranscript]);

  const handleStudentInfoChange = useCallback((e) => {
    const { name, value } = e.target;
    const updatedFormData = { ...formData, [name]: value };
    
    // Save to localStorage immediately
    localStorage.setItem(`transcript_${user.id}`, JSON.stringify(updatedFormData));
    
    // Update state
    setFormData(updatedFormData);
    
    if (name === 'name') {
      localStorage.setItem('studentName', value);
    }
    
    // Update status and trigger save
    setDataStatus('Saving data...');
    setSaving(true);
    
    if (saveTimeoutRef.current) {
      clearTimeout(saveTimeoutRef.current);
    }
    
    saveTimeoutRef.current = setTimeout(() => {
      saveTranscript(updatedFormData);
    }, 1000);
  }, [formData, saveTranscript, user.id]);

  const handleAddCourse = (grade) => {
    const updatedCourses = [...formData[`${grade}Courses`], { method: '', courseTitle: '', term1Grade: '', term2Grade: '', term3Grade: '', credits: '', apScore: '' }];
    const updatedFormData = { ...formData, [`${grade}Courses`]: updatedCourses };
    setFormData(updatedFormData);
    if (saveTimeoutRef.current) {
      clearTimeout(saveTimeoutRef.current);
    }
    saveTimeoutRef.current = setTimeout(() => {
      saveTranscript(updatedFormData);
    }, 1000);
  };

  const handleRemoveCourse = (grade, index) => {
    const updatedCourses = formData[`${grade}Courses`].filter((_, i) => i !== index);
    const updatedFormData = { ...formData, [`${grade}Courses`]: updatedCourses };
    setFormData(updatedFormData);
    if (saveTimeoutRef.current) {
      clearTimeout(saveTimeoutRef.current);
    }
    saveTimeoutRef.current = setTimeout(() => {
      saveTranscript(updatedFormData);
    }, 1000);
  };

  const moveCourseUp = (grade, index) => {
    if (index === 0) return;
    const updatedCourses = [...formData[`${grade}Courses`]];
    [updatedCourses[index - 1], updatedCourses[index]] = [updatedCourses[index], updatedCourses[index - 1]];
    const updatedFormData = { ...formData, [`${grade}Courses`]: updatedCourses };
    setFormData(updatedFormData);
    if (saveTimeoutRef.current) {
      clearTimeout(saveTimeoutRef.current);
    }
    saveTimeoutRef.current = setTimeout(() => {
      saveTranscript(updatedFormData);
    }, 1000);
  };

  const moveCourseDown = (grade, index) => {
    if (index === formData[`${grade}Courses`].length - 1) return;
    const updatedCourses = [...formData[`${grade}Courses`]];
    [updatedCourses[index + 1], updatedCourses[index]] = [updatedCourses[index], updatedCourses[index + 1]];
    const updatedFormData = { ...formData, [`${grade}Courses`]: updatedCourses };
    setFormData(updatedFormData);
    if (saveTimeoutRef.current) {
      clearTimeout(saveTimeoutRef.current);
    }
    saveTimeoutRef.current = setTimeout(() => {
      saveTranscript(updatedFormData);
    }, 1000);
  };

  // GPA and Term GPA Calculations
  const calculateCumulativeSummary = () => {
    let totalCredits = 0;
    let gpaCredits = 0;
    let gpaPoints = 0;
    let unweightedGPA = 0;

    const gradeValues = {
      'A+': 4.3,
      'A': 4.0,
      'A-': 3.7,
      'B+': 3.3,
      'B': 3.0,
      'B-': 2.7,
      'C+': 2.3,
      'C': 2.0,
      'C-': 1.7,
      'D+': 1.3,
      'D': 1.0,
      'D-': 0.7,
      'F': 0
    };

    ['freshman', 'sophomore', 'junior', 'senior', 'preHighSchool'].forEach((grade) => {
      formData[`${grade}Courses`].forEach((course) => {
        const credits = parseFloat(course.credits) || 0;
        const grades = [course.term1Grade, course.term2Grade, course.term3Grade];

        totalCredits += credits;

        grades.forEach((grade) => {
          if (grade && !['P', 'Pass', 'N/A', 'n/a'].includes(grade)) {
            const gradeValue = gradeValues[grade] || 0;
            gpaCredits += credits;
            gpaPoints += credits * gradeValue;
          }
        });
      });
    });

    const cumulativeGPA = gpaCredits ? (gpaPoints / gpaCredits).toFixed(2) : 0;

    const updatedFormData = {
      ...formData,
      cumulativeSummary: {
        totalCredits: totalCredits.toFixed(1),
        cumulativeGPA,
        weightedGPA: formData.cumulativeSummary.weightedGPA // Preserve the existing weighted GPA
      }
    };
    setFormData(updatedFormData);
    if (saveTimeoutRef.current) {
      clearTimeout(saveTimeoutRef.current);
    }
    saveTimeoutRef.current = setTimeout(() => {
      saveTranscript(updatedFormData);
    }, 1000);
  };

  const handleGradingScaleToggle = () => {
    setFormData({ ...formData, gradingScale: { show: !formData.gradingScale.show } });
    if (saveTimeoutRef.current) {
      clearTimeout(saveTimeoutRef.current);
    }
    saveTimeoutRef.current = setTimeout(() => {
      saveTranscript(formData);
    }, 1000);
  };

  const handleMiscellaneousChange = (e) => {
    const { value } = e.target;
    const updatedFormData = { ...formData, miscellaneous: value };
    setFormData(updatedFormData);
    if (saveTimeoutRef.current) {
      clearTimeout(saveTimeoutRef.current);
    }
    saveTimeoutRef.current = setTimeout(() => {
      saveTranscript(updatedFormData);
    }, 1000);
  };

  const handleSignatureDateChange = (e) => {
    const { value } = e.target;
    setFormData({ ...formData, signatureDate: value });
    if (saveTimeoutRef.current) {
      clearTimeout(saveTimeoutRef.current);
    }
    saveTimeoutRef.current = setTimeout(() => {
      saveTranscript(formData);
    }, 1000);
  };

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const handleTestScoresChange = (e) => {
    const { value } = e.target;
    const updatedFormData = { ...formData, testScores: value };
    setFormData(updatedFormData);
    if (saveTimeoutRef.current) {
      clearTimeout(saveTimeoutRef.current);
    }
    saveTimeoutRef.current = setTimeout(() => {
      saveTranscript(updatedFormData);
    }, 1000);
  };

  const handleSavePDF = async () => {
    try {
      const response = await fetch('/api/transcript', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ ...formData, userId: user.id }),
      });

      if (!response.ok) {
        console.error('Failed to save PDF');
      }
    } catch (error) {
      console.error('Error saving PDF:', error);
    }
  };

  // Call handleSavePDF when generating the PDF
  const handlePreview = () => {
    openModal();
    handleSavePDF();
  };

  const handleDownload = () => {
    handleSavePDF();
  };

  const TranscriptDocument = ({ data }) => (
    <Document>
      <Page style={pdfStyles.page}>
        
        {/* Header Section */}
        <View style={pdfStyles.header}>
          <Text style={pdfStyles.studentName}>{data.name}</Text>
          <Text style={pdfStyles.title}>High School Transcript</Text>
        </View>

        {/* Student and School Information */}
        <View style={pdfStyles.section}>
          <View style={pdfStyles.rowCentered}>
            <View style={pdfStyles.column}>
              <Text style={pdfStyles.underlineBold}>Student Information</Text>
              <Text style={pdfStyles.text}>{data.name}</Text>
              <Text style={pdfStyles.text}>{data.address}</Text>
              <Text style={pdfStyles.text}>{`${data.city}, ${data.state} ${data.zip}`}</Text>
              <Text style={pdfStyles.text}>
                DOB: {data.dob 
                  ? new Date(data.dob + 'T00:00:00').toLocaleDateString('en-US', {
                      year: 'numeric', 
                      month: 'numeric', 
                      day: 'numeric'
                    }) 
                  : 'No Date Provided'}
              </Text>
              <Text style={pdfStyles.text}>{data.studentEmail}</Text>
            </View>

            <View style={pdfStyles.column}>
              <Text style={pdfStyles.underlineBold}>School Information</Text>
              <Text style={pdfStyles.text}>{data.schoolName}</Text>
              <Text style={pdfStyles.text}>{data.schoolAddress}</Text>
              <Text style={pdfStyles.text}>{`${data.schoolCity}, ${data.schoolState} ${data.schoolZip}`}</Text>
              <Text style={pdfStyles.text}>Contact: {data.parentGuardian}</Text>
              <Text style={pdfStyles.text}>{data.parentEmail}</Text>
            </View>

            <View style={pdfStyles.column}>
              <Text style={pdfStyles.underlineBold}>Issue Date</Text>
              <Text style={pdfStyles.text}>
                {new Date(new Date().toISOString().substring(0, 10) + 'T00:00:00').toLocaleDateString('en-US')}
              </Text>
              <Text style={pdfStyles.underlineBold}>Graduation Date</Text>
              <Text style={pdfStyles.text}>
                {data.projectedGradDate 
                  ? new Date(data.projectedGradDate + 'T00:00:00').toLocaleDateString('en-US', {
                      year: 'numeric', 
                      month: 'numeric', 
                      day: 'numeric'
                    }) 
                  : 'No Date Provided'}
              </Text>
            </View>
          </View>
        </View>

        {/* Pre-High School Section */}
        {data.preHighSchoolCourses && data.preHighSchoolCourses.length > 0 && (
          <View style={pdfStyles.section}>
            <Text style={pdfStyles.bold}>Pre-High School, {data.preHighSchoolYear}</Text>
            <View style={pdfStyles.table}>
              <View style={pdfStyles.tableRow}>
                <Text style={[pdfStyles.courseTitleCellWide, pdfStyles.tableHeader]}>Course Title</Text>
                <Text style={[pdfStyles.tableCell, pdfStyles.tableHeader]}>T1</Text>
                <Text style={[pdfStyles.tableCell, pdfStyles.tableHeader]}>T2</Text>
                <Text style={[pdfStyles.tableCell, pdfStyles.tableHeader]}>T3</Text>
                <Text style={[pdfStyles.tableCell, pdfStyles.tableHeader]}>Credit</Text>
              </View>
              {data.preHighSchoolCourses.map((course, i) => (
                <View key={i} style={pdfStyles.tableRow}>
                  <Text style={pdfStyles.courseTitleCellWide}>{course.courseTitle}</Text>
                  <Text style={pdfStyles.tableCell}>{course.term1Grade}</Text>
                  <Text style={pdfStyles.tableCell}>{course.term2Grade}</Text>
                  <Text style={pdfStyles.tableCell}>{course.term3Grade}</Text>
                  <Text style={pdfStyles.tableCell}>{course.credits}</Text>
                </View>
              ))}
            </View>
          </View>
        )}

        {/* Courses and Grades for High School */}
        <View style={pdfStyles.quadrants}>
          {['freshman', 'sophomore', 'junior', 'senior'].map((grade, idx) => (
            data[`${grade}Courses`] && data[`${grade}Courses`].length > 0 && (
              <View key={idx} style={pdfStyles.quadrant}>
                <Text style={pdfStyles.bold}>
                  {`${idx + 9}th Grade, ${data[`${grade}Year`]}`}
                </Text>
                <View style={pdfStyles.table}>
                  <View style={pdfStyles.tableRow}>
                    <Text style={[pdfStyles.courseTitleCellWide, pdfStyles.tableHeader]}>Course Title</Text>
                    <Text style={[pdfStyles.tableCellLeftAlign, pdfStyles.tableHeader]}>T1</Text>
                    <Text style={[pdfStyles.tableCellLeftAlign, pdfStyles.tableHeader]}>T2</Text>
                    <Text style={[pdfStyles.tableCellLeftAlign, pdfStyles.tableHeader]}>T3</Text>
                    <Text style={[pdfStyles.tableCell, pdfStyles.tableHeader]}>Credit</Text>
                  </View>

                  {data[`${grade}Courses`].map((course, i) => (
                    <View key={i} style={pdfStyles.tableRow}>
                      <Text style={pdfStyles.courseTitleCellWide}>{course.courseTitle}</Text>
                      <Text style={pdfStyles.tableCellLeftAlign}>{course.term1Grade}</Text>
                      <Text style={pdfStyles.tableCellLeftAlign}>{course.term2Grade}</Text>
                      <Text style={pdfStyles.tableCellLeftAlign}>{course.term3Grade}</Text>
                      <Text style={pdfStyles.tableCell}>{course.credits}</Text>
                    </View>
                  ))}

                  {/* Highlight Term GPA in a box */}
                  {(() => {
                    const gradeValues = {
                      'A+': 4.3, 'A': 4.0, 'A-': 3.7,
                      'B+': 3.3, 'B': 3.0, 'B-': 2.7,
                      'C+': 2.3, 'C': 2.0, 'C-': 1.7,
                      'D+': 1.3, 'D': 1.0, 'D-': 0.7,
                      'F': 0,
                    };

                    let totalCredits = 0;
                    let totalPoints = 0;

                    data[`${grade}Courses`].forEach((course) => {
                      const credits = parseFloat(course.credits) || 0;
                      const termGrades = [course.term1Grade, course.term2Grade, course.term3Grade];
                      
                      // Filter out undefined or empty grades
                      const validTermGrades = termGrades.filter(termGrade => gradeValues[termGrade] !== undefined);
                      
                      // If there are valid term grades, proceed
                      if (validTermGrades.length > 0) {
                        const creditPerTerm = credits / validTermGrades.length; // Split credits based on the number of terms with valid grades
                        
                        validTermGrades.forEach((termGrade) => {
                          totalCredits += creditPerTerm; // Add proportional credits for each valid term
                          totalPoints += creditPerTerm * gradeValues[termGrade]; // Add GPA points based on each valid term's grade
                        });
                      }
                    });

                    // Calculate the GPA for all the terms
                    const termGPA = totalCredits > 0 ? (totalPoints / totalCredits).toFixed(2) : "N/A";


                    return (
                      <View style={pdfStyles.gpaBox1}>
                        <Text style={pdfStyles.bold}>Term GPA: {termGPA}</Text>
                      </View>
                    );
                  })()}
                </View>
              </View>
            )
          ))}
        </View>

        {/* Cumulative Summary, Test Scores, and Miscellaneous Section */}
        <View style={pdfStyles.fullWidthBox}>
          <View style={pdfStyles.twoColumnSection}>
            {/* Cumulative Summary */}
            <View style={pdfStyles.column50}>
              <Text style={pdfStyles.underlineBold}>Cumulative Summary</Text>
              <View style={pdfStyles.summaryRow}>
                <Text style={pdfStyles.summaryLabel}>Total Credits: </Text>
                <Text style={pdfStyles.summaryValue}>{data.cumulativeSummary.totalCredits}</Text>
              </View>
              <View style={pdfStyles.summaryRow}>
                <Text style={pdfStyles.summaryLabel}>Unweighted GPA: </Text>
                <Text style={pdfStyles.summaryValue}>{data.cumulativeSummary.cumulativeGPA}</Text>
              </View>
              {data.cumulativeSummary.weightedGPA && (
                <View style={pdfStyles.summaryRow}>
                  <Text style={pdfStyles.summaryLabel}>Weighted GPA: </Text>
                  <Text style={pdfStyles.summaryValue}>{data.cumulativeSummary.weightedGPA}</Text>
                </View>
              )}
            </View>

            {/* Test Scores */}
            {data.testScores && (
              <View style={pdfStyles.column50}>
                <Text style={pdfStyles.underlineBold}>Test Scores</Text>
                <Text style={pdfStyles.text}>{data.testScores}</Text>
              </View>
            )}
          </View>

          {/* Miscellaneous */}
          <View style={pdfStyles.section}>
            <Text style={pdfStyles.underlineBold}>Miscellaneous</Text>
            <Text style={pdfStyles.text}>{data.miscellaneous}</Text>
        </View>
      </View>

        <Text style={pdfStyles.italic}> I hereby certify and affirm that this is the official transcript and record of {data.name} in their high school academic studies.</Text>
        <View style={pdfStyles.signatureSection}>
              <Text>
                <Text style={pdfStyles.text}>Signature:</Text>
                <Text style={pdfStyles.signatureLine}> {data.parentGuardian}</Text>
              </Text>
              <Text style={pdfStyles.signatureDate}>Date: {new Date().toLocaleDateString('en-US')}</Text>
            </View>
          

        {/* YourEDU Stamp */}
        <View style={pdfStyles.copyrightContainer}>
          <Text style={pdfStyles.stamp}>Created by YourEDU ©</Text>
        </View>
      </Page>
    </Document>
);

const pdfStyles = StyleSheet.create({
  page: {
    padding: 20,
    fontSize: 10,
    fontFamily: 'Helvetica',
  },
  header: {
    textAlign: 'center',
    marginBottom: 0,
  },
  rowCentered: {
    flexDirection: 'row',
    justifyContent: 'flex-start',    // Aligns the columns to the left
    alignItems: 'flex-start',        // Ensures alignment starts at the top of the column
    width: '100%',                   // Ensures the row takes up the full width
    gap: 40,                         // Adds space between the columns
  },

  column: {
    flex: 1,
    textAlign: 'left',               // Keeps text left-aligned within the column
    maxWidth: '30%',                 // Controls the width of the columns
    marginHorizontal: 10,            // Adds a bit of horizontal spacing between columns
  },
  summaryRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 5,
  },
  summaryRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    marginBottom: 2,
  },
  summaryLabel: {
    fontFamily: 'Helvetica-Bold',
    width: '60%', // Adjust width to give enough space for the labels
    textAlign: 'left',
  },
  column50: {
    flex: 1,
    maxWidth: '50%',
    textAlign: 'left',
  },
  section: {
    marginBottom: 10,  // Add margin bottom for spacing between sections
    paddingBottom: 0,  // Optional: add padding within the section for internal spacing
  },
  label: {
    fontFamily: 'Helvetica-Bold',
    marginBottom: 2,
  },
  text: {
    fontFamily: 'Helvetica',
    marginTop: 0,
    marginBottom: 2,
  },
  italic: {
    fontFamily: 'Helvetica',
    marginTop: 0,
    marginBottom: 2,
    fontStyle: 'italic', // Add this new style for italics
  },
  underlineBold: {
    fontFamily: 'Helvetica-Bold',
    textDecoration: 'underline',
    marginBottom: 2,
  },
  table: {
    display: 'table',
    width: '100%',
    marginBottom: 5,
  },
  tableRow: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  tableCell: {
    flexGrow: 1,
    flexBasis: '8%',
    padding: 3,
    textAlign: 'center',
    fontFamily: 'Helvetica',
  },
  tableCellLeftAlign: {
    flexGrow: 1,
    flexBasis: '8%',
    padding: 3,
    textAlign: 'left',
    fontFamily: 'Helvetica',
  },
  courseTitleCellWide: {
    flexBasis: '60%',
    textAlign: 'left',
    padding: 3,
    fontFamily: 'Helvetica',
  },
  tableHeader: {
    fontFamily: 'Helvetica-Bold',
    backgroundColor: '#f0f0f0',
  },
  bold: {
    fontFamily: 'Helvetica-Bold',
  },
  gpaBox1: {
    borderWidth: 1,
    borderColor: '#000',
    padding: 5,
    marginTop: 5,
    marginBottom: 5,
    alignSelf: 'flex-start',
  },
  fullWidthBox: {
    borderWidth: 1,
    borderColor: '#000',
    padding: 3,
    marginTop: 3,
    marginBottom: 3,
    width: '100%',
  },
  twoColumnSection: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 7
  },
  signatureSection: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 5,
  },
  signatureLine: {
    fontFamily: 'Helvetica', // Use the registered custom font
    width: '60%',
    textDecorationLine: 'underline',
  },
  signatureDate: {
    fontFamily: 'Helvetica',
    fontStyle: 'italic',
    textAlign: 'right',
    width: '40%',
    marginBottom: 1,
  },
  copyrightContainer: {
    position: 'absolute',
    bottom: 10,
    right: 10,
  },
  stamp: {
    fontSize: 8,
    textAlign: 'right',
    fontFamily: 'Helvetica-Bold',
  },
  quadrants: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    marginBottom: 3,
  },
  quadrant: {
    width: '48%',
    marginBottom: 3,
  },
  studentName: {
    fontSize: 14,
    fontFamily: 'Helvetica-Bold',
    textAlign: 'center',
    marginBottom: 1, // Adjust for spacing
  },
  title: {
    fontSize: 16,
    fontFamily: 'Helvetica-Bold',
    textAlign: 'center',
    marginBottom: 8, // Adjust for spacing
  },
});



  const stylesForm = {
    container: {
      padding: '20px',
      maxWidth: '1200px',
      margin: '20px auto',
      border: '1px solid #ccc',
      borderRadius: '10px',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
      backgroundColor: '#fff',
    },
    headerContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: '20px',
      gap: '20px', // Add some spacing between elements
    },
    backButton: {
      padding: '10px 20px',
      backgroundColor: '#007BFF',
      color: '#FFFFFF',
      border: 'none',
      borderRadius: '4px',
      cursor: 'pointer',
    },
    header: {
      fontSize: '24px',
      fontWeight: 'bold',
    },
    buttonGroup: {
      display: 'flex',
      alignItems: 'center',
    },
    button: {
      padding: '10px 20px',
      backgroundColor: '#007BFF',
      color: '#FFFFFF',
      border: 'none',
      borderRadius: '4px',
      cursor: 'pointer',
      marginLeft: '10px',
    },
    saveStatus: {
      marginLeft: '10px',
      fontSize: '14px',
      color: '#28a745',
    },
    studentInfoGrid: {
      display: 'grid',
      gridTemplateColumns: 'repeat(4, 1fr)',
      gap: '10px',
    },
    column: {
      display: 'flex',
      flexDirection: 'column',
    },
    courseRow: {
      display: 'flex',
      gap: '10px',
      marginBottom: '10px',
    },
    input: {
      padding: '8px',
      border: '1px solid #ccc',
      borderRadius: '4px',
      marginBottom: '10px',
      width: '100%',
    },
    textarea: {
      padding: '8px',
      border: '1px solid #ccc',
      borderRadius: '4px',
      marginBottom: '10px',
      width: '100%',
      minHeight: '100px',
    },
    modal: {
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '80%',
        height: '80%',
        padding: '20px',
        borderRadius: '10px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
      },
    },
    noteText: {
      marginTop: '10px',
      fontSize: '14px',
      color: '#333',
      textAlign: 'left'
    },
    section: {
      marginTop: '20px',
    },
    cumulativeSection: {
      marginTop: '40px',
    },
    bottomSection: {
      marginTop: '40px',
    },
    buttonStack: {
      display: 'flex',
      flexDirection: 'column',
      gap: '5px',
    },
    smallButton: {
      padding: '5px',
      width: '100%',
      border: 'none',
      borderRadius: '4px',
      backgroundColor: '#007BFF',
      color: '#FFFFFF',
      cursor: 'pointer',
    },
    notesContainer: {
      padding: '20px',
      backgroundColor: '#fff',
      borderRadius: '8px',
      border: '1px solid #ccc',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
      maxWidth: '1200px',
      margin: '20px auto',
    },
    notesHeader: {
      fontSize: '18px',
      fontWeight: 'bold',
      marginBottom: '15px',
      color: '#00356b',
    },
    notesList: {
      listStyle: 'none',
      padding: 0,
      margin: 0,
    },
    noteItem: {
      display: 'flex',
      marginBottom: '12px',
      lineHeight: '1.5',
    },
    noteNumber: {
      minWidth: '30px', // Slightly wider for indentation
      marginRight: '10px',
      fontWeight: 'bold',
    },
    noteText: {
      flex: 1,
    },
  };

  return (
    <div>
      <div style={{
        ...stylesForm.container,
        width: '100%',
        marginTop: '0',
        marginBottom: '20px',
        textAlign: 'left'
      }}>
        <div style={stylesForm.notesList}>
          <h3 style={{
            fontSize: '18px',
            fontWeight: 'bold',
            marginBottom: '15px',
            color: '#00356b'
          }}>Notes from YourEDU:</h3>
          
          <div style={{
            display: 'flex',
            marginBottom: '12px',
            paddingLeft: '20px'
          }}>
            <span style={{ minWidth: '35px', fontSize: '16px' }}>1️⃣</span>
            <span>Because homeschoolers have flexible academic calendars, we have added three terms for grade entry – the Fall should typically be Term 1 for each academic year. If you completed semesters, simply leave the third term blank, and if you did quarters or took a summer course use Term 3.</span>
          </div>

          <div style={{
            display: 'flex',
            marginBottom: '12px',
            paddingLeft: '20px'
          }}>
            <span style={{ minWidth: '35px', fontSize: '16px' }}>2️⃣</span>
            <span>Feel free to use your own credit and grading standard as long as you can justify it in your grading rubric document. The standard we use is 1 credit for a full year course, 0.5 credits for a semester or summer course.</span>
          </div>

          <div style={{
            display: 'flex',
            marginBottom: '12px',
            paddingLeft: '20px'
          }}>
            <span style={{ minWidth: '35px', fontSize: '16px' }}>3️⃣</span>
            <span>Weighted GPA is completely optional, especially for homeschoolers. If you want to include, please calculate and include below. Typically only Honors, AP, and college-level courses can be weighted, and we recommend including a note on how you weighted GPA in the miscellaneous section.</span>
          </div>

          <div style={{
            display: 'flex',
            marginBottom: '12px',
            paddingLeft: '20px'
          }}>
            <span style={{ minWidth: '35px', fontSize: '16px' }}>4️⃣</span>
            <span>For Pass/Fail and Credit/No Credit courses please enter a "P" for Pass, the whole word "Pass", or "n/a" or "N/A" if grading is not applicable. These courses will automatically not be used in the GPA calculation.</span>
          </div>
        </div>
      </div>
      <div style={stylesForm.container}>
        <div style={stylesForm.headerContainer}>
          <button style={stylesForm.backButton} onClick={() => navigate('/admin-materials')}>Back</button>
          
          <div style={{ display: 'flex', alignItems: 'center', flex: 1, justifyContent: 'center' }}>
            <h2 style={stylesForm.header}>Transcript</h2>
          </div>
          
          <div style={{ display: 'flex', alignItems: 'center', minWidth: '150px' }}>
            {saving ? (
              <div style={{ color: '#28a745' }}>Saving data...</div>
            ) : (
              <div style={{ color: '#28a745' }}>{dataStatus}</div>
            )}
          </div>
          
          <div style={stylesForm.buttonGroup}>
            <button style={stylesForm.button} onClick={handlePreview}>Preview</button>
            <PDFDownloadLink
              document={<TranscriptDocument data={formData} />}
              fileName="transcript.pdf"
              style={{ textDecoration: 'none' }}
            >
              {({ loading }) => (
                <button type="button" style={stylesForm.button}>
                  {loading ? 'Download' : 'Download'}
                </button>
              )}
            </PDFDownloadLink>
          </div>
        </div>
        <form>
          <h3 style={{ marginTop: '40px' }}>Student Information</h3>
          <div style={stylesForm.studentInfoGrid}>
            <div style={stylesForm.column}>
              <div>
                <label>* Name:</label>
                <input type="text" name="name" value={formData.name} onChange={handleStudentInfoChange} required style={stylesForm.input} />
              </div>
              <div>
                <label>* Gender:</label>
                <input type="text" name="gender" value={formData.gender} onChange={handleStudentInfoChange} required style={stylesForm.input} />
              </div>
              <div>
                <label>* Date of Birth:</label>
                <input type="date" name="dob" value={formData.dob} onChange={handleStudentInfoChange} required style={stylesForm.input} />
              </div>
            </div>
            <div style={stylesForm.column}>
              <div>
                <label>* Address:</label>
                <input type="text" name="address" value={formData.address} onChange={handleStudentInfoChange} required style={stylesForm.input} />
              </div>
              <div>
                <label>* City:</label>
                <input type="text" name="city" value={formData.city} onChange={handleStudentInfoChange} required style={stylesForm.input} />
              </div>
              <div>
                <label>* State:</label>
                <input type="text" name="state" value={formData.state} onChange={handleStudentInfoChange} required style={stylesForm.input} />
              </div>
              <div>
                <label>* Zip:</label>
                <input type="text" name="zip" value={formData.zip} onChange={handleStudentInfoChange} required style={stylesForm.input} />
              </div>
            </div>
            <div style={stylesForm.column}>
              <div>
                <label>* Parent/Guardian Name:</label>
                <input type="text" name="parentGuardian" value={formData.parentGuardian} onChange={handleStudentInfoChange} required style={stylesForm.input} />
              </div>
              <div>
                <label>* Parent/Guardian Email:</label>
                <input type="email" name="parentEmail" value={formData.parentEmail} onChange={handleStudentInfoChange} required style={stylesForm.input} />
              </div>
              <div>
                <label>* Student Email:</label>
                <input type="email" name="studentEmail" value={formData.studentEmail} onChange={handleStudentInfoChange} required style={stylesForm.input} />
              </div>
              <div>
                <label>* Projected Graduation Date:</label>
                <input type="date" name="projectedGradDate" value={formData.projectedGradDate} onChange={handleStudentInfoChange} required style={stylesForm.input} />
              </div>
            </div>
            <div style={stylesForm.column}>
              <div>
                <label>* Name of School:</label>
                <input type="text" name="schoolName" value={formData.schoolName} onChange={handleStudentInfoChange} required style={stylesForm.input} />
              </div>
              <div>
                <label>* School Phone:</label>
                <input type="tel" name="schoolPhone" value={formData.schoolPhone} onChange={handleStudentInfoChange} required style={stylesForm.input} />
              </div>
              <div>
                <label>* School Address:</label>
                <input type="text" name="schoolAddress" value={formData.schoolAddress} onChange={handleStudentInfoChange} required style={stylesForm.input} />
              </div>
              <div>
                <label>* School City:</label>
                <input type="text" name="schoolCity" value={formData.schoolCity} onChange={handleStudentInfoChange} required style={stylesForm.input} />
              </div>
              <div>
                <label>* School State:</label>
                <input type="text" name="schoolState" value={formData.schoolState} onChange={handleStudentInfoChange} required style={stylesForm.input} />
              </div>
              <div>
                <label>* School Zip:</label>
                <input type="text" name="schoolZip" value={formData.schoolZip} onChange={handleStudentInfoChange} required style={stylesForm.input} />
              </div>
            </div>
          </div>

          {/* Pre-High School Section */}
          <h3 style={{ marginTop: '40px' }}>Pre-High School (Optional)</h3>
          <div>
            <label>Grade, Year:</label>
            <input
              type="text"
              name="preHighSchoolYear"
              placeholder="Nth Grade, YYYY/YYYY"
              value={formData.preHighSchoolYear}
              onChange={handleYearChange}
              style={stylesForm.input}
            />
          </div>
          {formData.preHighSchoolCourses.map((course, index) => (
            <div key={index} style={stylesForm.courseRow}>
              <input
                type="text"
                name="courseTitle"
                placeholder="Course Title"
                value={course.courseTitle}
                onChange={(e) => handleChange(e, 'preHighSchool', index)}
                style={stylesForm.input}
              />
              <input
                type="text"
                name="term1Grade"
                placeholder="Term 1 Grade"
                value={course.term1Grade}
                onChange={(e) => handleChange(e, 'preHighSchool', index)}
                style={stylesForm.input}
              />
              <input
                type="text"
                name="term2Grade"
                placeholder="Term 2 Grade"
                value={course.term2Grade}
                onChange={(e) => handleChange(e, 'preHighSchool', index)}
                style={stylesForm.input}
              />
              <input
                type="text"
                name="term3Grade"
                placeholder="Term 3 Grade"
                value={course.term3Grade}
                onChange={(e) => handleChange(e, 'preHighSchool', index)}
                style={stylesForm.input}
              />
              <input
                type="text"
                name="credits"
                placeholder="Credits"
                value={course.credits}
                onChange={(e) => handleChange(e, 'preHighSchool', index)}
                style={stylesForm.input}
              />
              <button type="button" onClick={() => handleRemoveCourse('preHighSchool', index)} style={stylesForm.button}>Remove</button>
            </div>
          ))}
          <button type="button" onClick={() => handleAddCourse('preHighSchool')} style={stylesForm.button}>Add another course</button>

          <h3 style={{ marginTop: '40px' }}>Courses</h3>
          {['freshman', 'sophomore', 'junior', 'senior'].map((grade, idx) => (
            <div key={grade} style={{ marginTop: idx !== 0 ? '30px' : '0' }}>
              <h4>{grade.charAt(0).toUpperCase() + grade.slice(1)}</h4>
              <div>
                <label>Year:</label>
                <input
                  type="text"
                  name={`${grade}Year`}
                  placeholder="YYYY/YYYY"
                  value={formData[`${grade}Year`]}
                  onChange={handleYearChange}
                  required
                  style={stylesForm.input}
                />
              </div>
              {formData[`${grade}Courses`].map((course, index) => (
                <div key={index} style={stylesForm.courseRow}>
                  <input
                    type="text"
                    name="courseTitle"
                    placeholder="Course Title"
                    value={course.courseTitle}
                    onChange={(e) => handleChange(e, grade, index)}
                    style={stylesForm.input}
                  />
                  <input
                    type="text"
                    name="term1Grade"
                    placeholder="Term 1 Grade"
                    value={course.term1Grade}
                    onChange={(e) => handleChange(e, grade, index)}
                    style={stylesForm.input}
                  />
                  <input
                    type="text"
                    name="term2Grade"
                    placeholder="Term 2 Grade"
                    value={course.term2Grade}
                    onChange={(e) => handleChange(e, grade, index)}
                    style={stylesForm.input}
                  />
                  <input
                    type="text"
                    name="term3Grade"
                    placeholder="Term 3 Grade"
                    value={course.term3Grade}
                    onChange={(e) => handleChange(e, grade, index)}
                    style={stylesForm.input}
                  />
                  <input
                    type="text"
                    name="credits"
                    placeholder="Credits"
                    value={course.credits}
                    onChange={(e) => handleChange(e, grade, index)}
                    style={stylesForm.input}
                  />
                  <div style={stylesForm.buttonStack}>
                    <button type="button" onClick={() => moveCourseUp(grade, index)} style={stylesForm.smallButton}>
                      ▲
                    </button>
                    <button type="button" onClick={() => moveCourseDown(grade, index)} style={stylesForm.smallButton}>
                      ▼
                    </button>
                  </div>
                  <button type="button" onClick={() => handleRemoveCourse(grade, index)} style={stylesForm.button}>Remove</button>
                </div>
              ))}
              <button type="button" onClick={() => handleAddCourse(grade)} style={stylesForm.button}>Add another course</button>
            </div>
          ))}

          <div style={stylesForm.cumulativeSection}>
            <h3>Cumulative Summary</h3>
            <div>
              <label>Total Credits:</label>
              <input
                type="number"
                name="totalCredits"
                value={formData.cumulativeSummary.totalCredits}
                onChange={(e) => setFormData({ ...formData, cumulativeSummary: { ...formData.cumulativeSummary, totalCredits: e.target.value } })}
                style={stylesForm.input}
              />
            </div>

            <div>
              <label>Cumulative GPA:</label>
              <input
                type="number"
                name="cumulativeGPA"
                value={formData.cumulativeSummary.cumulativeGPA}
                onChange={(e) => setFormData({ ...formData, cumulativeSummary: { ...formData.cumulativeSummary, cumulativeGPA: e.target.value } })}
                style={stylesForm.input}
              />
            </div>
            
            <button 
              type="button" 
              onClick={calculateCumulativeSummary} 
              style={{...stylesForm.button, marginTop: '10px', marginBottom: '30px'}} // Added spacing
            >
              Calculate
            </button>

            <div>
              <label>Weighted GPA (Optional, Self-Input):</label>
              <input
                type="number"
                name="weightedGPA"
                value={formData.cumulativeSummary.weightedGPA}
                onChange={(e) => setFormData({ ...formData, cumulativeSummary: { ...formData.cumulativeSummary, weightedGPA: e.target.value } })}
                style={stylesForm.input}
              />
            </div>
          </div>

          <div style={stylesForm.bottomSection}>
          <h3 style={{ marginTop: '40px' }}>Test Scores (SAT/ACT and APs)</h3>
          <textarea
            name="testScores"
            placeholder="Enter your test scores..."
            value={formData.testScores}
            onChange={handleTestScoresChange}
            style={{
              ...stylesForm.input,
              fontFamily: "'Inter', sans-serif", // Match font family with other fields
              minHeight: '100px'
            }}
          />

            <h3>Miscellaneous</h3>
            <p>We encourage families to briefly explain their weighting process and note any grades received from external institutions or businesses that courses were taken through.</p>
            <textarea
              name="miscellaneous"
              placeholder="Use this section for notes pertaining to the above listed courses (community college courses, previous schools attended, etc.)"
              value={formData.miscellaneous}
              onChange={handleMiscellaneousChange}
              style={{
                ...stylesForm.input,
                fontFamily: "'Inter', sans-serif", // Match font family with other fields
                minHeight: '100px'
              }}
            />
          </div>
          <div style={stylesForm.bottomSection}>
            <h3>Signature</h3>
            <label>Date shown on authorized signature box:</label>
            <input
              type="date"
              name="signatureDate"
              value={formData.signatureDate}
              onChange={handleSignatureDateChange}
              style={stylesForm.input}
            />
          </div>
        </form>
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
          <button type="button" onClick={handlePreview} style={stylesForm.button}>Preview</button>
          <PDFDownloadLink
            document={<TranscriptDocument data={formData} />}
            fileName="transcript.pdf"
            style={{ textDecoration: 'none' }}
          >
            {({ loading }) => (
              <button type="button" style={stylesForm.button}>
                {loading ? 'Generating PDF...' : 'Download'}
              </button>
            )}
          </PDFDownloadLink>
        </div>
        <Modal
          isOpen={isModalOpen}
          onRequestClose={closeModal}
          style={stylesForm.modal}
          contentLabel="Transcript Preview"
        >
          <button onClick={closeModal} style={{ ...stylesForm.button, marginBottom: '10px' }}>Close</button>
          <PDFViewer width="100%" height="100%">
            <TranscriptDocument data={formData} />
          </PDFViewer>
        </Modal>
      </div>
    </div>
  );
};

export default Transcript;

