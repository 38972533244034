import React, { useState } from 'react';
import { useAuth } from '../utils/AuthContext';
import mathPathways from '../assets/math.jpg';
import PageHeader from '../components/PageHeader';

// The main Course Planning page
const CoursePlanning = () => {
  const { user } = useAuth();
  const [activeSubject, setActiveSubject] = useState(null);
  const [courseData, setCourseData] = useState({
    math: { '9th': '', '10th': '', '11th': '', '12th': '' },
    science: { '9th': '', '10th': '', '11th': '', '12th': '' },
    english: { '9th': '', '10th': '', '11th': '', '12th': '' },
    history: { '9th': '', '10th': '', '11th': '', '12th': '' },
    language: { '9th': '', '10th': '', '11th': '', '12th': '' },
    fineArts: { '9th': '', '10th': '', '11th': '', '12th': '' },
    pe: { '9th': '', '10th': '', '11th': '', '12th': '' },
  });

  const grades = ['9th', '10th', '11th', '12th'];
  const subjects = [
    { name: 'Math', key: 'math' },
    { name: 'Science', key: 'science' },
    { name: 'English', key: 'english' },
    { name: 'History', key: 'history' },
    { name: 'Language', key: 'language' },
    { name: 'Fine Arts', key: 'fineArts' },
    { name: 'PE', key: 'pe' }
  ];

  // Handle course input change
  const handleCourseChange = (subjectKey, grade, value) => {
    setCourseData((prevData) => ({
      ...prevData,
      [subjectKey]: {
        ...prevData[subjectKey],
        [grade]: value,
      },
    }));
  };

  return (
    <div style={styles.container}>
      <PageHeader title="Course Planning" />
      
      {/* Main Grid */}
      <div style={styles.courseCard}>
        <div style={styles.gridContainer}>
          <div style={styles.emptyBox}></div>
          {grades.map((grade) => (
            <div key={grade} style={styles.gradeHeader}>{grade} Grade</div>
          ))}

          {subjects.map((subject) => (
            <React.Fragment key={subject.key}>
              <button 
                style={{
                  ...styles.subjectButton,
                  backgroundColor: activeSubject === subject.key ? '#0056b3' : '#007BFF'
                }} 
                onClick={() => setActiveSubject(subject.key)}
              >
                {subject.name}
              </button>

              {grades.map((grade) => (
                <input
                  key={grade}
                  type="text"
                  value={courseData[subject.key][grade]}
                  onChange={(e) => handleCourseChange(subject.key, grade, e.target.value)}
                  style={styles.inputBox}
                  placeholder={`Enter ${subject.name} course`}
                />
              ))}
            </React.Fragment>
          ))}
        </div>
      </div>

      {/* Subject-specific sections */}
      {activeSubject && (
        <div style={styles.courseCard}>
          {activeSubject === 'math' && <MathPlanning />}
          {activeSubject === 'science' && <SciencePlanning />}
          {activeSubject === 'english' && <EnglishPlanning />}
          {activeSubject === 'history' && <HistoryPlanning />}
          {activeSubject === 'pe' && <PEPlanning />}
          {activeSubject === 'language' && <LanguagePlanning />}
        </div>
      )}
    </div>
  );
};


// The Math planning page
const MathPlanning = () => {
  const tracks = [
    ['Algebra 1', 'Geometry', 'Algebra 2', 'Precalculus'],
    ['Geometry H', 'Algebra 2/Trig H', 'Analysis H', 'AP Calculus BC'],
    ['Geometry', 'Algebra 2/Trig A', 'Intro to Analysis and Calculus', 'AP Calculus AB']
  ];

  const electives = ['AP Statistics', 'Applied Math H', 'Computer Science'];

  const sierraCourses = [
    { id: 1, name: 'MATH 10: Elementary Algebra' },
    { id: 2, name: 'MATH 20: Geometry' },
    { id: 3, name: 'MATH 30: College Algebra' },
    { id: 4, name: 'MATH 40: Trigonometry' },
    { id: 5, name: 'MATH 50: Precalculus' },
    { id: 6, name: 'MATH 60: Calculus I' },
    { id: 7, name: 'MATH 70: Calculus II' }
  ];

  const [selectedCourse, setSelectedCourse] = useState(null);

  return (
    <div style={styles.container}>
    <h1 style={styles.title}>Math Planning</h1>
      <img
        src={mathPathways}
        alt="Math Pathways"
        style={styles.image}
      />
      <h3 style={styles.imageSubtitle}>
        Image credit to Gunn High School
      </h3>
      <h2 style={styles.trackHeader}>Typical California Public HS Track Options</h2>
      <div style={styles.trackGridContainer}>
        {tracks.map((track, index) => (
          <div key={index} style={styles.trackRow}>
            <h3 style={styles.trackTitle}>Track {index + 1}</h3>
            <div style={styles.courseGrid}>
              {track.map((course, idx) => (
                <div key={idx} style={styles.courseBox}>
                  {course}
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>

      <h3 style={styles.trackTitle}>Electives</h3>
      <div style={styles.courseGrid}>
        {electives.map((course, idx) => (
          <div key={idx} style={styles.courseBox}>
            {course}
          </div>
        ))}
      </div>


      {/* Sierra College Courses */}
      <div style={styles.communityCoursesSection}>
        <h3 style={styles.courseTitle}>Sierra College Courses</h3>
        <div style={styles.courseListContainer}>
          <div style={styles.courseListLeft}>
            {sierraCourses.map((course) => (
              <div
                key={course.id}
                style={styles.courseBox}
                onClick={() => setSelectedCourse(course)}
              >
                {course.name}
              </div>
            ))}
          </div>
          {selectedCourse && (
            <div style={styles.courseDetails}>
              <h4>{selectedCourse.name}</h4>
              <p>
                Course description: This is an AI-generated description of {selectedCourse.name}. It includes an overview of the course content, prerequisites, and expectations.
              </p>
              <button style={styles.planCourseButton}>Plan Course</button>
              <button style={styles.applyButton}>Apply to Course</button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

// Science Planning Section
const SciencePlanning = () => {
  const tracks = [
    ['Biology', 'Chemistry', 'Physics', 'AP Biology'],
    ['Biology H', 'Chemistry H', 'AP Chemistry', 'AP Physics C'],
    ['Integrated Science', 'Earth Science', 'Environmental Science', 'Anatomy']
  ];

  const sierraCourses = [
    { id: 1, name: 'SCI 10: Introduction to Biology' },
    { id: 2, name: 'SCI 20: General Chemistry' },
    { id: 3, name: 'SCI 30: Physics I' },
    { id: 4, name: 'SCI 40: Physics II' },
    { id: 5, name: 'SCI 50: Environmental Science' }
  ];

  const [selectedCourse, setSelectedCourse] = useState(null);

  return (
    <div style={styles.container}>
      <h2 style={styles.trackHeader}>Science Course Tracks</h2>
      <div style={styles.trackContainer}>
        {tracks.map((track, index) => (
          <div key={index} style={styles.trackSection}>
            <h3 style={styles.trackTitle}>Track {index + 1}</h3>
            <div style={styles.courseList}>
              {track.map((course, idx) => (
                <div key={idx} style={styles.courseBox}>
                  {course}
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>

      {/* Sierra College Science Courses */}
      <div style={styles.communityCoursesSection}>
        <h3 style={styles.courseTitle}>Sierra College Science Courses</h3>
        <div style={styles.courseListLeft}>
          {sierraCourses.map((course) => (
            <div
              key={course.id}
              style={styles.courseBox}
              onClick={() => setSelectedCourse(course)}
            >
              {course.name}
            </div>
          ))}
        </div>
        {selectedCourse && (
          <div style={styles.courseDetails}>
            <h4>{selectedCourse.name}</h4>
            <p>Course description: This is an AI-generated description of {selectedCourse.name}.</p>
            <button style={styles.planCourseButton}>Plan Course</button>
            <button style={styles.applyButton}>Apply to Course</button>
          </div>
        )}
      </div>
    </div>
  );
};

// English Planning Section
const EnglishPlanning = () => {
  const tracks = [
    ['English 9', 'English 10', 'English 11', 'English 12'],
    ['Honors English 9', 'Honors English 10', 'AP Language', 'AP Literature'],
    ['Creative Writing', 'Journalism', 'Speech & Debate', 'AP Seminar']
  ];

  const sierraCourses = [
    { id: 1, name: 'ENG 10: English Composition' },
    { id: 2, name: 'ENG 20: Advanced Writing' },
    { id: 3, name: 'ENG 30: Creative Writing' }
  ];

  const [selectedCourse, setSelectedCourse] = useState(null);

  return (
    <div style={styles.container}>
      <h2 style={styles.trackHeader}>English Course Tracks</h2>
      <div style={styles.trackContainer}>
        {tracks.map((track, index) => (
          <div key={index} style={styles.trackSection}>
            <h3 style={styles.trackTitle}>Track {index + 1}</h3>
            <div style={styles.courseList}>
              {track.map((course, idx) => (
                <div key={idx} style={styles.courseBox}>
                  {course}
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>

      {/* Sierra College English Courses */}
      <div style={styles.communityCoursesSection}>
        <h3 style={styles.courseTitle}>Sierra College English Courses</h3>
        <div style={styles.courseListLeft}>
          {sierraCourses.map((course) => (
            <div
              key={course.id}
              style={styles.courseBox}
              onClick={() => setSelectedCourse(course)}
            >
              {course.name}
            </div>
          ))}
        </div>
        {selectedCourse && (
          <div style={styles.courseDetails}>
            <h4>{selectedCourse.name}</h4>
            <p>Course description: This is an AI-generated description of {selectedCourse.name}.</p>
            <button style={styles.planCourseButton}>Plan Course</button>
            <button style={styles.applyButton}>Apply to Course</button>
          </div>
        )}
      </div>
    </div>
  );
};

// History Planning Section
const HistoryPlanning = () => {
  const tracks = [
    ['World History', 'US History', 'Government', 'Economics'],
    ['Honors World History', 'AP US History', 'AP Government', 'AP Economics'],
    ['Modern History', 'Civics', 'Global Politics', 'AP European History']
  ];

  const sierraCourses = [
    { id: 1, name: 'HIS 10: World History' },
    { id: 2, name: 'HIS 20: American History' },
    { id: 3, name: 'HIS 30: Political Science' }
  ];

  const [selectedCourse, setSelectedCourse] = useState(null);

  return (
    <div style={styles.container}>
      <h2 style={styles.trackHeader}>History Course Tracks</h2>
      <div style={styles.trackContainer}>
        {tracks.map((track, index) => (
          <div key={index} style={styles.trackSection}>
            <h3 style={styles.trackTitle}>Track {index + 1}</h3>
            <div style={styles.courseList}>
              {track.map((course, idx) => (
                <div key={idx} style={styles.courseBox}>
                  {course}
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>

      {/* Sierra College History Courses */}
      <div style={styles.communityCoursesSection}>
        <h3 style={styles.courseTitle}>Sierra College History Courses</h3>
        <div style={styles.courseListLeft}>
          {sierraCourses.map((course) => (
            <div
              key={course.id}
              style={styles.courseBox}
              onClick={() => setSelectedCourse(course)}
            >
              {course.name}
            </div>
          ))}
        </div>
        {selectedCourse && (
          <div style={styles.courseDetails}>
            <h4>{selectedCourse.name}</h4>
            <p>Course description: This is an AI-generated description of {selectedCourse.name}.</p>
            <button style={styles.planCourseButton}>Plan Course</button>
            <button style={styles.applyButton}>Apply to Course</button>
          </div>
        )}
      </div>
    </div>
  );
};

// PE Planning Section
const PEPlanning = () => {
  const tracks = [
    ['PE 9', 'PE 10', 'PE 11', 'PE 12'],
    ['Advanced PE', 'Weight Training', 'Conditioning', 'Yoga'],
    ['Sports Training', 'Health & Fitness', 'Aerobics', 'Dance']
  ];

  const sierraCourses = [
    { id: 1, name: 'PE 10: General PE' },
    { id: 2, name: 'PE 20: Advanced PE' },
    { id: 3, name: 'PE 30: Yoga' },
    { id: 4, name: 'PE 40: Aerobics' }
  ];

  const [selectedCourse, setSelectedCourse] = useState(null);

  return (
    <div style={styles.container}>
      <h2 style={styles.trackHeader}>PE Course Tracks</h2>
      <div style={styles.trackContainer}>
        {tracks.map((track, index) => (
          <div key={index} style={styles.trackSection}>
            <h3 style={styles.trackTitle}>Track {index + 1}</h3>
            <div style={styles.courseList}>
              {track.map((course, idx) => (
                <div key={idx} style={styles.courseBox}>
                  {course}
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>

      {/* Sierra College PE Courses */}
      <div style={styles.communityCoursesSection}>
        <h3 style={styles.courseTitle}>Sierra College PE Courses</h3>
        <div style={styles.courseListLeft}>
          {sierraCourses.map((course) => (
            <div
              key={course.id}
              style={styles.courseBox}
              onClick={() => setSelectedCourse(course)}
            >
              {course.name}
            </div>
          ))}
        </div>
        {selectedCourse && (
          <div style={styles.courseDetails}>
            <h4>{selectedCourse.name}</h4>
            <p>Course description: This is an AI-generated description of {selectedCourse.name}.</p>
            <button style={styles.planCourseButton}>Plan Course</button>
            <button style={styles.applyButton}>Apply to Course</button>
          </div>
        )}
      </div>
    </div>
  );
};

// Language Planning Section
const LanguagePlanning = () => {
  const tracks = [
    ['Spanish I', 'Spanish II', 'Spanish III', 'AP Spanish'],
    ['French I', 'French II', 'French III', 'AP French'],
    ['German I', 'German II', 'German III', 'AP German'],
    ['Chinese I', 'Chinese II', 'Chinese III', 'AP Chinese'],
    ['Italian I', 'Italian II', 'Italian III', 'AP Italian']
  ];

  const sierraCourses = [
    { id: 1, name: 'SPA 10: Beginning Spanish' },
    { id: 2, name: 'FRE 10: Beginning French' },
    { id: 3, name: 'GER 10: Beginning German' },
    { id: 4, name: 'CHI 10: Beginning Chinese' },
    { id: 5, name: 'ITA 10: Beginning Italian' }
  ];

  const [selectedCourse, setSelectedCourse] = useState(null);

  return (
    <div style={styles.container}>
      <h2 style={styles.trackHeader}>Language Course Tracks</h2>
      <div style={styles.trackContainer}>
        {tracks.map((track, index) => (
          <div key={index} style={styles.trackSection}>
            <h3 style={styles.trackTitle}>Track {index + 1}</h3>
            <div style={styles.courseList}>
              {track.map((course, idx) => (
                <div key={idx} style={styles.courseBox}>
                  {course}
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>

      {/* Sierra College Language Courses */}
      <div style={styles.communityCoursesSection}>
        <h3 style={styles.courseTitle}>Sierra College Language Courses</h3>
        <div style={styles.courseListLeft}>
          {sierraCourses.map((course) => (
            <div
              key={course.id}
              style={styles.courseBox}
              onClick={() => setSelectedCourse(course)}
            >
              {course.name}
            </div>
          ))}
        </div>
        {selectedCourse && (
          <div style={styles.courseDetails}>
            <h4>{selectedCourse.name}</h4>
            <p>Course description: This is an AI-generated description of {selectedCourse.name}.</p>
            <button style={styles.planCourseButton}>Plan Course</button>
            <button style={styles.applyButton}>Apply to Course</button>
          </div>
        )}
      </div>
    </div>
  );
};

// Styles
const styles = {
  container: {
    padding: '2rem',
    maxWidth: '1200px',
    margin: '0 auto',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '2rem',
  },
  title: {
    fontSize: '2rem',
    color: '#333',
  },
  createButton: {
    padding: '0.75rem 1.5rem',
    backgroundColor: '#4CAF50',
    color: 'white',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    fontSize: '1rem',
  },
  courseCard: {
    backgroundColor: 'white',
    borderRadius: '8px',
    padding: '1.5rem',
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
    marginBottom: '2rem',
  },
  gridContainer: {
    display: 'grid',
    gridTemplateColumns: '150px repeat(4, 1fr)',
    gap: '1rem',
    alignItems: 'center',
  },
  emptyBox: {
    // This is an empty box to align the grid header with the grade labels
  },
  gradeHeader: {
    fontWeight: 'bold',
    textAlign: 'center',
    padding: '0.75rem',
    backgroundColor: '#f8f9fa',
    color: '#333',
    borderRadius: '4px',
    border: '1px solid #ddd',
  },
  subjectButton: {
    backgroundColor: '#007BFF',
    color: 'white',
    padding: '0.75rem',
    borderRadius: '4px',
    fontSize: '0.9rem',
    cursor: 'pointer',
    border: 'none',
    width: '100%',
    textAlign: 'left',
    transition: 'background-color 0.2s ease',
  },
  inputBox: {
    padding: '0.75rem',
    borderRadius: '4px',
    border: '1px solid #ddd',
    fontSize: '0.9rem',
    width: '100%',
    boxSizing: 'border-box',
  },
  trackHeader: {
    textAlign: 'center',
    fontSize: '28px',
    marginTop: '40px',
    color: '#00356b'
  },
  trackContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))',
    gap: '1.5rem',
    marginTop: '2rem',
  },
  trackSection: {
    backgroundColor: '#ffffff',
    padding: '1.5rem',
    borderRadius: '8px',
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
  },
  trackTitle: {
    fontSize: '20px',
    color: '#333',
    marginBottom: '10px'
  },
  courseList: {
    display: 'grid',
    gap: '1rem',
    marginTop: '1rem',
  },
  courseBox: {
    backgroundColor: '#f8f9fa',
    padding: '1rem',
    borderRadius: '8px',
    border: '1px solid #dee2e6',
    cursor: 'pointer',
    transition: 'all 0.2s ease',
    '&:hover': {
      transform: 'translateY(-2px)',
      boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
    }
  },
  communityCoursesSection: {
    marginTop: '3rem',
    padding: '2rem',
    backgroundColor: '#f8f9fa',
    borderRadius: '8px',
  },
  courseTitle: {
    fontSize: '1.25rem',
    fontWeight: 'bold',
    color: '#333',
    marginBottom: '1rem',
  },
  courseListContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  courseListLeft: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '10px',
    width: '50%'
  },
  courseDetails: {
    backgroundColor: '#ffffff',
    padding: '1.5rem',
    borderRadius: '8px',
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
    marginTop: '1rem',
  },
  button: {
    backgroundColor: '#007bff',
    color: '#ffffff',
    padding: '0.75rem 1.5rem',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    fontSize: '1rem',
    transition: 'background-color 0.2s ease',
    '&:hover': {
      backgroundColor: '#0056b3',
    }
  },
  planCourseButton: {
    backgroundColor: '#28a745',
    color: '#ffffff',
    padding: '0.75rem 1.5rem',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    marginRight: '1rem',
  },
  applyButton: {
    backgroundColor: '#007bff',
    color: '#ffffff',
    padding: '0.75rem 1.5rem',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
  },
  image: {
    width: '100%',
    borderRadius: '10px',
  },
  imageSubtitle: {
    textAlign: 'center',
    fontStyle: 'italic',
    color: '#555',
    marginTop: '10px'
  }
};

export default CoursePlanning;