import React from 'react';
import PageHeader from '../components/PageHeader';

const Extracurriculars = () => {
  return (
    <div style={styles.container}>
      <PageHeader title="Extracurricular Activities" />
      <p style={styles.comingSoon}>Coming Soon!</p>
      <p style={styles.message}>
        We are putting together the largest homeschool extracurricular repository to help students find and apply for Summer programs, internships, jobs, community/volunteer work, tutoring, etc.
      </p>
    </div>
  );
};

const styles = {
  container: {
    padding: '40px',
    textAlign: 'center',
    backgroundColor: '#fff',
    borderRadius: '10px',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
  },
  title: {
    fontSize: '36px',
    fontWeight: 'bold',
    color: '#00356b',
    marginBottom: '20px',
  },
  comingSoon: {
    fontSize: '28px',
    fontWeight: 'bold',
    color: '#007BFF',
    marginBottom: '10px',
  },
  message: {
    fontSize: '18px',
    color: '#555',
  },
};

export default Extracurriculars;
