import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { PDFDownloadLink, Document, Page, Text, View, PDFViewer, Image, StyleSheet } from '@react-pdf/renderer';
import Modal from 'react-modal';
import axios from 'axios';
import OpenAI from 'openai';
import { useAuth } from '../utils/AuthContext';
import logo from '../assets/logo.png';

const openai = new OpenAI({
  apiKey: process.env.REACT_APP_OPENAI_API_KEY,
  dangerouslyAllowBrowser: true,
});

// Define the pdfStyles object
const pdfStyles = StyleSheet.create({
  page: {
    padding: 30,
    fontSize: 12,
    fontFamily: 'Helvetica',
  },
  studentName: {
    fontSize: 14,
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: 5,
  },
  title: {
    fontSize: 16,
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: 20,
  },
  text: {
    marginTop: 5,
    textAlign: 'justify',
  },
  copyrightContainer: {
    position: 'absolute',
    bottom: 10,
    right: 10,
  },
  stamp: {
    fontSize: 8,
    textAlign: 'right',
  },
});

const SchoolPhilosophyPDF = ({ aiPhilosophy, formData }) => {
  // Fetch student name from localStorage
  const storedName = localStorage.getItem('studentName');
  const studentName = storedName && storedName.length > 0 ? storedName : 'Student Name';

  return (
    <Document>
      <Page style={pdfStyles.page}>
        <Text style={pdfStyles.studentName}>{studentName}</Text>
        <Text style={pdfStyles.title}>School Profile Report</Text>
        <Text style={pdfStyles.text}>{aiPhilosophy}</Text>
        <View style={pdfStyles.copyrightContainer} fixed>
          <Text style={pdfStyles.stamp}>Created by YourEDU ©</Text>
        </View>
      </Page>
    </Document>
  );
};

const SchoolPhilosophy = () => {
  const { user } = useAuth();
  console.log('User object:', user); // Log the user object

  // Initialize formData from localStorage first
  const [formData, setFormData] = useState(() => {
    const cachedData = localStorage.getItem(`schoolPhilosophy_${user?.id}`);
    return cachedData ? JSON.parse(cachedData) : {
      prefix: '',
      firstName: '',
      middleInitial: '',
      lastName: '',
      title: '',
      phoneNumber: '',
      fax: '',
      emailAddress: '',
      profileURL: '',
      graduatingClassSize: '',
      blockSchedule: '',
      graduationDate: '',
      outsideUS: '',
      volunteerService: '',
      schoolAddress: '',
      oneSentencePhilosophy: '',
      whyHomeschool: '',
      typesOfLearning: '',
      courseStructure: '',
      successMeasurement: '',
      extracurricularOpportunities: '',
      aiPhilosophy: '',
    };
  });

  const [sections, setSections] = useState({
    parentDetails: true,
    schoolDetails: true,
    schoolProfile: true,
  });

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [dataStatus, setDataStatus] = useState('Data saved');
  const navigate = useNavigate();
  const saveTimeoutRef = useRef(null);

  // Modify fetchSchoolPhilosophy to update localStorage
  const fetchSchoolPhilosophy = useCallback(async () => {
    if (!user || !user.id) {
      console.error('No user or user ID found, aborting fetchSchoolPhilosophy');
      return;
    }

    try {
      setLoading(true);
      console.log(`Fetching School Philosophy for user ID: ${user.id}`);
      const response = await axios.get('/api/school-philosophy', {
        params: { userId: user.id },
      });

      if (response.status === 200 && Object.keys(response.data).length > 0) {
        console.log('Fetched School Philosophy data:', response.data);
        setFormData(response.data);
        // Store in localStorage after successful fetch
        localStorage.setItem(`schoolPhilosophy_${user.id}`, JSON.stringify(response.data));
      } else {
        console.log('No existing School Philosophy data found.');
      }
    } catch (error) {
      console.error('Failed to fetch School Philosophy:', error.message || error);
    } finally {
      setLoading(false);
    }
  }, [user]);

  useEffect(() => {
    if (user && user.id) {
      const cachedData = localStorage.getItem(`schoolPhilosophy_${user.id}`);
      if (!cachedData) {
        // Only fetch from backend if no cached data exists
        fetchSchoolPhilosophy();
      }
    }
  }, [fetchSchoolPhilosophy, user]);

  // Modify saveSchoolPhilosophy to update localStorage
  const saveSchoolPhilosophy = useCallback(async (updatedFormData) => {
    if (!user || !user.id) {
      console.error('No user or user ID found, aborting saveSchoolPhilosophy');
      return;
    }
    try {
      setSaving(true);
      setDataStatus('Saving data...'); // Update status when saving starts
      console.log('Sending the following data to save:', updatedFormData);
      const response = await axios.post('/api/school-philosophy', {
        userId: user.id,
        formData: updatedFormData,
      });
      if (response.status === 200) {
        console.log('School Philosophy saved successfully:', response.data);
        // Update localStorage after successful save
        localStorage.setItem(`schoolPhilosophy_${user.id}`, JSON.stringify(updatedFormData));
      } else {
        console.error('Failed to save School Philosophy:', response);
      }
    } catch (error) {
      console.error('Error saving School Philosophy:', error);
    } finally {
      setSaving(false);
    }
  }, [user]);

  const debouncedSave = useCallback((updatedFormData) => {
    if (saveTimeoutRef.current) clearTimeout(saveTimeoutRef.current);
    saveTimeoutRef.current = setTimeout(() => {
      saveSchoolPhilosophy(updatedFormData);
    }, 1000);
  }, [saveSchoolPhilosophy]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const updatedFormData = { ...formData, [name]: value };
    setFormData(updatedFormData);

    if (user && user.id) {
      debouncedSave(updatedFormData);
    } else {
      console.error('User or user ID is not available, cannot save data');
    }
  };

  const generateAIDescription = async () => {
      //const context = await fetchContext();
      const prompt = `Please create a school profile report that this homeschooled student needs to apply to college. It absolutely NEEDS to be multiple paragraphs and full length. Ideally between 0.75 of a full page and 2 full pages. and it should be similar to the examples that this fine-tuned model was trained on, thanks. Here is the parent user data as context:
        - Prefix: ${formData.prefix}
        - First Name: ${formData.firstName}
        - Middle Initial: ${formData.middleInitial}
        - Last Name: ${formData.lastName}
        - Title: ${formData.title}
        - Phone Number: ${formData.phoneNumber}
        - Fax: ${formData.fax}
        - Email Address: ${formData.emailAddress}
        - Profile URL: ${formData.profileURL}
        - Graduating Class Size: ${formData.graduatingClassSize}
        - Block Schedule: ${formData.blockSchedule}
        - Graduation Date: ${formData.graduationDate}
        - Outside US: ${formData.outsideUS}
        - Volunteer Service: ${formData.volunteerService}
        - One Sentence Philosophy: ${formData.oneSentencePhilosophy}
        - Why Homeschool: ${formData.whyHomeschool}
        - Types of Learning: ${formData.typesOfLearning}
        - Course Structure: ${formData.courseStructure}
        - Success Measurement: ${formData.successMeasurement}
        - Extracurricular Opportunities: ${formData.extracurricularOpportunities}`;

      try {
        console.log('Generating AI School Philosophy...');
        const response = await openai.chat.completions.create({
          model: 'ft:gpt-4o-mini-2024-07-18:personal:school-profile-report:AIhlJ0Cv',
          messages: [{ role: 'system', content: prompt }],
          temperature: 0.1,
          max_tokens: 1024,
          top_p: 1,
          frequency_penalty: 0,
          presence_penalty: 0,
        });

        const aiGeneratedText = response.choices[0].message.content.trim();
        const updatedFormData = { ...formData, aiPhilosophy: aiGeneratedText };
        setFormData(updatedFormData);
        debouncedSave(updatedFormData);

        console.log('AI-generated school profile report:', aiGeneratedText);
      } catch (error) {
        console.error('Error generating AI description:', error);
      }
    };

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const styles = {
    container: {
      padding: '20px',
      maxWidth: '1200px',
      margin: '20px auto',
      border: '1px solid #ccc',
      borderRadius: '10px',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
      backgroundColor: '#fff',
    },
    headerContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: '20px',
    },
    backButton: {
      padding: '10px 20px',
      backgroundColor: '#007BFF',
      color: '#FFFFFF',
      border: 'none',
      borderRadius: '4px',
      cursor: 'pointer',
    },
    header: {
      fontSize: '24px',
      fontWeight: 'bold',
    },
    buttonGroup: {
      display: 'flex',
      alignItems: 'center',
    },
    button: {
      padding: '10px 20px',
      backgroundColor: '#007BFF',
      color: '#FFFFFF',
      border: 'none',
      borderRadius: '4px',
      cursor: 'pointer',
      marginLeft: '10px',
    },
    sectionButton: {
      display: 'block',
      width: '100%',
      padding: '10px',
      backgroundColor: '#f1f1f1',
      border: 'none',
      borderBottom: '1px solid #ccc',
      textAlign: 'left',
      cursor: 'pointer',
      fontWeight: 'bold',
      fontFamily: 'Arial',
    },
    sectionContent: {
      padding: '15px',
      backgroundColor: '#f9f9f9',
      borderRadius: '4px',
      height: '100%',
    },
    formGroup: {
      marginBottom: '15px',
    },
    label: {
      display: 'block',
      fontWeight: 'bold',
      marginBottom: '5px',
    },
    input: {
      width: '100%',
      padding: '8px',
      height: '36px',
      borderRadius: '4px',
      border: '1px solid #CED4DA',
      fontSize: '14px',
    },
    splitContainer: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    leftColumn: {
      flex: 1,
      marginRight: '20px',
    },
    rightColumn: {
      flex: 1,
      marginLeft: '20px',
    },
    textarea: {
      width: '100%',
      padding: '12px',
      borderRadius: '4px',
      border: '1px solid #CED4DA',
      resize: 'vertical',
      minHeight: '36px',
      fontSize: '14px',
      backgroundColor: '#fff',
      fontFamily: 'inherit',
    },
    profileTextarea: {
      width: '100%',
      padding: '12px',
      borderRadius: '4px',
      border: '1px solid #CED4DA',
      resize: 'vertical',
      minHeight: '36px',
      fontSize: '14px',
      backgroundColor: '#fff',
      fontFamily: 'inherit',
    },
    aiTextarea: {
      width: '100%',
      padding: '12px',
      borderRadius: '4px',
      border: '1px solid #CED4DA',
      resize: 'none',
      fontFamily: 'inherit',
      minHeight: '500px',
      backgroundColor: '#e6f3ff',
    },
    modal: {
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '80%',
        height: '80%',
        padding: '20px',
        borderRadius: '10px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
      },
    },
    topSectionContainer: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr 1fr',
      gap: '20px',
      marginBottom: '20px',
      width: '100%',
    },
    parentDetailsSection: {
      gridColumn: 'span 2',
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      gap: '20px',
    },
    schoolDetailsSection: {
      gridColumn: '3',
    },
  };

  return (
    <div style={styles.container}>
      <div style={styles.headerContainer}>
        <button style={styles.backButton} onClick={() => navigate('/admin-materials')}>
          Back
        </button>
        <div style={{ display: 'flex', alignItems: 'center', flex: 1, justifyContent: 'center' }}>
          <h2 style={styles.header}>School Profile Report</h2>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', minWidth: '150px' }}>
          {saving ? (
            <div style={{ color: '#28a745' }}>Saving data...</div>
          ) : (
            <div style={{ color: '#28a745' }}>{dataStatus}</div>
          )}
        </div>
        <div style={styles.buttonGroup}>
          <button style={styles.button} onClick={openModal}>
            Preview
          </button>
          <PDFDownloadLink
            document={<SchoolPhilosophyPDF aiPhilosophy={formData.aiPhilosophy} formData={formData} />}
            fileName="school_profile_report.pdf"
            style={{ textDecoration: 'none' }}
          >
            {({ loading }) => (
              <button type="button" style={styles.button}>
                {loading ? 'Download' : 'Download'}
              </button>
            )}
          </PDFDownloadLink>
        </div>
      </div>

      <div style={styles.topSectionContainer}>
        <div style={styles.parentDetailsSection}>
          <div>
            <h3>Parent/Guardian Details</h3>
            <div style={styles.sectionContent}>
              <div style={styles.leftColumn}>
                <div style={styles.formGroup}>
                  <label style={styles.label}>Prefix</label>
                  <input
                    type="text"
                    name="prefix"
                    value={formData.prefix}
                    onChange={handleChange}
                    style={styles.input}
                  />
                </div>
                <div style={styles.formGroup}>
                  <label style={styles.label}>First Name</label>
                  <input
                    type="text"
                    name="firstName"
                    value={formData.firstName}
                    onChange={handleChange}
                    style={styles.input}
                  />
                </div>
                <div style={styles.formGroup}>
                  <label style={styles.label}>Middle Initial</label>
                  <input
                    type="text"
                    name="middleInitial"
                    value={formData.middleInitial}
                    onChange={handleChange}
                    style={styles.input}
                  />
                </div>
                <div style={styles.formGroup}>
                  <label style={styles.label}>Last Name</label>
                  <input
                    type="text"
                    name="lastName"
                    value={formData.lastName}
                    onChange={handleChange}
                    style={styles.input}
                  />
                </div>
                <div style={styles.formGroup}>
                  <label style={styles.label}>Title</label>
                  <input
                    type="text"
                    name="title"
                    value={formData.title}
                    onChange={handleChange}
                    style={styles.input}
                  />
                </div>
              </div>
            </div>
          </div>
          <div>
            <h3>&nbsp;</h3>
            <div style={styles.sectionContent}>
              <div style={styles.rightColumn}>
                <div style={styles.formGroup}>
                  <label style={styles.label}>Phone Number</label>
                  <input
                    type="text"
                    name="phoneNumber"
                    value={formData.phoneNumber}
                    onChange={handleChange}
                    style={styles.input}
                  />
                </div>
                <div style={styles.formGroup}>
                  <label style={styles.label}>Fax</label>
                  <input
                    type="text"
                    name="fax"
                    value={formData.fax}
                    onChange={handleChange}
                    style={styles.input}
                  />
                </div>
                <div style={styles.formGroup}>
                  <label style={styles.label}>Email Address</label>
                  <input
                    type="email"
                    name="emailAddress"
                    value={formData.emailAddress}
                    onChange={handleChange}
                    style={styles.input}
                  />
                </div>
                <div style={styles.formGroup}>
                  <label style={styles.label}>Profile URL</label>
                  <input
                    type="url"
                    name="profileURL"
                    value={formData.profileURL}
                    onChange={handleChange}
                    style={styles.input}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div style={styles.schoolDetailsSection}>
          <h3>School Details</h3>
          <div style={styles.sectionContent}>
            <div style={styles.formGroup}>
              <label style={styles.label}>Graduating Class Size</label>
              <input
                type="number"
                name="graduatingClassSize"
                value={formData.graduatingClassSize}
                onChange={handleChange}
                style={styles.input}
              />
            </div>
            <div style={styles.formGroup}>
              <label style={styles.label}>Are classes taken on a block schedule?</label>
              <select
                name="blockSchedule"
                value={formData.blockSchedule}
                onChange={handleChange}
                style={styles.input}
              >
                <option value="yes">Yes</option>
                <option value="no">No</option>
              </select>
            </div>
            <div style={styles.formGroup}>
              <label style={styles.label}>Graduation Date</label>
              <input
                type="date"
                name="graduationDate"
                value={formData.graduationDate}
                onChange={handleChange}
                style={styles.input}
              />
            </div>
            <div style={styles.formGroup}>
              <label style={styles.label}>Is your school located outside of the United States?</label>
              <select name="outsideUS" value={formData.outsideUS} onChange={handleChange} style={styles.input}>
                <option value="yes">Yes</option>
                <option value="no">No</option>
              </select>
            </div>
            <div style={styles.formGroup}>
              <label style={styles.label}>Does your school require students to perform volunteer service?</label>
              <select
                name="volunteerService"
                value={formData.volunteerService}
                onChange={handleChange}
                style={styles.input}
              >
                <option value="yes">Yes</option>
                <option value="no">No</option>
              </select>
            </div>
            <div style={styles.formGroup}>
              <label style={styles.label}>School Address</label>
              <textarea
                name="schoolAddress"
                value={formData.schoolAddress}
                onChange={handleChange}
                rows="3"
                style={styles.textarea}
              />
            </div>
          </div>
        </div>
      </div>

      <div>
        <h3>School Profile</h3>
        <div style={styles.sectionContent}>
          <div style={styles.formGroup}>
            <label style={styles.label}>Describe your educational philosophy</label>
            <textarea
              name="oneSentencePhilosophy"
              value={formData.oneSentencePhilosophy}
              onChange={handleChange}
              rows="3"
              style={styles.profileTextarea}
            />
          </div>
          <div style={styles.formGroup}>
            <label style={styles.label}>Describe why you chose to homeschool</label>
            <textarea
              name="whyHomeschool"
              value={formData.whyHomeschool}
              onChange={handleChange}
              rows="3"
              style={styles.profileTextarea}
            />
          </div>
          <div style={styles.formGroup}>
            <label style={styles.label}>Describe what types of learning were encouraged</label>
            <textarea
              name="typesOfLearning"
              value={formData.typesOfLearning}
              onChange={handleChange}
              rows="3"
              style={styles.profileTextarea}
            />
          </div>
          <div style={styles.formGroup}>
            <label style={styles.label}>Describe how academic courses were structured</label>
            <textarea
              name="courseStructure"
              value={formData.courseStructure}
              onChange={handleChange}
              rows="3"
              style={styles.profileTextarea}
            />
          </div>
          <div style={styles.formGroup}>
            <label style={styles.label}>Describe how learning goals and course evaluation within courses was measured</label>
            <textarea
              name="successMeasurement"
              value={formData.successMeasurement}
              onChange={handleChange}
              rows="3"
              style={styles.profileTextarea}
            />
          </div>
          <div style={styles.formGroup}>
            <label style={styles.label}>
              Describe the extracurricular opportunities and activities that were pursued and encouraged
            </label>
            <textarea
              name="extracurricularOpportunities"
              value={formData.extracurricularOpportunities}
              onChange={handleChange}
              rows="3"
              style={styles.profileTextarea}
            />
          </div>
          <div style={styles.formGroup}>
            <button type="button" style={styles.button} onClick={generateAIDescription}>
              Generate AI School Profile Report
            </button>
          </div>
          <div style={styles.formGroup}>
            <label style={styles.label}>AI Generated School Report (You may edit directly as well!)</label>
            <textarea
              name="aiPhilosophy"
              value={formData.aiPhilosophy}
              onChange={handleChange}
              rows="6"
              style={styles.aiTextarea}
            />
          </div>
        </div>
      </div>

      <Modal isOpen={isModalOpen} onRequestClose={closeModal} style={styles.modal} contentLabel="PDF Preview">
        <button style={styles.button} onClick={closeModal}>
          Close
        </button>
        <PDFViewer width="100%" height="100%">
          <SchoolPhilosophyPDF aiPhilosophy={formData.aiPhilosophy} formData={formData} />
        </PDFViewer>
      </Modal>
    </div>
  );
};

export default SchoolPhilosophy;
