import React, { useState } from 'react';
import { useAuth } from '../utils/AuthContext';
import { useNavigate } from 'react-router-dom';

const MyAccount = () => {
  const { user, logout } = useAuth();
  const [showPasswordForm, setShowPasswordForm] = useState(false);
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState(''); // 'success' or 'error'
  const navigate = useNavigate();

  if (!user) {
    return <p>Please log in.</p>;
  }

  const handlePasswordChange = async (e) => {
    e.preventDefault();
    
    if (newPassword !== confirmPassword) {
      setMessage('New passwords do not match');
      setMessageType('error');
      return;
    }

    try {
      const response = await fetch('/api/auth/password-reset', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          email: user.email,
          currentPassword,
          newPassword: newPassword.trim(),
          fromAccount: true
        })
      });

      if (response.ok) {
        setMessage('Password successfully changed');
        setMessageType('success');
        setShowPasswordForm(false);
        // Clear the form
        setCurrentPassword('');
        setNewPassword('');
        setConfirmPassword('');
      } else {
        const data = await response.json();
        setMessage(data.message || 'Current password is incorrect');
        setMessageType('error');
      }
    } catch (error) {
      console.error('Error changing password:', error);
      setMessage('Error changing password. Please try again.');
      setMessageType('error');
    }
  };

  const styles = {
    container: {
      maxWidth: '800px',
      margin: '20px auto',
      padding: '40px',
      backgroundColor: '#ffffff',
      borderRadius: '16px',
      boxShadow: '0 4px 20px rgba(0, 0, 0, 0.05)',
    },
    header: {
      color: '#2B6CB0',
      fontSize: '28px',
      fontWeight: '600',
      marginBottom: '32px',
      textAlign: 'center',
    },
    infoSection: {
      marginBottom: '32px',
      width: '400px',
      margin: '0 auto 32px auto',
    },
    infoItem: {
      display: 'flex',
      gap: '8px',
      marginBottom: '12px',
      fontSize: '16px',
      color: '#2D3748',
      justifyContent: 'flex-start',
    },
    label: {
      fontWeight: '600',
      minWidth: '60px',
    },
    buttonContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: '16px',
      maxWidth: '400px',
      margin: '0 auto',
    },
    button: {
      padding: '12px 24px',
      backgroundColor: '#3182CE',
      color: '#ffffff',
      border: 'none',
      borderRadius: '8px',
      cursor: 'pointer',
      fontSize: '16px',
      fontWeight: '500',
      transition: 'all 0.2s ease',
      '&:hover': {
        transform: 'translateY(-1px)',
        boxShadow: '0 4px 12px rgba(49, 130, 206, 0.15)',
      },
    },
    logoutButton: {
      backgroundColor: '#1a365d',
      marginTop: '32px',
      '&:hover': {
        backgroundColor: '#2c5282',
      },
    },
    passwordForm: {
      marginTop: '24px',
      padding: '24px',
      backgroundColor: '#F7FAFC',
      borderRadius: '12px',
      border: '1px solid #E2E8F0',
    },
    formGroup: {
      marginBottom: '16px',
    },
    formLabel: {
      display: 'block',
      marginBottom: '8px',
      color: '#4A5568',
      fontSize: '14px',
      fontWeight: '500',
    },
    input: {
      width: '100%',
      padding: '10px 16px',
      borderRadius: '6px',
      border: '1px solid #E2E8F0',
      fontSize: '15px',
      transition: 'all 0.2s ease',
      '&:focus': {
        outline: 'none',
        borderColor: '#3182CE',
        boxShadow: '0 0 0 3px rgba(49, 130, 206, 0.1)',
      },
    },
  };

  return (
    <div style={styles.container}>
      <h2 style={styles.header}>Account Settings</h2>
      
      <div style={styles.infoSection}>
        <div style={styles.infoItem}>
          <span style={styles.label}>Name:</span>
          <span>{user.name}</span>
        </div>
        <div style={styles.infoItem}>
          <span style={styles.label}>Email:</span>
          <span>{user.email}</span>
        </div>
      </div>

      <div style={styles.buttonContainer}>
        <button 
          style={styles.button}
          onClick={() => navigate('/onboarding-college', { state: { isEditing: true } })}
        >
          Edit Onboarding Data
        </button>

        <button 
          style={styles.button}
          onClick={() => setShowPasswordForm(!showPasswordForm)}
        >
          Change Password
        </button>

        {showPasswordForm && (
          <form style={styles.passwordForm} onSubmit={handlePasswordChange}>
            <div style={styles.formGroup}>
              <label style={styles.formLabel}>Current Password</label>
              <input
                type="password"
                value={currentPassword}
                onChange={(e) => setCurrentPassword(e.target.value)}
                required
                style={styles.input}
              />
            </div>
            <div style={styles.formGroup}>
              <label style={styles.formLabel}>New Password</label>
              <input
                type="password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                required
                style={styles.input}
              />
            </div>
            <div style={styles.formGroup}>
              <label style={styles.formLabel}>Confirm New Password</label>
              <input
                type="password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
                style={styles.input}
              />
            </div>
            <button type="submit" style={styles.button}>
              Update Password
            </button>
          </form>
        )}

        <button 
          style={{...styles.button, ...styles.logoutButton}}
          onClick={logout}
        >
          Log Out
        </button>
      </div>
    </div>
  );
};

export default MyAccount;
