import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from '../assets/logo.png';

const PasswordReset = () => {
  const [email, setEmail] = useState('');
  const [step, setStep] = useState('request');
  const [resetToken, setResetToken] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const handleRequestReset = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('/api/auth/password-reset', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email })
      });

      if (response.ok) {
        setStep('verify');
      } else {
        const data = await response.json();
        alert(data.message);
      }
    } catch (error) {
      console.error('Error:', error);
      alert('Error requesting password reset');
    }
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      alert('Passwords do not match');
      return;
    }

    try {
      const response = await fetch('/api/auth/password-reset', {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, resetToken, newPassword })
      });

      if (response.ok) {
        setStep('success');
      } else {
        const data = await response.json();
        alert(data.message);
      }
    } catch (error) {
      console.error('Error:', error);
      alert('Error resetting password');
    }
  };

  return (
    <div style={styles.background}>
      <div style={styles.container}>
        <div style={styles.header}>
          <div style={styles.logoContainer}>
            <img src={logo} alt="Logo" style={styles.logo} />
            <h1 style={styles.companyName}>YourEDU</h1>
          </div>
        </div>
        <div style={styles.formContainer}>
          <h2 style={styles.formTitle}>Password Reset</h2>
          
          {step === 'request' && (
            <form onSubmit={handleRequestReset} style={styles.form}>
              <div style={styles.inputGroup}>
                <label style={styles.label}>Email</label>
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  style={styles.input}
                />
              </div>
              <button type="submit" style={styles.button}>Request Reset</button>
            </form>
          )}

          {step === 'verify' && (
            <form onSubmit={handleResetPassword} style={styles.form}>
              <div style={styles.inputGroup}>
                <label style={styles.label}>Reset Code</label>
                <input
                  type="text"
                  value={resetToken}
                  onChange={(e) => setResetToken(e.target.value)}
                  required
                  style={styles.input}
                />
              </div>
              <div style={styles.inputGroup}>
                <label style={styles.label}>New Password</label>
                <input
                  type={showPassword ? "text" : "password"}
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  required
                  style={styles.input}
                />
              </div>
              <div style={styles.inputGroup}>
                <label style={styles.label}>Confirm Password</label>
                <input
                  type={showPassword ? "text" : "password"}
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  required
                  style={styles.input}
                />
              </div>
              <div style={styles.extraOptions}>
                <label style={styles.showPassword}>
                  <input
                    type="checkbox"
                    checked={showPassword}
                    onChange={(e) => setShowPassword(e.target.checked)}
                  />
                  Show password
                </label>
              </div>
              <button type="submit" style={styles.button}>Reset Password</button>
            </form>
          )}

          {step === 'success' && (
            <div>
              <p>Password has been reset successfully!</p>
              <button 
                onClick={() => navigate('/login')}
                style={styles.button}
              >
                Return to Login
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const styles = {
  background: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    width: '100vw',
    backgroundColor: '#00356b',
    position: 'fixed',
    top: 0,
    left: 0,
    margin: 0,
  },
  container: {
    backgroundColor: '#fff',
    borderRadius: '10px',
    padding: '40px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    width: '100%',
    maxWidth: '400px',
  },
  header: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '20px',
  },
  logoContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  logo: {
    height: '50px',
    marginRight: '10px',
  },
  companyName: {
    fontSize: '24px',
    fontWeight: 'bold',
    color: '#00356b',
  },
  formContainer: {
    width: '100%',
    textAlign: 'left',
  },
  formTitle: {
    fontSize: '20px',
    marginBottom: '20px',
    color: '#00356b',
    textAlign: 'center',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  inputGroup: {
    margin: '10px 0',
  },
  label: {
    marginBottom: '5px',
    fontWeight: 'bold',
    color: '#00356b',
  },
  input: {
    padding: '10px',
    width: '100%',
    boxSizing: 'border-box',
    borderRadius: '4px',
    border: '1px solid #ccc',
    backgroundColor: '#f9f9f9',
  },
  extraOptions: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '5px',
    fontSize: '14px',
  },
  showPassword: {
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
    cursor: 'pointer',
  },
  button: {
    padding: '10px 20px',
    backgroundColor: '#00356b',
    color: 'white',
    border: 'none',
    cursor: 'pointer',
    borderRadius: '4px',
    marginTop: '20px',
    width: '100%',
  },
};

export default PasswordReset;
