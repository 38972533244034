import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useAuth } from '../utils/AuthContext';
import logo from '../assets/logo.png';

const Navbar = () => {
  const { user, logout } = useAuth();
  const location = useLocation();
  const userType = localStorage.getItem('userType');

  const isActive = (path) => {
    if (path === '/') {
      return location.pathname === path;
    }
    return location.pathname.startsWith(path);
  };

  return (
    <nav style={styles.navbar}>
      <div style={styles.navContent}>
        <div style={styles.leftContent}>
          <Link to="/" style={styles.logoLink}>
            <img src={logo} alt="Logo" style={styles.logo} />
            <span style={styles.brandName}>YourEDU</span>
          </Link>
          <ul style={styles.navList}>
            {user ? (
              userType === 'counselor' ? (
                <>
                  <li style={styles.navItem}>
                    <Link to="/" style={{ ...styles.navLink, ...(isActive('/') ? styles.activeNavLink : {}) }}>Home</Link>
                  </li>
                  <li style={styles.navItem}>
                    <Link to="/my-students" style={{ ...styles.navLink, ...(isActive('/my-students') ? styles.activeNavLink : {}) }}>My Students</Link>
                  </li>
                  <li style={styles.navItem}>
                    <Link to="/school-search" style={{ ...styles.navLink, ...(isActive('/school-search') ? styles.activeNavLink : {}) }}>School Search</Link>
                  </li>
                </>
              ) : userType === 'temp' ? (
                <>
                  <li style={styles.navItem}>
                    <Link to="/" style={{ ...styles.navLink, ...(isActive('/') ? styles.activeNavLink : {}) }}>Home</Link>
                  </li>
                  <li style={styles.navItem}>
                    <Link to="/enrollment-form" style={{ ...styles.navLink, ...(isActive('/enrollment-form') ? styles.activeNavLink : {}) }}>YourEDU Enrollment Form</Link>
                  </li>
                </>
              ) : userType === 'highschool' ? (
                <>
                  <li style={styles.navItem}>
                    <Link to="/" style={{ ...styles.navLink, ...(isActive('/') ? styles.activeNavLink : {}) }}>Home</Link>
                  </li>
                  <li style={styles.navItem}>
                    <Link to="/mycourses" style={{ ...styles.navLink, ...(isActive('/mycourses') ? styles.activeNavLink : {}) }}>My Courses</Link>
                  </li>
                  <li style={styles.navItem}>
                    <Link to="/course-planning" style={{ ...styles.navLink, ...(isActive('/course-planning') ? styles.activeNavLink : {}) }}>Course Planning</Link>
                  </li>
                  <li style={styles.navItem}>
                    <Link to="/coursesearch" style={{ ...styles.navLink, ...(isActive('/coursesearch') ? styles.activeNavLink : {}) }}>Course Search</Link>
                  </li>
                  <li style={styles.navItem}>
                    <Link to="/registration" style={{ ...styles.navLink, ...(isActive('/registration') ? styles.activeNavLink : {}) }}>Registration</Link>
                  </li>
                  <li style={styles.navItem}>
                    <Link to="/extracurriculars" style={{ ...styles.navLink, ...(isActive('/extracurriculars') ? styles.activeNavLink : {}) }}>Extracurriculars</Link>
                  </li>
                  <li style={styles.navItem}>
                    <Link to="/career-exploration" style={{ ...styles.navLink, ...(isActive('/career-exploration') ? styles.activeNavLink : {}) }}>Career Exploration</Link>
                  </li>
                  <li style={styles.navItem}>
                    <Link to="/colleges" style={{ ...styles.navLink, ...(isActive('/colleges') ? styles.activeNavLink : {}) }}>College Prep</Link>
                  </li>
                </>
              ) : (
                <>
                  <li style={styles.navItem}>
                    <Link to="/" style={{ ...styles.navLink, ...(isActive('/') ? styles.activeNavLink : {}) }}>Home</Link>
                  </li>
                  <li style={styles.navItem}>
                    <Link to="/admin-materials" style={{ ...styles.navLink, ...(isActive('/admin-materials') ? styles.activeNavLink : {}) }}>Admin Materials</Link>
                  </li>
                  <li style={styles.navItem}>
                    <Link to="/school-search" style={{ ...styles.navLink, ...(isActive('/school-search') ? styles.activeNavLink : {}) }}>Colleges</Link>
                  </li>
                  <li style={styles.navItem}>
                    <Link to="/scholarships" style={{ ...styles.navLink, ...(isActive('/scholarships') ? styles.activeNavLink : {}) }}>Scholarships</Link>
                  </li>
                </>
              )
            ) : (
              <>
                <li style={styles.navItem}>
                  <Link to="/login" style={{ ...styles.navLink, ...(isActive('/login') ? styles.activeNavLink : {}) }}>Login</Link>
                </li>
                <li style={styles.navItem}>
                  <Link to="/register" style={{ ...styles.navLink, ...(isActive('/register') ? styles.activeNavLink : {}) }}>Create Account</Link>
                </li>
              </>
            )}
          </ul>
        </div>
        <div style={styles.rightContent}>
          <Link 
            to="/account" 
            style={styles.accountButton}
          >
            {user?.email}
          </Link>
          <button onClick={logout} style={styles.logoutButton}>Log Out</button>
        </div>
      </div>
    </nav>
  );
};

const styles = {
  navbar: {
    backgroundColor: '#ffffff',
    padding: '14px 24px',
    borderBottom: '1px solid #3182CE',
    boxShadow: '0 2px 4px rgba(49, 130, 206, 0.1)',
    fontFamily: "'Inter', sans-serif",
  },
  navContent: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    maxWidth: '1600px',
    margin: '0 auto',
    position: 'relative',
  },
  leftContent: {
    display: 'flex',
    alignItems: 'center',
  },
  logoLink: {
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    marginRight: '48px',
  },
  logo: {
    height: '40px',
    marginRight: '12px',
  },
  brandName: {
    fontSize: '24px',
    color: '#2B6CB0',
    fontWeight: '600',
    fontFamily: "'Inter', sans-serif",
    letterSpacing: '-0.3px',
  },
  navList: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    listStyleType: 'none',
    padding: 0,
    margin: 0,
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  navItem: {
    margin: '4px',
    display: 'flex',
    justifyContent: 'center',
  },
  navLink: {
    color: '#2B6CB0',
    textDecoration: 'none',
    padding: '10px 16px',
    borderRadius: '6px',
    fontSize: '15px',
    fontWeight: '600',
    transition: 'all 0.2s ease',
    fontFamily: "'Inter', sans-serif",
    position: 'relative',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '40px',
    lineHeight: '1.2',
    whiteSpace: 'normal',
    '&:hover': {
      backgroundColor: '#EBF8FF',
      color: '#2C5282',
    },
  },
  activeNavLink: {
    backgroundColor: '#3182CE',
    color: '#ffffff',
    fontWeight: '700',
    position: 'relative',
    borderRadius: '6px',
    padding: '10px 16px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    transform: 'translateY(-1px)',
    textAlign: 'center',
    minHeight: '40px',
    '&:hover': {
      backgroundColor: '#2B6CB0',
    },
  },
  rightContent: {
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
  },
  accountButton: {
    backgroundColor: '#f7fafc',
    color: '#1a365d',
    border: '1px solid #1a365d',
    borderRadius: '6px',
    padding: '8px 16px',
    fontSize: '14px',
    fontWeight: '500',
    cursor: 'pointer',
    textDecoration: 'none',
    fontFamily: "'Inter', sans-serif",
    transition: 'all 0.2s ease',
    '&:hover': {
      backgroundColor: '#edf2f7',
    },
  },
  logoutButton: {
    backgroundColor: '#1a365d',
    color: '#ffffff',
    border: 'none',
    borderRadius: '6px',
    padding: '8px 16px',
    fontSize: '14px',
    fontWeight: '500',
    cursor: 'pointer',
    fontFamily: "'Inter', sans-serif",
    transition: 'all 0.2s ease',
    '&:hover': {
      backgroundColor: '#2c5282',
    },
  },
};

// Add font import
const fontImport = document.createElement('link');
fontImport.href = 'https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap';
fontImport.rel = 'stylesheet';
document.head.appendChild(fontImport);

export default Navbar;
