import React, { useState, useEffect } from 'react';
import { useAuth } from '../utils/AuthContext';
import PageHeader from '../components/PageHeader';

const CourseSearch = () => {
  const { user } = useAuth();
  const [courses, setCourses] = useState([]);
  const [filteredCourses, setFilteredCourses] = useState([]);
  const [filters, setFilters] = useState({
    campus: '',
    zipCode: '',
    instructionMethod: '',
    subject: '',
    level: '',
    searchTerm: ''
  });

  // Load course data
  useEffect(() => {
    // This would normally fetch from an API
    // For now, we'll use the spreadsheet data directly
    const loadCourses = async () => {
      try {
        const response = await fetch('/data/sierra-college-courses.json');
        const data = await response.json();
        setCourses(data);
        setFilteredCourses(data);
      } catch (error) {
        console.error('Error loading courses:', error);
      }
    };
    loadCourses();
  }, []);

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters(prev => ({
      ...prev,
      [name]: value
    }));
  };

  // Apply filters
  useEffect(() => {
    let filtered = courses;

    if (filters.searchTerm) {
      filtered = filtered.filter(course => 
        course.courseTitle.toLowerCase().includes(filters.searchTerm.toLowerCase()) ||
        course.courseCode.toLowerCase().includes(filters.searchTerm.toLowerCase())
      );
    }

    if (filters.campus) {
      filtered = filtered.filter(course => 
        course.institution.toLowerCase().includes(filters.campus.toLowerCase())
      );
    }

    if (filters.zipCode) {
      filtered = filtered.filter(course => 
        course.zipCode === filters.zipCode
      );
    }

    if (filters.instructionMethod) {
      filtered = filtered.filter(course => 
        course.instructionMethod.toLowerCase() === filters.instructionMethod.toLowerCase()
      );
    }

    if (filters.subject) {
      filtered = filtered.filter(course => 
        course.subjectTags.includes(filters.subject)
      );
    }

    if (filters.level) {
      filtered = filtered.filter(course => 
        course.level === filters.level
      );
    }

    setFilteredCourses(filtered);
  }, [filters, courses]);

  return (
    <div style={styles.container}>
      <PageHeader title="Course Search" />
      
      {/* Search and Filters */}
      <div style={styles.filtersContainer}>
        <input
          type="text"
          name="searchTerm"
          placeholder="Search courses..."
          value={filters.searchTerm}
          onChange={handleFilterChange}
          style={styles.searchInput}
        />
        
        <div style={styles.filterControls}>
          <select 
            name="campus" 
            value={filters.campus}
            onChange={handleFilterChange}
            style={styles.select}
          >
            <option value="">All Campuses</option>
            <option value="Sierra College - Rocklin">Rocklin Campus</option>
            {/* Add other campuses */}
          </select>

          <select 
            name="instructionMethod" 
            value={filters.instructionMethod}
            onChange={handleFilterChange}
            style={styles.select}
          >
            <option value="">All Instruction Methods</option>
            <option value="Live & In-Person">Live & In-Person</option>
            <option value="Online">Online</option>
            <option value="Hybrid">Hybrid</option>
          </select>

          <select 
            name="subject" 
            value={filters.subject}
            onChange={handleFilterChange}
            style={styles.select}
          >
            <option value="">All Subjects</option>
            <option value="Mathematics">Mathematics</option>
            {/* Add other subjects */}
          </select>
        </div>
      </div>

      {/* Course List */}
      <div style={styles.courseList}>
        {filteredCourses.map((course, index) => (
          <div key={index} style={styles.courseCard}>
            <div style={styles.courseHeader}>
              <h3 style={styles.courseTitle}>{course.courseTitle}</h3>
              <span style={styles.courseCode}>{course.courseCode}</span>
            </div>
            
            <div style={styles.courseInfo}>
              <p style={styles.courseDates}>
                <strong>Dates:</strong> {course.courseDates}
              </p>
              <p style={styles.courseSchedule}>
                <strong>Schedule:</strong> {course.courseSchedule}
              </p>
              <p style={styles.instructor}>
                <strong>Instructor:</strong> {course.instructor}
              </p>
              <p style={styles.credits}>
                <strong>Credits:</strong> {course.credits}
              </p>
              <p style={styles.method}>
                <strong>Method:</strong> {course.instructionMethod}
              </p>
              {course.prerequisites && (
                <p style={styles.prerequisites}>
                  <strong>Prerequisites:</strong> {course.prerequisites}
                </p>
              )}
            </div>

            <div style={styles.courseDescription}>
              {course.courseDescription}
            </div>

            <div style={styles.courseFooter}>
              <span style={styles.enrollment}>
                {course.maxStudents - (course.currentEnrollment || 0)} spots remaining
              </span>
              <button 
                style={styles.enrollButton}
                onClick={() => {/* Handle enrollment */}}
              >
                Learn More
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const styles = {
  container: {
    padding: '2rem',
    maxWidth: '1200px',
    margin: '0 auto',
  },
  filtersContainer: {
    marginBottom: '2rem',
  },
  searchInput: {
    width: '100%',
    padding: '0.75rem',
    fontSize: '1.1rem',
    borderRadius: '8px',
    border: '1px solid #ddd',
    marginBottom: '1rem',
  },
  filterControls: {
    display: 'flex',
    gap: '1rem',
    flexWrap: 'wrap',
  },
  select: {
    padding: '0.5rem',
    borderRadius: '4px',
    border: '1px solid #ddd',
    minWidth: '200px',
  },
  courseList: {
    display: 'grid',
    gap: '2rem',
  },
  courseCard: {
    backgroundColor: 'white',
    borderRadius: '8px',
    padding: '1.5rem',
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
  },
  courseHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '1rem',
  },
  courseTitle: {
    margin: 0,
    fontSize: '1.25rem',
    color: '#333',
  },
  courseCode: {
    color: '#666',
    fontSize: '0.9rem',
  },
  courseInfo: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))',
    gap: '1rem',
    marginBottom: '1rem',
    fontSize: '0.9rem',
  },
  courseDescription: {
    fontSize: '0.9rem',
    color: '#666',
    marginBottom: '1rem',
    lineHeight: '1.5',
  },
  courseFooter: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '1rem',
    paddingTop: '1rem',
    borderTop: '1px solid #eee',
  },
  enrollment: {
    color: '#666',
    fontSize: '0.9rem',
  },
  enrollButton: {
    backgroundColor: '#4CAF50',
    color: 'white',
    border: 'none',
    borderRadius: '4px',
    padding: '0.5rem 1rem',
    cursor: 'pointer',
    fontSize: '0.9rem',
  },
};

export default CourseSearch; 