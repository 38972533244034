import React, { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { useAuth } from '../utils/AuthContext';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import PageHeader from '../components/PageHeader';

pdfjs.GlobalWorkerOptions.workerSrc = 'https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js';

const Registration = () => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  return (
    <div style={styles.container}>
      <PageHeader title="Registration" />
      <div style={styles.pdfContainer}>
        <Document
          file="/AE-Compliance-Form.pdf"
          onLoadSuccess={({ numPages }) => {
            setNumPages(numPages);
            console.log('PDF loaded successfully');
          }}
          onLoadError={(error) => {
            console.error('Error loading PDF:', error);
          }}
        >
          <Page 
            pageNumber={pageNumber}
            scale={1.2}
          />
        </Document>
        
        {numPages && (
          <div style={styles.pdfControls}>
            <button 
              onClick={() => setPageNumber(prev => Math.max(prev - 1, 1))}
              disabled={pageNumber <= 1}
              style={styles.button}
            >
              Previous
            </button>
            <span style={styles.pageInfo}>Page {pageNumber} of {numPages}</span>
            <button 
              onClick={() => setPageNumber(prev => Math.min(prev + 1, numPages))}
              disabled={pageNumber >= numPages}
              style={styles.button}
            >
              Next
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

const styles = {
  container: {
    padding: '2rem',
    maxWidth: '1200px',
    margin: '0 auto',
  },
  pdfContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: 'white',
    borderRadius: '8px',
    padding: '2rem',
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
  },
  pdfControls: {
    display: 'flex',
    gap: '1rem',
    alignItems: 'center',
    marginTop: '1rem',
  },
  button: {
    padding: '0.5rem 1rem',
    backgroundColor: '#007BFF',
    color: 'white',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    '&:disabled': {
      backgroundColor: '#cccccc',
      cursor: 'not-allowed',
    },
  },
  pageInfo: {
    color: '#666',
    fontSize: '0.9rem',
  },
};

export default Registration;