import React, { useEffect } from 'react';
import { Route, Routes, Navigate, useLocation, useNavigate } from 'react-router-dom';
import Navbar from './components/Navbar';
import Home from './pages/Home';
import LoginSelection from './pages/LoginSelection';
import Login from './pages/Login';
import Register from './pages/Register';
import PasswordReset from './pages/PasswordReset';
import ResetPassword from './pages/ResetPassword'; // Importing the new ResetPassword component
import PasswordSuccess from './pages/PasswordSuccess';
import MyAccount from './pages/MyAccount';
import Transcript from './pages/Transcript';
import CourseDescriptions from './pages/CourseDescriptions';
import SchoolPhilosophy from './pages/SchoolPhilosophy';
import GradingRubric from './pages/GradingRubric';
import Colleges from './pages/Colleges';
import SchoolList from './pages/SchoolList';
import Scholarships from './pages/Scholarships';
import Extracurriculars from './pages/Extracurriculars';
import AdminMaterials from './pages/AdminMaterials';
import GuidanceCounselorLetter from './pages/GuidanceCounselorLetter';
import MyStudents from './pages/MyStudents';
import EnrollmentForm from './pages/PSA';
import Profile from './pages/Profile';
import CoursePlanning from './pages/CoursePlanning'; 
import CollegePrep from './pages/CollegePrep'; 
import CareerExploration from './pages/CareerExploration'; 
import Onboarding from './pages/Onboarding';
import OnboardingCollege from './pages/OnboardingCollege';
import { AuthProvider, useAuth } from './utils/AuthContext';
import { DndProvider } from 'react-dnd'; // Import DndProvider
import { HTML5Backend } from 'react-dnd-html5-backend'; // Import HTML5Backend
import MyCourses from './pages/MyCourses';
import CourseDetail from './pages/CourseDetail';
import CourseSearch from './pages/CourseSearch';
import Registration from './pages/Registration';

const App = () => {
  return (
    <AuthProvider>
      <DndProvider backend={HTML5Backend}>
        <AppContent />
      </DndProvider>
    </AuthProvider>
  );
};

const AppContent = () => {
  const { user } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const userType = localStorage.getItem('userType');
  const hideNavbar = [
    '/login-selection',
    '/login',
    '/register',
    '/password-reset',
    '/reset-password/:token',
    '/onboarding',
    '/onboarding-college'
  ].includes(location.pathname);

  useEffect(() => {
    const checkOnboardingStatus = async () => {
      if (!user?.id) return;

      try {
        const response = await fetch(`/api/profile?userId=${user.id}`);
        
        // Handle 404 gracefully for new users
        if (response.status === 404) {
          if (userType === 'college') {
            navigate('/onboarding-college');
          } else if (userType === 'highschool') {
            navigate('/onboarding');
          }
          return;
        }

        if (!response.ok) {
          throw new Error('Failed to fetch profile');
        }

        const data = await response.json();
        if (!data.profile) {
          if (userType === 'college') {
            navigate('/onboarding-college');
          } else if (userType === 'highschool') {
            navigate('/onboarding');
          }
          return;
        }

        // Check onboarding completion status
        if (userType === 'college' && !data.profile.collegeOnboardingCompleted) {
          if (location.pathname !== '/onboarding-college') {
            navigate('/onboarding-college');
          }
        } else if (userType === 'highschool' && !data.profile.onboardingCompleted) {
          if (location.pathname !== '/onboarding') {
            navigate('/onboarding');
          }
        }
      } catch (error) {
        console.error('Error checking onboarding status:', error);
      }
    };

    checkOnboardingStatus();
  }, [user, userType, location.pathname, navigate]);

  return (
    <div style={styles.appContainer}>
      {!hideNavbar && <Navbar />}
      <div style={styles.pageContainer}>
        <Routes>
          {!user ? (
            <>
              <Route path="/login-selection" element={<LoginSelection />} />
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Register />} />
              <Route path="/password-reset" element={<PasswordReset />} />
              <Route path="/reset-password/:token" element={<ResetPassword />} /> {/* New reset password route */}
              <Route path="/password-success" element={<PasswordSuccess />} />
              <Route path="*" element={<Navigate to="/login-selection" />} />
            </>
          ) : (
            <>
              <Route path="/" element={<Home />} />
              {userType === 'counselor' ? (
                <>
                  <Route path="/my-students" element={<MyStudents />} />
                  <Route path="/school-search" element={<Colleges />} />
                  <Route path="/account" element={<MyAccount />} />
                </>
              ) : userType === 'college' ? (
                <>
                  <Route path="/onboarding-college" element={<OnboardingCollege />} />
                  <Route path="/" element={<Home />} />
                  <Route path="/school-search" element={<Colleges />} />
                  <Route path="/scholarships" element={<Scholarships />} />
                  <Route path="/extracurriculars" element={<Extracurriculars />} />
                  <Route path="/admin-materials/*" element={<AdminMaterials />} />
                  <Route path="/admin-materials/school-philosophy" element={<SchoolPhilosophy />} />
                  <Route path="/admin-materials/transcript" element={<Transcript />} />
                  <Route path="/admin-materials/course-descriptions" element={<CourseDescriptions />} />
                  <Route path="/admin-materials/grading-rubric" element={<GradingRubric />} />
                  <Route path="/admin-materials/guidance-counselor-letter" element={<GuidanceCounselorLetter />} />
                  <Route path="/profile" element={<Profile />} />
                  <Route path="/account" element={<MyAccount />} />
                </>
              ) : userType === 'highschool' ? (
                <>
                  <Route path="/onboarding" element={<Onboarding />} />
                  <Route path="/" element={<Home />} />
                  <Route path="/mycourses" element={<MyCourses />} />
                  <Route path="/mycourses/:courseId" element={<CourseDetail />} />
                  <Route path="/course-planning" element={<CoursePlanning />} />
                  <Route path="/extracurriculars" element={<Extracurriculars />} />
                  <Route path="/college-prep" element={<CollegePrep />} />
                  <Route path="/career-exploration" element={<CareerExploration />} />
                  <Route path="/account" element={<MyAccount />} />
                  <Route path="/coursesearch" element={<CourseSearch />} />
                  <Route path="/registration" element={<Registration />} />
                  <Route path="/colleges" element={<Colleges />} />
                </>
              ) : (
                <>
                  <Route path="/school-search" element={<Colleges />} />
                  <Route path="/scholarships" element={<Scholarships />} />
                  <Route path="/extracurriculars" element={<Extracurriculars />} />
                  <Route path="/admin-materials/*" element={<AdminMaterials />} />
                  <Route path="/admin-materials/school-philosophy" element={<SchoolPhilosophy />} />
                  <Route path="/admin-materials/transcript" element={<Transcript />} />
                  <Route path="/admin-materials/course-descriptions" element={<CourseDescriptions />} />
                  <Route path="/admin-materials/grading-rubric" element={<GradingRubric />} />
                  <Route path="/admin-materials/guidance-counselor-letter" element={<GuidanceCounselorLetter />} />
                  <Route path="/profile" element={<Profile />} />
                  <Route path="/account" element={<MyAccount />} />
                  //<Route path="/enrollment-form" element={<EnrollmentForm />} />
                  //<Route path="/account" element={<MyAccount />} />
                </>
              )}
              <Route path="*" element={<Navigate to="/" />} />
            </>
          )}
        </Routes>
      </div>
    </div>
  );
};

const styles = {
  appContainer: {
    backgroundColor: '#f8f9fa',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
  },
  pageContainer: {
    padding: '20px',
    paddingTop: '80px',
    width: '100%',
    maxWidth: '1200px',
    margin: '0 auto',
  },
};

export default App;
