import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import logo from '../assets/logo.png';

const ResetPassword = () => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const { token } = useParams();
  const navigate = useNavigate();

  const handlePasswordReset = async (e) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      alert("Passwords do not match");
      return;
    }

    try {
      const trimmedNewPassword = newPassword.trim();
      console.log('Attempting to reset password with token:', token);
      console.log('Password to be hashed:', trimmedNewPassword);
      const response = await fetch('/api/auth/password-reset', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token, newPassword: trimmedNewPassword }),
      });

      if (response.ok) {
        console.log('Password reset successful');
        alert('Password reset successfully');
        navigate('/login');
      } else {
        const data = await response.json();
        console.log('Password reset failed:', data.message);
        alert(data.message);
      }
    } catch (error) {
      console.error('Error resetting password:', error);
      alert('An error occurred. Please try again.');
    }
  };

  return (
    <div style={styles.background}>
      <div style={styles.container}>
        <div style={styles.header}>
          <div style={styles.logoContainer}>
            <img src={logo} alt="Logo" style={styles.logo} />
            <h1 style={styles.companyName}>YourEDU</h1>
          </div>
        </div>
        <div style={styles.formContainer}>
          <h2>Reset Password</h2>
          <form onSubmit={handlePasswordReset} style={styles.form}>
            <div style={styles.inputGroup}>
              <label style={styles.label}>New Password</label>
              <input
                type={showPassword ? "text" : "password"}
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                required
                style={styles.input}
              />
            </div>
            <div style={styles.inputGroup}>
              <label style={styles.label}>Confirm New Password</label>
              <input
                type={showPassword ? "text" : "password"}
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
                style={styles.input}
              />
            </div>
            <div style={styles.showPasswordContainer}>
              <input
                type="checkbox"
                checked={showPassword}
                onChange={() => setShowPassword(!showPassword)}
              /> Show Password
            </div>
            <div style={styles.buttonGroup}>
              <button type="submit" style={styles.continueButton}>Update Password</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

const styles = {
  background: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    width: '100vw',
    backgroundColor: '#00356b',
    position: 'fixed',
    top: 0,
    left: 0,
    margin: 0,
  },
  container: {
    backgroundColor: '#fff',
    borderRadius: '10px',
    padding: '40px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    width: '100%',
    maxWidth: '400px',
  },
  header: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '20px',
  },
  logoContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  logo: {
    height: '50px',
    marginRight: '10px',
  },
  companyName: {
    fontSize: '24px',
    fontWeight: 'bold',
    color: '#00356b',
  },
  formContainer: {
    width: '100%',
    textAlign: 'left',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  inputGroup: {
    margin: '10px 0',
  },
  label: {
    marginBottom: '5px',
    fontWeight: 'bold',
    color: '#00356b',
  },
  input: {
    padding: '10px',
    width: '100%',
    boxSizing: 'border-box',
    borderRadius: '4px',
    border: '1px solid #ccc',
    backgroundColor: '#f9f9f9',
  },
  showPasswordContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '10px',
  },
  buttonGroup: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '20px',
  },
  continueButton: {
    padding: '10px 20px',
    backgroundColor: '#00356b',
    color: 'white',
    border: 'none',
    cursor: 'pointer',
    borderRadius: '4px',
  },
};

export default ResetPassword;
