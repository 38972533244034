import React, { useState, useEffect, useRef, useCallback } from 'react';
import { PDFDownloadLink, Document, Page, Text, View, StyleSheet, PDFViewer, Image } from '@react-pdf/renderer';
import OpenAI from 'openai';
import Modal from 'react-modal';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../utils/AuthContext';
import logo from '../assets/logo.png';
import axios from 'axios';

const openai = new OpenAI({
  apiKey: process.env.REACT_APP_OPENAI_API_KEY,
  dangerouslyAllowBrowser: true,
});

const pdfStyles = StyleSheet.create({
  page: {
    padding: 30,
    fontSize: 12,
  },
  title: {
    fontSize: 18,
    marginBottom: 10,
    textAlign: 'center',
  },
  section: {
    marginBottom: 10,
  },
  text: {
    marginTop: 5,
  },
  logoStampContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginBottom: 10,
  },
  logo: {
    width: 30,
    height: 30,
  },
  copyrightContainer: {
    position: 'absolute',
    bottom: 10,
    right: 10,
  },
  stamp: {
    fontSize: 8,
    textAlign: 'right',
  },
  studentName: {
    fontSize: 14,
    fontWeight: 'bold',
    marginBottom: 5,
    textAlign: 'center',
  },
});

const GradingRubricPDF = ({ aiGradingScale, gradingScale }) => {
  // Fetch student name from localStorage or another source
  const storedName = localStorage.getItem('studentName');
  const studentName = storedName && storedName.length > 0 ? storedName : 'Student Name';

  // Log the retrieved name for debugging
  console.log('Retrieved student name:', studentName);

  return (
    <Document>
      <Page style={pdfStyles.page}>
        <Text style={pdfStyles.studentName}>{studentName}</Text>  // Display student name
        <Text style={pdfStyles.title}>Grading Rubric</Text>  // Display Grading Rubric title
        <View style={pdfStyles.section}>
          <Text style={pdfStyles.text}>{aiGradingScale}</Text>
        </View>
        <View style={pdfStyles.section}>
          {Object.keys(gradingScale).map((grade) => (
            <Text style={pdfStyles.text} key={grade}>
              {grade}: {gradingScale[grade]}
            </Text>
          ))}
        </View>
        <View style={pdfStyles.copyrightContainer} fixed>
          <Text style={pdfStyles.stamp}>Created by YourEDU ©</Text>
        </View>
      </Page>
    </Document>
  );
};

// Define default form data structure
const defaultFormData = {
  evaluationMethod: '',
  learningGoals: '',
  assignments: '',
  gradingScale: {
    'A+': '',
    'A': '',
    'A-': '',
    'B+': '',
    'B': '',
    'B-': '',
    'C+': '',
    'C': '',
    'C-': '',
    'D+': '',
    'D': '',
    'D-': '',
    'F': '',
  },
  aiGradingScale: '',
};

const GradingRubric = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const saveTimeoutRef = useRef(null);

  // Initialize states
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [dataStatus, setDataStatus] = useState('Data saved');

  // Initialize formData from localStorage first
  const [formData, setFormData] = useState(() => {
    const cachedData = localStorage.getItem(`gradingRubric_${user?.id}`);
    return cachedData ? JSON.parse(cachedData) : defaultFormData;
  });

  // Fetch data from backend if no cached data exists
  const fetchGradingRubric = useCallback(async () => {
    if (!user || !user.id) {
      console.error('No user or user ID found, aborting fetchGradingRubric');
      return;
    }

    try {
      setLoading(true);
      const response = await axios.get('/api/grading-rubric', {
        params: { userId: user.id },
      });

      if (response.status === 200 && Object.keys(response.data).length > 0) {
        console.log('Fetched Grading Rubric data:', response.data);
        setFormData(response.data);
        // Store in localStorage after successful fetch
        localStorage.setItem(`gradingRubric_${user.id}`, JSON.stringify(response.data));
      }
    } catch (error) {
      console.error('Failed to fetch Grading Rubric:', error.message || error);
    } finally {
      setLoading(false);
    }
  }, [user]);

  // Check for cached data on mount
  useEffect(() => {
    if (user && user.id) {
      const cachedData = localStorage.getItem(`gradingRubric_${user.id}`);
      if (!cachedData) {
        fetchGradingRubric();
      }
    }
  }, [fetchGradingRubric, user]);

  // Save function updates both backend and localStorage
  const saveGradingRubric = useCallback(async (updatedFormData) => {
    if (!user || !user.id) {
      console.error('No user or user ID found, aborting saveGradingRubric');
      return;
    }
    try {
      setSaving(true);
      setDataStatus('Saving data...');
      const response = await axios.post('/api/grading-rubric', {
        userId: user.id,
        formData: updatedFormData,
      });
      if (response.status === 200) {
        localStorage.setItem(`gradingRubric_${user.id}`, JSON.stringify(updatedFormData));
        setDataStatus('Data saved');
      } else {
        setDataStatus('Save failed');
      }
    } catch (error) {
      console.error('Error saving Grading Rubric:', error);
      setDataStatus('Save failed');
    } finally {
      setSaving(false);
    }
  }, [user]);

  const debouncedSave = useCallback((updatedFormData) => {
    if (saveTimeoutRef.current) clearTimeout(saveTimeoutRef.current);
    saveTimeoutRef.current = setTimeout(() => {
      saveGradingRubric(updatedFormData);
    }, 1000);
  }, [saveGradingRubric]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    debouncedSave({ ...formData, [name]: value });
  };

  const handleGradingScaleChange = (e) => {
    const { name, value } = e.target;
    const updatedGradingScale = { ...formData.gradingScale, [name]: value };
    setFormData({ ...formData, gradingScale: updatedGradingScale });
    debouncedSave({ ...formData, gradingScale: updatedGradingScale });
  };

  const generateAIDescription = async () => {
    const userGrades = formData.gradingScale;
    const prompt = `Please create a grading rubric that this homeschooled student needs to apply to college. Please use the fields the user has filled out as background info on the student and incorporate that info as well as possislbe into your response, as well as use the examples that this model is fine-tuned on for structure and content, thanks. 
      User background info: 
      Evaluation Method: ${formData.evaluationMethod},
      Learning Goals: ${formData.learningGoals},
      Assignments: ${formData.assignments},
      Grading Scale: ${JSON.stringify(formData.gradingScale, null, 2)},
      User Grades: ${JSON.stringify(userGrades, null, 2)}.`;

    try {
      const response = await openai.chat.completions.create({
        model: 'ft:gpt-4o-mini-2024-07-18:personal:grading-rubric-3:ALd8DpEr',
        messages: [{ role: 'system', content: prompt }],
        temperature: 0.2,
        max_tokens: 1024,
        top_p: 1,
        frequency_penalty: 0,
        presence_penalty: 0,
      });
      const aiGradingScale = response.choices[0].message.content.trim();
      setFormData({ ...formData, aiGradingScale });
      debouncedSave({ ...formData, aiGradingScale });
    } catch (error) {
      console.error('Error generating AI Grading Scale:', error);
    }
  };

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const styles = {
      container: {
        padding: '20px',
        maxWidth: '1200px',
        margin: '20px auto',
        border: '1px solid #ccc',
        borderRadius: '10px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        backgroundColor: '#fff',
      },
      headerContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '20px',
      },
      backButton: {
        padding: '10px 20px',
        backgroundColor: '#007BFF',
        color: '#FFFFFF',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
      },
      header: {
        fontSize: '24px',
        fontWeight: 'bold',
      },
      buttonGroup: {
        display: 'flex',
        alignItems: 'center',
      },
      button: {
        padding: '10px 20px',
        backgroundColor: '#007BFF',
        color: '#FFFFFF',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
        marginLeft: '10px',
        textDecoration: 'none',
        fontFamily: 'Arial',
      },
      form: {
        display: 'flex',
        flexDirection: 'column',
      },
      formGroup: {
        marginBottom: '20px',
      },
      label: {
        display: 'block',
        fontWeight: 'bold',
        marginBottom: '5px',
      },
      textarea: {
        width: '100%',
        padding: '10px',
        borderRadius: '4px',
        border: '1px solid #CED4DA',
        fontFamily: 'Arial',
      },
      scaleInput: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '10px',
        justifyContent: 'flex-start', // Aligns grade input with typical range
      },
      gradeLabel: {
          flex: '0 0 50px', // Set a consistent width for grade labels
          fontWeight: 'bold',
        },
      input: {
        padding: '5px',
        borderRadius: '4px',
        border: '1px solid #CED4DA',
        width: '80px',
      },
      typicalRange: {
        marginLeft: '10px',
        fontStyle: 'italic',
        fontSize: '12px',
        color: '#555',
      },
      aiTextarea: {
        width: '100%',
        padding: '10px',
        borderRadius: '4px',
        border: '1px solid #CED4DA',
        resize: 'none',  // Locked size
        fontFamily: 'Arial',
        height: '500px', // Increased the length to 500px
      },
      modal: {
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          width: '80%',
          height: '80%',
          padding: '20px',
          borderRadius: '10px',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        },
      },
    };

    return (
      <div style={styles.container}>
        <div style={styles.headerContainer}>
          <button style={styles.backButton} onClick={() => navigate('/admin-materials')}>
            Back
          </button>
          
          <div style={{ display: 'flex', alignItems: 'center', flex: 1, justifyContent: 'center' }}>
            <h2 style={styles.header}>Grading Rubric</h2>
          </div>
          
          <div style={{ display: 'flex', alignItems: 'center', minWidth: '150px' }}>
            {loading && <div style={{ color: '#28a745' }}>Loading data...</div>}
            {saving && <div style={{ color: '#28a745' }}>Saving data...</div>}
            {!loading && !saving && <div style={{ color: '#28a745' }}>Data saved</div>}
          </div>
          
          <div style={styles.buttonGroup}>
            <button style={styles.button} onClick={openModal}>
              Preview
            </button>
            <PDFDownloadLink
              document={<GradingRubricPDF aiGradingScale={formData.aiGradingScale} gradingScale={formData.gradingScale} />}
              fileName="grading_rubric.pdf"
              style={{ textDecoration: 'none' }}
            >
              {({ loading }) => (
                <button style={styles.button}>
                  {loading ? 'Download' : 'Download'}
                </button>
              )}
            </PDFDownloadLink>
          </div>
        </div>
        <form style={styles.form}>
          <div style={styles.formGroup}>
            <label style={styles.label}>1. How were students evaluated in academic courses?</label>
            <textarea
              name="evaluationMethod"
              value={formData.evaluationMethod}
              onChange={handleChange}
              rows="3"
              style={styles.textarea}
            />
          </div>
          <div style={styles.formGroup}>
            <label style={styles.label}>2. What learning goals needed to be met?</label>
            <textarea
              name="learningGoals"
              value={formData.learningGoals}
              onChange={handleChange}
              rows="3"
              style={styles.textarea}
            />
          </div>
          <div style={styles.formGroup}>
            <label style={styles.label}>3. What type of assignments were conducted?</label>
            <textarea
              name="assignments"
              value={formData.assignments}
              onChange={handleChange}
              rows="3"
              style={styles.textarea}
            />
          </div>
          <div style={styles.formGroup}>
            <label style={styles.label}>Grading Scale:</label>
            {Object.keys(formData.gradingScale)
              .filter((grade) => grade !== '_id')  // Filter out the '_id' key
              .map((grade) => (
                <div style={styles.scaleInput} key={grade}>
                  <label style={styles.gradeLabel}>{grade}:</label>
                  <input
                    type="text"
                    name={grade}
                    value={formData.gradingScale[grade]}
                    onChange={handleGradingScaleChange}
                    style={styles.input}
                  />
                </div>
              ))}
          </div>
          <div style={styles.formGroup}>
            <button type="button" style={styles.button} onClick={generateAIDescription}>
              Generate AI Grading Scale
            </button>
          </div>
          <div style={styles.formGroup}>
            <label style={styles.label}>AI Generated Grading Scale</label>
            <textarea
              name="aiGradingScale"
              value={formData.aiGradingScale}
              onChange={handleChange}
              rows="10"
              style={styles.aiTextarea}  // Locked size and increased length to 500px
            />
          </div>
        </form>
        <Modal
          isOpen={isModalOpen}
          onRequestClose={closeModal}
          style={styles.modal}
          contentLabel="Grading Rubric Preview"
        >
          <button onClick={closeModal} style={{ ...styles.button, marginBottom: '10px' }}>
            Close
          </button>
          <PDFViewer width="100%" height="100%">
            <GradingRubricPDF aiGradingScale={formData.aiGradingScale} gradingScale={formData.gradingScale} />
          </PDFViewer>
        </Modal>
      </div>
    );
  };


export default GradingRubric;
