import React, { useState, useEffect } from 'react';
import { useAuth } from '../utils/AuthContext';
import { formatDeadlineDate } from '../utils/dateUtils';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import './Colleges.css';
import { useNavigate } from 'react-router-dom';

const Home = () => {
  const { user } = useAuth();
  const userType = localStorage.getItem('userType');
  const navigate = useNavigate();

  // State for To-Dos (stored locally for now)
  const [todos, setTodos] = useState(() => JSON.parse(localStorage.getItem('todos')) || []);
  const [newTodo, setNewTodo] = useState('');

  // State for Calendar
  const [date, setDate] = useState(new Date());

  const [progress, setProgress] = useState(() => {
    const savedProgress = localStorage.getItem('adminMaterialsProgress');
    return savedProgress ? JSON.parse(savedProgress) : {
      schoolPhilosophy: 0,
      transcript: 0,
      courseDescriptions: 0,
      gradingRubric: 0,
      guidanceCounselorLetter: 0
    };
  });

  const [schoolList, setSchoolList] = useState([]);

  useEffect(() => {
    const savedProgress = localStorage.getItem('adminMaterialsProgress');
    if (savedProgress) {
      setProgress(JSON.parse(savedProgress));
    }
  }, []);

  useEffect(() => {
    const loadSchoolList = () => {
      const savedSchools = localStorage.getItem('mySchoolList');
      if (savedSchools) {
        try {
          const schools = JSON.parse(savedSchools);
          setSchoolList(schools);
        } catch (e) {
          console.error('Error parsing saved schools:', e);
        }
      }
    };

    loadSchoolList();
    
    // Add event listener for storage changes
    window.addEventListener('storage', loadSchoolList);
    return () => window.removeEventListener('storage', loadSchoolList);
  }, []);

  // Save todos to localStorage whenever they are updated
  useEffect(() => {
    localStorage.setItem('todos', JSON.stringify(todos));
  }, [todos]);

  // Handle adding a new to-do
  const handleAddTodo = () => {
    if (newTodo.trim() === '') return;
    setTodos([...todos, newTodo]);
    setNewTodo('');
  };

  // Handle deleting a to-do
  const handleDeleteTodo = (index) => {
    const updatedTodos = todos.filter((_, i) => i !== index);
    setTodos(updatedTodos);
  };

  // Update progress from localStorage when it changes
  useEffect(() => {
    const handleStorageChange = () => {
      const savedProgress = localStorage.getItem('adminMaterialsProgress');
      if (savedProgress) {
        setProgress(JSON.parse(savedProgress));
      }
    };

    // Initial load
    handleStorageChange();

    // Listen for changes
    window.addEventListener('storage', handleStorageChange);
    return () => window.removeEventListener('storage', handleStorageChange);
  }, []);

  const formatDeadline = (dateStr) => {
    if (!dateStr) return null;
    return formatDeadlineDate(dateStr); // Using the existing formatDeadlineDate function
  };

  return (
    <div style={styles.container}>
      <div style={styles.leftColumn} className="container-hover">
        {userType === 'temp' ? (
          <>
            <p style={styles.introText}>
              Congratulations on taking this first step to Homeschool through YourEDU's PSP!
            </p>
            <p style={styles.introText}>
              YourEDU offers a very flexible K - 12 Private School Satellite Program (PSP) for homeschooling families throughout California. YourEDU maintains on file all the documents required by the CA Ed Code pertaining to private schools.
            </p>
            <h2 style={styles.infoHeader}>YourEDU PSP Info</h2>
            <div style={styles.requirementsContainer}>
              <h2 style={styles.requirementsHeader}>Requirements for Enrollment:</h2>
              <ul style={styles.requirementsList}>
                <li>Students must reside in California and may not be enrolled in another public or private school.</li>
                <li>Parents meet with the YourEDU Director for consultation by phone or video chat.</li>
                <li>Family submits YourEDU Enrollment Form along with required documentation and payment.</li>
              </ul>
            </div>
            <div style={styles.servicesContainer}>
              <h2 style={styles.servicesHeader}>YourEDU PSP will:</h2>
              <ul style={styles.servicesList}>
                <li>Request cumulative records from previous school.</li>
                <li>Maintain all student records that are legally required in California.</li>
                <li>Transfer cumulative records to any new school in which the student enrolls.</li>
                <li>Provide verification of student enrollment.</li>
                <li>Provide verification of teacher status for parents of enrolled students.</li>
                <li>Organize several field trips per year in the Bay Area focusing on college tours and college preparation.</li>
              </ul>
            </div>
            <div style={styles.additionalServicesContainer}>
              <h2 style={styles.additionalServicesHeader}>In addition, YourEDU will provide:</h2>
              <ul style={styles.additionalServicesList}>
                <li>Report cards, transcripts, work permits, high school diploma.</li>
                <li>Academic counseling, college counseling, encouragement, and support.</li>
                <li>Assistance in registering for California High School Proficiency Program (CPP), Career Technical Education (CTE or ROP), community college concurrent enrollment.</li>
                <li>Referral to outside resources.</li>
              </ul>
            </div>
            <div style={styles.feesContainer}>
              <h2 style={styles.feesHeader}>Fees</h2>
              <ul style={styles.feesList}>
                <li>Initial consultation to determine whether YourEDU is right for your family - No charge</li>
                <li>Annual tuition per family - $300</li>
                <li>Field Trips may require additional participation fees</li>
              </ul>
            </div>
            <p style={styles.helpText}>
              If you need help, don't hesitate to contact us at <a href="mailto:info@youredu.school" style={styles.link}>info@youredu.school</a>
            </p>
          </>
        ) : userType === 'highschool' ? (
          <>
            <h2 style={styles.welcomeHeader}>🎓 Welcome High School Students! 🎓</h2>
            <p style={styles.introText}>
              Welcome to YourEDU, a platform designed to help you navigate your high school journey and prepare for college.
            </p>
            <div style={styles.resourceContainer}>
              <h2 style={styles.resourceHeader}>High School Program Overview</h2>
              <p style={styles.resourceText}>
                Our program offers guidance on course planning, college preparation, extracurricular activities, and more.
              </p>
            </div>
            <div style={styles.tipsContainer}>
              <h2 style={styles.tipsHeader}>Key Resources</h2>
              <ul style={styles.tipsList}>
                <li>📘 Course Planning: Use our tools to design a personalized high school curriculum.</li>
                <li>📝 Extracurriculars: Explore opportunities to enhance your application through internships, community service, and clubs.</li>
                <li>💼 Career Exploration: Learn about different career paths and find resources to help you get started.</li>
                <li>🎓 College Prep: Get advice on how to prepare for college applications, SAT/ACT tests, and more.</li>
                <li>⚙️ Manage your account settings in the "My Account" tab.</li>
              </ul>
            </div>
            
            <div style={styles.feedbackContainer}>
              <p style={styles.helpText}>
              If you have any questions, feel free to contact us at <a href="mailto:info@youredu.school" style={styles.link}>info@youredu.school</a>.
            </p>
              <p style={styles.feedbackText}>
                As you're using the platform, should you have any immediate feedback or ideas, or need help troubleshooting (we’re constantly improving and there will likely be some minor problems), please use the link below, and we’ll get back to you within 24 hours.
              </p>
              <a 
                href="https://forms.gle/voaAD1mt6SK16m8YA" 
                target="_blank" 
                rel="noopener noreferrer" 
                style={styles.feedbackLink}
                className="feedback-button"
              >
                📝 Submit Feedback
              </a>
            </div>
            <div style={styles.editOnboardingButtonContainer}>
              <button 
                onClick={() => navigate('/onboarding', { state: { isEditing: true } })}
                style={styles.editOnboardingButton}
              >
                Edit Onboarding Data
              </button>
            </div>
          </>
        ) : (
          <>
            <h2 style={styles.welcomeHeader}>✨ Welcome to YourEDU</h2>
            <p style={styles.introText}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Thank you for being part of our community! Founded at Stanford University, YourEDU is a labor of love to give back to the homeschool community that has given us so much. Our mission is to support and empower your homeschool experience every step of the way. <br></br><br></br>
                
                <div style={{ display: 'flex', justifyContent: 'left' }}>
                    <div style={{ textAlign: 'left' }}>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Warmest regards,<br></br>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Henry and Colin, YourEDU Team
                    </div>
                </div>
            </p>

            <h3 style={styles.tipsHeader}>Quick Navigation Guide</h3>
            <ul style={styles.tipsList}>
              <li style={styles.tipItem}>
                <span style={styles.tipIcon}>📝</span>
                <span style={styles.tipText}>Use the <strong>Admin Materials</strong> tab to manage and complete your administrative materials with the help of our own AI, including transcript, course descriptions, school profile report, grading rubric, and parent letter of rec.</span>
              </li>
              <li style={styles.tipItem}>
                <span style={styles.tipIcon}>🔍</span>
                <span style={styles.tipText}>Use the <strong>Colleges</strong> tab to explore college-specific requirements and manage your school list</span>
              </li>
              <li style={styles.tipItem}>
                <span style={styles.tipIcon}>💰</span>
                <span style={styles.tipText}>Use the <strong>Scholarships</strong> tab to explore available independent scholarships and application tips</span>
              </li>
              <li style={styles.tipItem}>
                <span style={styles.tipIcon}>⚙️</span>
                <span style={styles.tipText}>Use the <strong>{user.email}</strong> tab to manage your account settings</span>
              </li>
            </ul>

            <h3 style={{...styles.tipsHeader, marginTop: '32px'}}>Feedback</h3>
            <p style={styles.helpText}>
              If you have any general questions, feel free to contact us at <a href="mailto:info@youredu.school" style={styles.link}>info@youredu.school</a>.
            </p>
            <p style={styles.feedbackText}>
              Should you have any immediate feedback or ideas, or need help troubleshooting (we're constantly improving and there will likely be some minor problems), please use the link below, and we'll get back to you within 24 hours.
            </p>
            <a 
              href="https://forms.gle/voaAD1mt6SK16m8YA" 
              target="_blank" 
              rel="noopener noreferrer" 
              style={styles.feedbackLink}
              className="feedback-button"
            >
              📝 Submit Feedback
            </a>
          </>
        )}
      </div>

      {/* Right side: To-Do List and Calendar */}
      <div style={styles.rightColumn}>
        {/* Admin Materials Progress section - moved up */}
        <div style={styles.adminProgressContainer} className="container-hover">
          <h2 style={styles.header}>Admin Materials Progress</h2>
          <div style={styles.progressItem}>
            <div style={styles.progressLabel}>School Profile Report</div>
            <div style={styles.progressBarContainer}>
              <div style={{...styles.progressBar, width: `${progress.schoolPhilosophy}%`}}></div>
            </div>
            <div style={styles.progressText}>{`${progress.schoolPhilosophy}% Complete`}</div>
          </div>
          <div style={styles.progressItem}>
            <div style={styles.progressLabel}>Transcript</div>
            <div style={styles.progressBarContainer}>
              <div style={{...styles.progressBar, width: `${progress.transcript}%`}}></div>
            </div>
            <div style={styles.progressText}>{`${progress.transcript}% Complete`}</div>
          </div>
          <div style={styles.progressItem}>
            <div style={styles.progressLabel}>Course Descriptions</div>
            <div style={styles.progressBarContainer}>
              <div style={{...styles.progressBar, width: `${progress.courseDescriptions}%`}}></div>
            </div>
            <div style={styles.progressText}>{`${progress.courseDescriptions}% Complete`}</div>
          </div>
          <div style={styles.progressItem}>
            <div style={styles.progressLabel}>Grading Rubric</div>
            <div style={styles.progressBarContainer}>
              <div style={{...styles.progressBar, width: `${progress.gradingRubric}%`}}></div>
            </div>
            <div style={styles.progressText}>{`${progress.gradingRubric}% Complete`}</div>
          </div>
          <div style={styles.progressItem}>
            <div style={styles.progressLabel}>Parent Letter of Recommendation</div>
            <div style={styles.progressBarContainer}>
              <div style={{...styles.progressBar, width: `${progress.guidanceCounselorLetter}%`}}></div>
            </div>
            <div style={styles.progressText}>{`${progress.guidanceCounselorLetter}% Complete`}</div>
          </div>
        </div>

        {/* College Deadlines section */}
        <div style={styles.deadlinesContainer} className="container-hover">
          <h2 style={styles.header}>College Deadlines</h2>
          {schoolList.length > 0 ? (
            <div style={styles.deadlinesList}>
              {schoolList.map((school, index) => (
                <div key={index} style={styles.deadlineItem}>
                  <h3 style={styles.schoolName}>{school.name}</h3>
                  {school.deadlines?.earlyAction && school.deadlines.earlyAction !== 'N/A' && (
                    <div style={styles.deadline}>
                      <span style={styles.deadlineLabel}>Early Action:</span>
                      <span style={styles.deadlineValue}>
                        {formatDeadlineDate(school.deadlines.earlyAction)}
                      </span>
                    </div>
                  )}
                  {school.deadlines?.earlyDecision && school.deadlines.earlyDecision !== 'N/A' && (
                    <div style={styles.deadline}>
                      <span style={styles.deadlineLabel}>Early Decision:</span>
                      <span style={styles.deadlineValue}>
                        {formatDeadlineDate(school.deadlines.earlyDecision)}
                      </span>
                    </div>
                  )}
                  {school.deadlines?.regularDecision && school.deadlines.regularDecision !== 'N/A' && (
                    <div style={styles.deadline}>
                      <span style={styles.deadlineLabel}>Regular Decision:</span>
                      <span style={styles.deadlineValue}>
                        {formatDeadlineDate(school.deadlines.regularDecision)}
                      </span>
                    </div>
                  )}
                </div>
              ))}
            </div>
          ) : (
            <>
              <p style={styles.deadlinesText}>None added</p>
              <p style={styles.deadlinesText}>
                Build Your Perfect College List With Our Curated Rankings And Recommendations
              </p>
              <div style={{ textAlign: 'center' }}>
                <a 
                  onClick={() => navigate('/school-search')} 
                  style={styles.feedbackLink}
                  className="feedback-button"
                >
                  Search Colleges
                </a>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    padding: '32px',
    minHeight: 'calc(100vh - 72px)',
    maxWidth: '1400px',
    margin: '0 auto',
    marginTop: '-50px',
  },
  leftColumn: {
    backgroundColor: '#ffffff',
    padding: '32px',
    paddingBottom: '0',
    borderRadius: '16px',
    marginRight: '24px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)',
    transition: 'all 0.3s ease',
    border: '1px solid rgba(49, 130, 206, 0.1)',
    flex: '2',
    maxWidth: '66%',
  },
  welcomeContainer: {
    backgroundColor: '#ffffff',
    padding: '24px',
    borderRadius: '12px',
    marginBottom: '24px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)',
    transition: 'all 0.3s ease',
    border: '1px solid rgba(49, 130, 206, 0.1)',
  },
  tipsContainer: {
    backgroundColor: '#ffffff',
    padding: '24px',
    borderRadius: '12px',
    marginBottom: '24px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)',
    transition: 'all 0.3s ease',
    border: '1px solid rgba(49, 130, 206, 0.1)',
  },
  helpText: {
    padding: '24px 32px',
    margin: 0,
    borderBottom: '1px solid #edf2f7',
    fontSize: '15px',
    color: '#2d3748',
    lineHeight: '1.5',
    fontWeight: '500',
    backgroundColor: 'transparent',
  },
  feedbackContainer: {
    backgroundColor: '#ffffff',
    padding: '24px',
    borderRadius: '12px',
    marginBottom: '24px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)',
    transition: 'all 0.3s ease',
    border: '1px solid rgba(49, 130, 206, 0.1)',
  },
  editOnboardingButtonContainer: {
    padding: '24px 32px',
    backgroundColor: 'transparent',
    boxShadow: 'none',
    display: 'flex',
    justifyContent: 'center',
  },
  rightColumn: {
    flex: '1',
    maxWidth: '33%',
  },
  mainContentBox: {
    backgroundColor: '#fff',
    padding: '20px',
    borderRadius: '8px',
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
  },
  welcomeText: {
    fontSize: '32px',
    fontWeight: 'bold',
    margin: '0 0 5px 0',
    lineHeight: '1.1',
  },
  emailText: {
    fontSize: '18px',
    marginBottom: '20px',
    color: '#555',
  },
  introText: {
    fontSize: '16px',
    lineHeight: '1.5',
    marginTop: '0',
    marginBottom: '20px',
    color: '#333',
  },
  tipsContainer: {
    padding: '20px',
    borderRadius: '10px',
    marginBottom: '20px',
    backgroundColor: '#f5f5f5',
  },
  tipsHeader: {
    fontSize: '24px',
    marginBottom: '10px',
  },
  tipsList: {
    listStyleType: 'none',
    paddingLeft: '20px',
  },
  tipItem: {
    fontSize: '16px',
    marginBottom: '10px',
  },
  helpText: {
    fontSize: '16px',
    textAlign: 'center',
  },
  feedbackContainer: {
    textAlign: 'center',
    marginTop: '20px',
  },
  feedbackText: {
    fontSize: '16px',
    marginBottom: '10px',
  },
  feedbackLink: {
    display: 'inline-block',
    padding: '15px',
    fontSize: '18px',
    fontWeight: 'bold',
    backgroundColor: '#00356b',
    color: '#FFFFFF',
    textDecoration: 'none',
    borderRadius: '5px',
  },
  link: {
    color: '#00356b',
    textDecoration: 'none',
  },
  welcomeHeader: {
    fontSize: '26px',
    fontWeight: 'bold',
    margin: '0 0 10px 0',
    color: '#00356b',
  },
  header: {
    fontSize: '24px',
    marginBottom: '10px',
    color: '#00356b',
  },
  todoContainer: {
    marginBottom: '20px',
  },
  todoList: {
    listStyleType: 'none',
    paddingLeft: '0',
  },
  todoItem: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '10px',
  },
  todoInput: {
    width: '80%',
    padding: '10px',
    marginRight: '10px',
    border: '1px solid #ccc',
    borderRadius: '4px',
  },
  addButton: {
    padding: '10px',
    backgroundColor: '#007BFF',
    color: '#FFFFFF',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
  },
  deleteButton: {
    padding: '10px',
    backgroundColor: '#FF0000',
    color: '#FFFFFF',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
  },
  calendarContainer: {
    marginTop: '20px',
  },
  editOnboardingButtonContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    marginTop: '20px',
  },
  editOnboardingButton: {
    padding: '10px 20px',
    backgroundColor: '#00356b',
    color: '#ffffff',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    fontSize: '16px',
  },
  deadlinesContainer: {
    backgroundColor: '#fff',
    borderRadius: '12px',
    padding: '28px 32px',
    marginBottom: '24px',
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
  },
  deadlinesText: {
    fontSize: '16px',
    color: '#555',
    marginBottom: '20px',
    lineHeight: '1.5',
  },
  searchButton: {
    backgroundColor: '#00356b',
    color: '#fff',
    padding: '12px 24px',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    fontSize: '16px',
    width: '100%',
  },
  adminProgressContainer: {
    backgroundColor: '#fff',
    borderRadius: '12px',
    padding: '28px 32px',
    marginBottom: '24px',
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
  },
  progressItem: {
    marginBottom: '15px',
  },
  progressLabel: {
    fontSize: '14px',
    color: '#555',
    marginBottom: '5px',
  },
  progressBarContainer: {
    width: '100%',
    height: '12px',
    backgroundColor: '#f0f0f0',
    borderRadius: '6px',
    overflow: 'hidden',
  },
  progressBar: {
    height: '100%',
    backgroundColor: '#00356b',
    borderRadius: '6px',
    transition: 'width 0.3s ease',
  },
  progressText: {
    fontSize: '14px',
    color: '#555',
    marginTop: '5px',
    textAlign: 'right',
  },
  deadlinesList: {
    display: 'flex',
    flexDirection: 'column',
    gap: '15px',
  },
  deadlineItem: {
    padding: '10px',
    borderRadius: '4px',
    backgroundColor: '#f8f9fa',
  },
  schoolName: {
    fontSize: '16px',
    fontWeight: 'bold',
    color: '#00356b',
    marginBottom: '5px',
  },
  deadline: {
    fontSize: '14px',
    color: '#555',
    margin: '3px 0',
  },
  deadlineLabel: {
    fontWeight: 'bold',
    color: '#333',
    marginRight: '8px',
  },
  welcomeContainer: {
    backgroundColor: '#fff',
    borderRadius: '12px 12px 0 0',
    padding: '24px 32px',
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
    marginBottom: '0',
  },
  welcomeHeader: {
    fontSize: '24px',
    fontWeight: '600',
    color: '#1a365d',
    marginBottom: '12px',
    textAlign: 'center',
    fontFamily: "'Inter', sans-serif",
  },
  introText: {
    fontSize: '15px',
    color: '#1a365d',
    lineHeight: '1.5',
    textAlign: 'center',
    fontFamily: "'Inter', sans-serif",
    marginBottom: '0',
    fontWeight: '500',
  },
  tipsContainer: {
    backgroundColor: '#fff',
    borderRadius: '0',
    padding: '28px 32px',
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
    marginBottom: '0',
    borderTop: '1px solid #edf2f7',
  },
  tipsHeader: {
    fontSize: '18px',
    fontWeight: '600',
    color: '#1a365d',
    marginBottom: '16px',
    fontFamily: "'Inter', sans-serif",
  },
  tipsList: {
    listStyle: 'none',
    padding: 0,
    margin: 0,
  },
  tipItem: {
    display: 'flex',
    alignItems: 'flex-start',
    marginBottom: '12px',
    padding: '10px',
    backgroundColor: '#f8fafc',
    borderRadius: '8px',
    transition: 'transform 0.2s ease',
  },
  tipIcon: {
    fontSize: '16px',
    marginRight: '12px',
    marginTop: '2px',
  },
  tipText: {
    flex: 1,
    fontSize: '14px',
    color: '#1a365d',
    lineHeight: '1.4',
    fontFamily: "'Inter', sans-serif",
    fontWeight: '500',
  },
  feedbackContainer: {
    backgroundColor: '#fff',
    borderRadius: '0 0 12px 12px',
    padding: '24px 32px',
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
    textAlign: 'center',
    borderTop: '1px solid #edf2f7',
  },
  feedbackText: {
    fontSize: '14px',
    color: '#1a365d',
    lineHeight: '1.4',
    marginBottom: '16px',
    fontFamily: "'Inter', sans-serif",
    fontWeight: '500',
  },
  feedbackLink: {
    display: 'inline-block',
    backgroundColor: '#1a365d',
    color: '#ffffff',
    padding: '10px 24px',
    borderRadius: '6px',
    textDecoration: 'none',
    fontWeight: '600',
    margin: '0 auto',
    transition: 'all 0.2s ease',
    '&:hover': {
      backgroundColor: '#2c5282',
      transform: 'translateY(-1px)',
    },
  },
  helpText: {
    fontSize: '14px',
    color: '#1a365d',
    lineHeight: '1.4',
    marginBottom: '16px',
    fontFamily: "'Inter', sans-serif",
    fontWeight: '500',
    textAlign: 'center',
    maxWidth: '600px',
    margin: '0 auto 16px',
  },
  feedbackText: {
    fontSize: '14px',
    color: '#1a365d',
    lineHeight: '1.4',
    marginBottom: '24px',
    fontFamily: "'Inter', sans-serif",
    fontWeight: '500',
    textAlign: 'center',
    maxWidth: '600px',
    margin: '0 auto 24px',
  },
  feedbackLink: {
    display: 'block',
    width: 'fit-content',
    backgroundColor: '#1a365d',
    color: '#ffffff',
    padding: '10px 24px',
    borderRadius: '6px',
    textDecoration: 'none',
    fontWeight: '600',
    margin: '0 auto',
    transition: 'all 0.2s ease',
    '&:hover': {
      backgroundColor: '#2c5282',
      transform: 'translateY(-1px)',
    },
  },
  mainContainer: {
    backgroundColor: '#ffffff',
    padding: '32px',
    borderRadius: '16px',
    marginBottom: '24px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)',
    transition: 'all 0.3s ease',
    border: '1px solid rgba(49, 130, 206, 0.1)',
  },
  welcomeHeader: {
    fontSize: '28px',
    fontWeight: '600',
    color: '#1a365d',
    marginBottom: '16px',
    fontFamily: "'Inter', sans-serif",
  },
  introText: {
    fontSize: '16px',
    color: '#2D3748',
    lineHeight: '1.6',
    marginBottom: '32px',
  },
};

// Add font import
const fontImport = document.createElement('link');
fontImport.href = 'https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap';
fontImport.rel = 'stylesheet';
document.head.appendChild(fontImport);

export default Home;
