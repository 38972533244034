import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';

const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem('user')) || null;
    if (userData) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${userData.token}`;
      setUser(userData);
    }
  }, []);

  const login = async (email, password) => {
    try {
      console.log('Attempting to log in with email:', email);
      const response = await axios.post('/api/auth/login', { email, password });
      console.log('Login response:', response);
      const { token, name, id } = response.data;
      const userData = { email, name, token, id };
      setUser(userData);
      localStorage.setItem('user', JSON.stringify(userData));
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      console.log('Login successful for:', email);
    } catch (error) {
      console.error('Failed to log in:', error.response ? error.response.data : error.message);
      alert('Failed to log in: Invalid credentials');
    }
  };

  const register = async (email, password, name) => {
    try {
      console.log('Attempting to register user:', email);
      const response = await axios.post('/api/auth/register', { email, password, name });
      console.log('Register response:', response);
      const { token, id } = response.data;
      const userData = { email, name, token, id };
      setUser(userData);
      localStorage.setItem('user', JSON.stringify(userData));
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      console.log('Registration successful for:', email);
    } catch (error) {
      console.error('Failed to register:', error.response ? error.response.data : error.message);
      alert('Failed to register');
    }
  };

  const logout = () => {
    setUser(null);
    localStorage.removeItem('user');
    delete axios.defaults.headers.common['Authorization'];
    console.log('User logged out');
  };

  return (
    <AuthContext.Provider value={{ user, login, register, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
