const allScholarships = [
    {
        id: '1',
        name: '$10,000 No Essay Scholarship',
        offeredBy: 'Scholarships360',
        amount: '$10,000',
        deadline: 'Jun 30, 2025',
        gradeLevel: 'All Grade Levels',
        description: 'The Scholarships360 $10,000 “No Essay” Scholarship is open to all students who want some extra help paying for their education. Whether you are a high school student who hopes to go to college, a graduate student who’s in a master’s program, or an adult learner who wants to return to school, you are eligible for our no essay scholarship. This scholarship will be awarded to students who get the most out of Scholarships360 scholarships and content. Finalists for this scholarship will be interviewed about their process for funding their education.',
        link: 'https://scholarships360.org/scholarships/search/10000-no-essay-scholarship/',
    },
    {
        id: '2',
        name: '$2,000 Sallie Mae Scholarship',
        offeredBy: 'Sallie Mae',
        amount: '$2,000',
        deadline: 'Oct 31, 2024',
        gradeLevel: 'HS Upperclassmen, College & Graduate Students',
        description: 'Sallie Mae will award $2,000 each month to eligible entrants. No essay or account sign-ups required, just a simple scholarship for those seeking help paying for their education.',
        link: 'https://www.salliemae.com/scholarships/2k-scholarship',
    },
    {
        id: '3',
        name: 'Technology Addiction Awareness Scholarship',
        offeredBy: 'Digital Responsibility',
        amount: '$1,000',
        deadline: 'Jan 30, 2025',
        gradeLevel: 'All Grade Levels',
        description: 'The Technology Addiction Awareness Scholarship offers $1,000 to the applicant who writes the best 140-character statement (and subsequent essay) about technology addiction. Finalists will submit a 500 - 1,000 word essay to qualify for the award.',
        link: 'https://www.digitalresponsibility.org/technology-addiction-awareness-scholarship',
    },
    {
        id: '4',
        name: 'BigFuture $40k Essay-Free Scholarship',
        offeredBy: 'The College Board',
        amount: '$40,000',
        deadline: 'Oct 31, 2024',
        gradeLevel: 'High School Senior',
        description: 'Open to US-based high school students in the class of 2025, with no essay, minimum GPA, test score, or citizenship requirements. BigFuture offers multiple opportunities to win scholarships as you plan for college.',
        link: 'https://bigfuture.collegeboard.org/pay-for-college/bigfuture-scholarships',
    },
    {
        id: '5',
        name: 'New Mexico Legislative Lottery Scholarship',
        offeredBy: 'New Mexico Higher Education Department',
        amount: 'Varies',
        deadline: 'Varies',
        gradeLevel: 'High School Senior',
        description: 'The New Mexico Legislative Lottery Scholarship provides tuition assistance to New Mexico high school graduates or GED recipients attending public post-secondary institutions in the state. Applicants must enroll full-time and maintain eligibility.',
        link: 'https://scholarships.unm.edu/Resources/lottery-scholarships.html',
    },
    {
        id: '6',
        name: 'Niche $25,000 “No Essay” Scholarship',
        offeredBy: 'Niche',
        amount: '$25,000',
        deadline: 'Sep 30, 2024',
        gradeLevel: 'All Grade Levels',
        description: 'Easy scholarship open to all high school and college students, as well as anyone looking to attend college or graduate school in the next year. No essay is required.',
        link: 'https://www.niche.com/colleges/scholarships/25k-no-essay-scholarship-202405/',
    },
    {
        id: '7',
        name: 'HSLDA Contests',
        offeredBy: 'Home School Legal Defense Association',
        amount: 'Varies',
        deadline: 'Triannual',
        gradeLevel: 'High School Students',
        description: 'Funded by the Home School Legal Defense Association, these contests allow homeschool students to showcase their talents in writing, art, and photography. Winners receive monetary awards and have their work published.',
        link: 'https://hslda.org/post/contests',
    },
    {
        id: '8',
        name: 'GHEA HOPE Scholarship',
        offeredBy: 'Georgia Home Education Association (GHEA)',
        amount: 'Varies',
        deadline: 'Rolling',
        gradeLevel: 'College Underclassmen',
        description: 'The GHEA HOPE Scholarship is open to Georgia residents who have completed a Home Study program. It provides financial assistance to eligible students enrolled at any college or university in Georgia. Recipients must maintain a minimum GPA to remain eligible.',
        link: 'https://ghea.org/hope-program/',
    },
    {
        id: '9',
        name: '$10,000 CollegeXpress Scholarship',
        offeredBy: 'CollegeXpress',
        amount: '$10,000',
        deadline: 'May 1, 2025',
        gradeLevel: 'High School Students',
        description: 'Annual $10k scholarship from CollegeXpress open to all high school freshmen, sophomores, juniors, and seniors. No essay required.',
        link: 'https://www.collegexpress.com/scholarships/10000-college-scholarship/2023904/',
    },
    {
        id: '10',
        name: 'Alaska Performance Scholarships',
        offeredBy: 'Alaska Commission on Postsecondary Education',
        amount: 'Up to $4,755',
        deadline: 'Jun 2025',
        gradeLevel: 'College Students',
        description: 'The Alaska Performance Scholarship is a prestigious award for Alaskan residents who have graduated from and excelled in their high school curriculum. To be eligible, students must have a minimum 2.5 GPA and meet readiness exam requirements.',
        link: 'https://acpe.alaska.gov/financial-aid/ak-performance-scholarship',
    },
    {
        id: '11',
        name: 'Constitutional Studies Scholarship',
        offeredBy: 'Foundation for the Restoration of America',
        amount: '$2,000 - $10,000',
        deadline: 'Jun 2025',
        gradeLevel: 'HS Upperclassmen, College & Graduate Students',
        description: 'This scholarship awards two $10,000 scholarships, two $5,000 scholarships, and 35 $2,000 scholarships to students who complete courses on the U.S. Constitution from Hillsdale College. These courses are free and open to students 16 years and older.',
        link: 'https://www.ffroa.com/.constitutional-study-program/',
    },
    {
        id: '12',
        name: '$25k “Be Bold” No-Essay Scholarship',
        offeredBy: 'Bold.org',
        amount: '$25,000',
        deadline: 'Sep 1, 2024',
        gradeLevel: 'All Grade Levels',
        description: 'This no-essay scholarship is open to high school students, college students, community college students, and graduate students. The selection process rewards applicants who are bold, determined, and earnest.',
        link: 'https://bold.org/scholarships/the-be-bold-no-essay-scholarship/',
    },
    {
        id: '13',
        name: 'Slumber Search Scholarship',
        offeredBy: 'Slumber Search',
        amount: '$1,000',
        deadline: 'Jun 2025',
        gradeLevel: 'High School Seniors & College Students',
        description: 'This scholarship offers $1,000 to a college student who demonstrates an entrepreneurial spirit by submitting a short video detailing a business idea that disrupts an existing industry.',
        link: 'https://www.slumbersearch.com/scholarship',
    },
    {
        id: '14',
        name: 'Ocean Awareness Art Contest',
        offeredBy: 'Bow Seat Ocean Awareness Programs',
        amount: 'Up to $1,000',
        deadline: 'Jun 9, 2025',
        gradeLevel: 'High School Students',
        description: 'This art contest encourages young artists aged 11–18 to submit creative works that explore their relationship with the natural world. Applicants may submit visual art, creative writing, music, and other media.',
        link: 'https://bowseat.org/programs/ocean-awareness-contest/contest-overview/',
    },
    {
        id: '15',
        name: '$1,000 Appily Easy College Money Scholarship',
        offeredBy: 'Appily',
        amount: '$1,000',
        deadline: 'Oct 31, 2024',
        gradeLevel: 'High School Students & College Underclassmen',
        description: 'This scholarship is open to U.S. high school students (Classes of 2025, 2026, 2027) and college transfer students. It’s a simple monthly scholarship with no essay required.',
        link: 'https://www.appily.com/scholarships/1000-next-generation-scholarship',
    },
    {
        id: '16',
        name: 'LMG Foundation Scholarship',
        offeredBy: 'LMG Foundation',
        amount: '$1,500',
        deadline: 'Jun 2025',
        gradeLevel: 'High School Seniors & College Students',
        description: 'The LMG Foundation Scholarship supports high school seniors, homeschooled seniors, and current college students. Applicants must submit a resume and letters of recommendation to apply.',
        link: 'https://www.lmgfoundation.org/',
    },
    {
        id: '17',
        name: 'Techie Homeschool Scholarship',
        offeredBy: 'Techie Homeschool Mom',
        amount: '$500',
        deadline: 'May 2025',
        gradeLevel: 'High School Senior',
        description: 'Applicants must be homeschooling high school seniors or dual-credit students. Applicants will submit an essay about their homeschool experience.',
        link: 'https://techiehomeschoolmom.com/techie-homeschool-scholarship-application/',
    },
    {
        id: '18',
        name: '$5,000 Christian Connector Scholarship',
        offeredBy: 'Christian Connector',
        amount: '$5,000',
        deadline: 'May 31, 2025',
        gradeLevel: 'High School Students',
        description: 'The Christian Connector offers a $5,000 scholarship open to high school students who plan to attend a Christian college or university. No essay is required.',
        link: 'https://christianconnector.com/',
    },
    {
        id: '19',
        name: 'The Jimmie L. Dean Scholarship',
        offeredBy: 'The Jimmie L. Dean Scholarship Foundation, Inc',
        amount: 'Varies',
        deadline: 'Apr 2025',
        gradeLevel: 'High School Senior',
        description: 'This scholarship is open to Oklahoma high school seniors who have lived in Oklahoma for at least six years. Applicants must meet ACT score requirements and plan to attend an in-state university.',
        link: 'https://jimmiedeanfoundation.org/apply/',
    },
    {
        id: '20',
        name: 'International College Counselors Scholarship',
        offeredBy: 'International College Counselors',
        amount: '$250',
        deadline: 'Apr 2025',
        gradeLevel: 'High School Freshmen, Sophomores & Juniors',
        description: 'This $250 scholarship is awarded to high school freshmen, sophomores, or juniors based on a 500-word essay about what they have learned or hope to learn during high school. One award is reserved for students from Miami-Dade, Broward, or Palm Beach counties.',
        link: 'https://internationalcollegecounselors.com/international-college-counselors-scholarship-essay-contest/',
    },
    {
        id: '21',
        name: '$2,000 No Essay CollegeVine Scholarship',
        offeredBy: 'CollegeVine',
        amount: '$2,000',
        deadline: 'Dec 31, 2024',
        gradeLevel: 'High School Students',
        description: 'This $2,000 scholarship is open to high school students and college applicants with no essay requirement. It is an easy application for students looking for financial aid.',
        link: 'https://www.collegevine.com/scholarships',
    },
    {
        id: '22',
        name: '$2,000 Niche “No Essay” College Scholarship',
        offeredBy: 'Niche',
        amount: '$2,000',
        deadline: 'Oct 31, 2024',
        gradeLevel: 'All Grade Levels',
        description: 'This simple scholarship from Niche is open to all high school, college, and graduate students. There is no essay required.',
        link: 'https://www.niche.com/colleges/scholarships/no-essay-scholarship/',
    },
    {
        id: '23',
        name: 'Emmett Comer Scholarship',
        offeredBy: 'Washington Homeschool Organization',
        amount: 'Up to $1,000',
        deadline: 'Mar 2025',
        gradeLevel: 'High School Senior',
        description: 'This scholarship is open to homeschooled, college-bound seniors who are members of the Washington Homeschool Organization. Applicants must submit an essay on how homeschooling has prepared them for the future.',
        link: 'https://washhomeschool.org/resources/scholarships/',
    },
    {
        id: '24',
        name: 'Edvisors $2,500 Scholarship',
        offeredBy: 'Edvisors',
        amount: '$2,500',
        deadline: 'Aug 31, 2024',
        gradeLevel: 'HS Upperclassmen, College & Graduate Students',
        description: 'Edvisors offers a monthly $2,500 scholarship to students aged 17 and older. No essay is required, making it an easy scholarship for college and graduate students.',
        link: 'https://www.edvisors.com/scholarships/featured-scholarships/2500-monthly-scholarship/enter-scholarship/',
    },
    {
        id: '25',
        name: 'Homeschoolers’ Support Association Scholarship',
        offeredBy: 'Washington Homeschool Organization (WHO)',
        amount: 'Up to $1,000',
        deadline: 'Mar 2025',
        gradeLevel: 'High School Senior',
        description: 'This scholarship awards up to $1,000 to homeschooled seniors in Washington who demonstrate community involvement. Applicants must submit a 500-word essay about their contributions to their community or how their community has influenced their homeschooling experience.',
        link: 'https://washhomeschool.org/resources/scholarships/',
    },
    {
        id: '26',
        name: 'ScholarshipOwl $50,000 No Essay Scholarship',
        offeredBy: 'ScholarshipOwl',
        amount: '$1,000',
        deadline: 'Aug 31, 2024',
        gradeLevel: 'All Grade Levels',
        description: 'ScholarshipOwl offers multiple opportunities for students to win up to $50,000 in scholarships. Open to U.S.-based high school, college, and graduate students with no essay required.',
        link: 'https://scholarshipowl.com/scholarship/28508-50000-scholarshipowl-no-essay-scholarship',
    },
    {
        id: '27',
        name: 'Education – Teaching Scholarship',
        offeredBy: 'General Federation of Women\'s Clubs of Massachusetts',
        amount: '$500',
        deadline: 'Mar 2025',
        gradeLevel: 'High School Senior',
        description: 'This scholarship is open to high school seniors or homeschooled students in Massachusetts who are planning to enroll in a four-year teacher-training program at an accredited college or university.',
        link: 'https://www.gfwcma.org/scholarships.html',
    },
    {
        id: '28',
        name: 'The Daniel Gerber Sr. Medallion Scholarship',
        offeredBy: 'The Gerber Foundation',
        amount: '$11,500',
        deadline: 'Feb 2025',
        gradeLevel: 'High School Senior',
        description: 'This scholarship is open to students graduating from select schools in Newaygo County, Michigan. The award is $11,500, which can be used for tuition and other college expenses.',
        link: 'https://www.gerberfoundation.org/wp-content/uploads/Medallion-Scholarship-Flyer-1.pdf',
    },
    {
        id: '29',
        name: 'Unigo $10,000 Scholarship',
        offeredBy: 'Unigo',
        amount: '$10,000',
        deadline: 'Dec 31, 2024',
        gradeLevel: 'High School Students',
        description: 'Unigo’s $10,000 scholarship is open to all high school students aged 14 and older. Applicants need only to submit a short response to a creative prompt.',
        link: 'https://www.unigo.com/scholarships/our-scholarships/unigo-10k-scholarship',
    },
    {
        id: '30',
        name: 'George and Mary Josephine Hamman Foundation Scholarships',
        offeredBy: 'George and Mary Josephine Hamman Foundation',
        amount: '$5,000',
        deadline: 'Feb 2025',
        gradeLevel: 'High School Senior',
        description: 'This foundation offers $5,000 per year to high school seniors in Texas who demonstrate financial need and academic excellence. The scholarship is renewable for up to four years.',
        link: 'https://hammanfoundation.org/scholarship-guidelines/',
    },
    {
        id: '31',
        name: 'Sonlight College Scholarships for Homeschoolers',
        offeredBy: 'Sonlight',
        amount: '$5,000',
        deadline: 'Dec 2024',
        gradeLevel: 'High School Senior',
        description: 'Sonlight offers scholarships for current homeschooled high school seniors. Awards are based on academic achievement, service, and essay submission.',
        link: 'https://www.sonlight.com/about/cares/scholarships?srsltid=AfmBOop5LXt-LYxZ3KAvUKW5ZCe-bKDA0RMqpX_L9yispvVM_pvOidCu',
    },
    {
        id: '32',
        name: 'Herb Kohl Student Excellence Scholarship',
        offeredBy: 'Herb Kohl Educational Foundation, Inc.',
        amount: '$10,000',
        deadline: 'Nov 2024',
        gradeLevel: 'High School Students',
        description: 'This scholarship awards $10,000 to 100 Wisconsin high school students annually based on academic excellence and community involvement.',
        link: 'https://www.kohleducation.org/in_the_media/press_releases.php',
    }
];

export default allScholarships;